import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import backendUrl from "../../constants/urls";
import { encodeUser } from "../../methods/api-calls";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { isMobile } from "../../helpers/generic";
import visa from "../../images/cards/visa.png";
import master from "../../images/cards/master.png";
import oca from "../../images/cards/oca.png";
import amex from "../../images/cards/amex.png";
import diners from "../../images/cards/diners.png";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
	margin: auto;
`;

const Footer = () => {
	const mobile = isMobile();
	const [currentEmail, setCurrentEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const validateEmail = () => {
		var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!reg.test(currentEmail)) {
			setError("Ingrese un email válido.");

			return false;
		}
		return true;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (currentEmail.trim()) {
			var emailOk = validateEmail();
			if (emailOk) {
				setLoading(true);
				const authHeader = encodeUser();
				axios
					.post(
						`${backendUrl}api/mailchimp/signup`,
						{ email: currentEmail },
						{
							headers: {
								accept: "application/json",
								"Accept-Language": "en-US,en;q=0.8",
								"Content-Type": `application/json`,
								...authHeader,
							},
						}
					)
					.then((response) => {
						setLoading(false);
						if (200 === response.status) {
							if (response.data.error) {
								alert(response.data.error);
							} else {
								setCurrentEmail("");
								toast.success("Te has suscripto exitosamente!", {
									position: "bottom-left",
									autoClose: 5000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}
						}
					})
					.catch((error) => {
						setLoading(false);
						alert(error, "red");
					});
			}
		}
	};

	let currentYear = new Date().getFullYear();
	return (
		<footer className="footer-area">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6 col-sm-6">
						<div className="single-footer-widget">
							<h3>Gabriel Vallone</h3>

							<div className="about-the-store">
								<ul className="footer-contact-info">
									<li>
										<i className="bx bx-phone-call"></i>
										+598 94 402 151
									</li>
									<li>
										<i className="bx bx-envelope"></i>
										info@gabrielvallone.com.uy
									</li>
								</ul>
							</div>

							<ul className="social-link">
								<li>
									<Link to="#">
										<a className="d-block" target="_blank">
											<i className="bx bxl-facebook"></i>
										</a>
									</Link>
								</li>
								<li>
									<Link to="#">
										<a className="d-block" target="_blank">
											<i className="bx bxl-twitter"></i>
										</a>
									</Link>
								</li>
								<li>
									<Link to="#">
										<a className="d-block" target="_blank">
											<i className="bx bxl-instagram"></i>
										</a>
									</Link>
								</li>
								<li>
									<Link to="#">
										<a className="d-block" target="_blank">
											<i className="bx bxl-linkedin"></i>
										</a>
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6">
						<div className="single-footer-widget pl-4">
							<h3>Populares</h3>

							<ul className="quick-links">
								<li>
									<Link to="/about">
										<a>About</a>
									</Link>
								</li>
								<li>
									<Link to="/cursos">
										<a>Cursos</a>
									</Link>
								</li>
								<li>
									<Link to="/in-company">
										<a>In Company</a>
									</Link>
								</li>
								<li>
									<Link to="/consultorias">
										<a>Consultoría</a>
									</Link>
								</li>
								<li>
									<Link to="/blog">
										<a>Blog</a>
									</Link>
								</li>

								<li>
									<Link to="/contact">
										<a>Contacto</a>
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6">
						<div className="single-footer-widget">
							<h3>Newsletter</h3>

							<div className="footer-newsletter-box">
								<p>Recibí las últimas novedades y mantenete informado.</p>

								<form className="newsletter-form">
									<label>Email:</label>
									<input
										type="email"
										className="input-newsletter"
										placeholder="Ingrese su correo"
										name="email"
										onChange={(evt) => setCurrentEmail(evt.target.value)}
										required
									/>
									<button
										type="submit"
										onClick={handleSubmit}
										disabled={loading}
									>
										{loading ? (
											<ClipLoader
												css={override}
												size={mobile ? 20 : 50}
												color={"#4d7c8a"}
												loading={true}
											/>
										) : (
											"SUSCRIBIRME"
										)}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-bottom-area">
					<div className="row align-items-center">
						<div className="col-lg-5 col-md-5">
							<ul
								className="footer-contact-info"
								style={{ listStyle: "none", paddingLeft: "0px" }}
							>
								<li>
									<div style={{ marginTop: "10px", background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<img src={visa} style={{ height: "35px" }} />
										<img src={master} style={{ height: "35px" }} />
										<img src={oca} style={{ height: "35px" }} />
										<img src={amex} style={{
											height: '28px',
											width: '38px',
											marginLeft: '4px',
											borderRadius: '3px',
											border: '0.5px solid gray'
										}} />
										<img src={diners} style={{
											height: '28px',
											padding: '2px 0px',
											width: '38px',
											marginLeft: '4px',
											borderRadius: '3px',
											border: '0.5px solid gray'
										}} />
									</div>
								</li>
								<li style={{ fontSize: "14px", color: "white" }}>
									Aceptamos <strong>VISA</strong>,<strong> MasterCard</strong>, <strong> American Express</strong> y{" "}
									<strong>OCA</strong>. Hasta 12 cuotas sin interés.
								</li>

								<li
									style={{ fontSize: "14px", marginTop: "0px", color: "white" }}
								>
									{" "}
									Por transferencia bancaria{" "}
									<a
										href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20Transferencia%20"
										style={{ color: "#FFBE58" }}
									>
										consulte aquí.
									</a>
								</li>
							</ul>
						</div>
						<div className="col-lg-7 col-md-7">
							<div className="darwin-logo">
								<p
									style={{ fontSize: "14px", textAlign: 'end' }}
								>
									Todos los derechos reservados.{" "}
									<i className="bx bx-copyright"></i>
									{currentYear} Designed By{" "}
									<a
										href="https://www.instagram.com/darwin_labs/"
										target="_blank"
									>
										Darwin Labs.
									</a>{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="lines">
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
			</div>
		</footer>
	);
};

export default Footer;
