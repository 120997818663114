import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { firebase } from "../../../firebase";
import { withRouter } from "react-router-dom";

const containerStyle = css({
	position: "relative",
});

class EditBannerModal extends Component {
	state = {
		modal: false,
		title: "",
		content: "",
		buttonLink: "",
		buttonText: "",
		bannerId: "",
	};

	componentDidMount() {
		const { banner } = this.props;
		if (banner) {
			this.setState({
				bannerId: banner.id,
				title: banner.title,
				content: banner.content,
				buttonLink: banner.buttonLink,
				buttonText: banner.buttonText,
			});
		}
	}

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	editBanner = (e) => {
		e.preventDefault();
		let { title, bannerId, content, buttonLink, buttonText } = this.state;

		const banner = {
			title: title,
			content: content,
			buttonLink: buttonLink,
			buttonText: buttonText,
		};

		if (title != "" && content != "") {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("banners");
			dbOrderRef
				.doc(bannerId)
				.update(banner)
				.then(() => {
					toast.success("El banner fue editado exitosamente.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						window.location.reload();
					}, 3000);
					this.closeModal();
					this.resetForm();
				});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer className={containerStyle} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Editar Banner</h3>
							<label>
								*Si existía un banner, se sobreescribirá la información. Los
								campos con <strong>*</strong> son obligatorios. En caso de dejar
								los campos del botón en blanco, el mismo no se mostrará.
							</label>

							<form onSubmit={this.editBanner}>
								<div className="form-group">
									<label>
										Título <strong>*</strong>
									</label>{" "}
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>

								<div className="form-group">
									<label>
										Contenido <strong>*</strong>
									</label>
									<textarea
										type="text"
										className="form-control"
										value={this.state.content}
										onChange={(e) => this.setState({ content: e.target.value })}
									/>
								</div>

								<div className="form-group">
									<label>Texto del botón</label>
									<p style={{ fontSize: "12px" }}>
										*Ej: VER, COMPRAR, CONSULTAR
									</p>
									<textarea
										type="text"
										className="form-control"
										value={this.state.buttonText}
										onChange={(e) =>
											this.setState({
												buttonText: e.target.value?.toUpperCase(),
											})
										}
									/>
								</div>

								<div className="form-group">
									<label>Link del botón</label>
									<p style={{ fontSize: "12px" }}>
										*Ej: https://gabrielvallone.com.uy/blog
									</p>
									<input
										type="text"
										className="form-control"
										value={this.state.buttonLink}
										onChange={(e) =>
											this.setState({ buttonLink: e.target.value })
										}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Editar Banner
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(EditBannerModal);
