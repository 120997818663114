import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import ColoredNavbar from '../components/Layouts/ColoredNavbar';
import CheckoutContent from '../components/Checkout/CheckoutContent';
import Footer from '../components/Layouts/Footer';


class Checkout extends Component {

    state ={
        course:null,
        courses:null,
        amountOfPeople:null
    }

    

    render() {
        return (
            <React.Fragment>
                <ColoredNavbar />
                <CheckoutContent course_id={this.props.match.params.id}  amountOfPeople={this.props.match.params.amount_of_people}/>
                <Footer />
            </React.Fragment>
        );
    }
}

const WrappedCheckout = (withRouter(Checkout));

export default  WrappedCheckout;