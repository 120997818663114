import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { firebase } from "../../../firebase";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { editCategory, editCourse } from "../../../store/actions/cartActions";

const containerStyle = css({
	position: "relative",
});

class EditCategoryModal extends Component {
	state = {
		modal: false,
		title: "",
		categoryId: "",
	};

	componentDidMount() {
		const { category } = this.props;
		if (category) {
			this.setState({
				categoryId: category.id,
				title: category.title,
			});
		}
	}

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	editCategory = (e) => {
		e.preventDefault();
		let { title, categoryId } = this.state;

		const category = {
			title: title,
		};

		if (title != "") {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("categories");
			dbOrderRef
				.doc(categoryId)
				.update(category)
				.then(() => {
					const { courses } = this.props;

					let coursesToUpdate = [];

					courses.forEach((c) => {
						if (c && c.category && c.category.id == categoryId) {
							coursesToUpdate.push(c);
						}
					});

					if (coursesToUpdate && coursesToUpdate.length > 0) {
						coursesToUpdate.forEach((course) => {
							const newCourse = { ...course };
							newCourse.category = category;
							newCourse.category.id = categoryId;

							const courseId = course.id;
							const db = firebase.firestore();
							const dbOrderRef = db.collection("courses");
							dbOrderRef
								.doc(courseId)
								.update(newCourse)
								.then(() => {
									this.props.editCourse(newCourse);
								});
						});
					}

					this.props.editCategory(category);
					toast.success("La categoría fue editada exitosamente.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						window.location.reload();
					}, 3000);
					this.closeModal();
					this.resetForm();
				});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
				<ToastContainer className={containerStyle} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Editar Categoría</h3>
							<label>*Esta categoría será para agrupar tus cursos.</label>

							<form onSubmit={this.editCategory}>
								<div className="form-group">
									<label>Nombre</label>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Editar Categoría
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		courses: state.courses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editCategory: (category) => {
			dispatch(editCategory(category));
		},
		editCourse: (course) => {
			dispatch(editCourse(course));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EditCategoryModal));
