import { useState, useEffect, useCallback } from "react";

function useForm(stateSchema, validationSchema = {}, callback, amountOfPeople) {
	const [state, setState] = useState(stateSchema);
	const [disableAll, setDisableAll] = useState(false);
	const [disable, setDisable] = useState(false);
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		const notForOne = !(
			state &&
			state.firstName?.value &&
			state.lastName?.value &&
			state.email?.value &&
			state.phone?.value &&
			state.country?.value &&
			state.emailConfirmation?.value &&
			state.city?.value &&
			state.location?.value &&
			state.address?.value &&
			state.document?.value &&
			state.documentType?.value
		);

		const notForTwo = !(
			state &&
			state.firstName2?.value &&
			state.lastName2?.value &&
			state.email2?.value &&
			state.phone2?.value &&
			state.country2?.value &&
			state.emailConfirmation2?.value &&
			state.city2?.value &&
			state.location2?.value &&
			state.address2?.value &&
			state.document2?.value &&
			state.documentType2?.value
		);

		const notForThree = !(
			state &&
			state.firstName3?.value &&
			state.lastName3?.value &&
			state.email3?.value &&
			state.phone3?.value &&
			state.country3?.value &&
			state.emailConfirmation3?.value &&
			state.city3?.value &&
			state.location3?.value &&
			state.address3?.value &&
			state.document3?.value &&
			state.documentType3?.value
		);
		if (
			(amountOfPeople == 1 && notForOne) ||
			(amountOfPeople == 2 && (notForOne || notForTwo)) ||
			(amountOfPeople == 3 && (notForOne || notForTwo || notForThree))
		) {
			setDisable(true);
		}
	}, []);

	useEffect(() => {
		if (isDirty) {
			setDisable(validateState());
		}
	}, [state, isDirty]);

	// Used to disable submit button if there's an error in state
	// or the required field in state has no value.
	// Wrapped in useCallback to cached the function to avoid instensive memory leaked
	// in every re-render in component
	const validateState = useCallback(() => {
		const hasErrorInState = Object.keys(validationSchema).some((key) => {
			const isInputFieldRequired = validationSchema[key].required;
			// Get state value
			const stateValue = state[key]?.value;
			const stateError = state[key]?.error;

			return (isInputFieldRequired && !stateValue) || stateError;
		});

		return hasErrorInState;
	}, [state, validationSchema]);

	function handleOnChange(event) {
		setIsDirty(true);

		const name = event.target.name;
		const value = event.target.value;

		let error = "";
		if (validationSchema[name]) {
			if (validationSchema[name].required) {
				if (!value) {
					error = "Este campo es requerido.";
				}
			}

			if (
				validationSchema[name].validator !== null &&
				typeof validationSchema[name].validator === "object"
			) {
				if (validationSchema[name].validator.regEx) {
					if (value && !validationSchema[name].validator.regEx.test(value)) {
						error = validationSchema[name].validator.error;
					}
				}
			}
		}

		setState((prevState) => ({
			...prevState,
			[name]: { value, error },
		}));
	}

	function clearAll() {
		setState((prevState) => ({
			firstName: { value: "", error: "" },
			lastName: { value: "", error: "" },
			email: { value: "", error: "" },
			phone: { value: "", error: "" },
			country: { value: "", error: "" },
			emailConfirmation: { value: "", error: "" },
			city: { value: "", error: "" },
			location: { value: "", error: "" },
			address: { value: "", error: "" },
			document: { value: "", error: "" },
			documentType: { value: "", error: "" },
			firstName2: { value: "", error: "" },
			lastName2: { value: "", error: "" },
			email2: { value: "", error: "" },
			phone2: { value: "", error: "" },
			country2: { value: "", error: "" },
			emailConfirmation2: { value: "", error: "" },
			city2: { value: "", error: "" },
			location2: { value: "", error: "" },
			address2: { value: "", error: "" },
			document2: { value: "", error: "" },
			documentType2: { value: "", error: "" },
			firstName3: { value: "", error: "" },
			lastName3: { value: "", error: "" },
			email3: { value: "", error: "" },
			phone3: { value: "", error: "" },
			country3: { value: "", error: "" },
			emailConfirmation3: { value: "", error: "" },
			city3: { value: "", error: "" },
			location3: { value: "", error: "" },
			address3: { value: "", error: "" },
			document3: { value: "", error: "" },
			documentType3: { value: "", error: "" },
			payedBy3: { value: "", error: "" },
		}));
	}

	function handleOnSubmit(event) {
		event.preventDefault();

		// Making sure that validateState returns false
		// Before calling the submit callback function
		if (!validateState()) {
			callback();
		}
	}

	return {
		state,
		disable,
		handleOnChange,
		handleOnSubmit,
		clearAll,
		setDisableAll,
		disableAll,
	};
}

export default useForm;
