import React, { Component } from "react";
import axios from "axios";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import backendUrl from "../../constants/urls";
import { encodeUser } from "../../methods/api-calls";

const override = css`
	display: flex;
	justify-content: center;
	margin: auto;
	margin-bottom: 50px;
	margin-top: 50px;
`;

const errorStyle = {
	color: "red",
	fontSize: "13px",
};

class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			emailError: "",
			nameError: "",
			messageError: "",
			name: "",
			message: "",
			loading: false,
		};
		this.validateEmail = this.validateEmail.bind(this);
		this.validateName = this.validateName.bind(this);
		this.validateMessage = this.validateMessage.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleMessageChange = this.handleMessageChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleNameChange(event) {
		this.setState({ nameError: "", name: event.target.value });
	}

	handleEmailChange(event) {
		this.setState({ emailError: "", email: event.target.value });
	}

	handleMessageChange(event) {
		this.setState({ messageError: "", message: event.target.value });
	}

	validateEmail() {
		var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!reg.test(this.state.email)) {
			this.setState({
				emailError: "Ingrese un email válido.",
			});
			return false;
		}
		return true;
	}

	validateName() {
		var reg = /^([a-z \u00E0-\u00FC]+[,.]?[ ]?|[a-z \u00E0-\u00FC]+['-]?)+$/i;
		if (!reg.test(this.state.name) || !this.state.name.trim()) {
			this.setState({
				nameError: "Ingrese un nombre válido.",
			});
			return false;
		}
		return true;
	}

	validateMessage() {
		if (this.state.message == null || this.state.message.trim() == "") {
			this.setState({
				messageError: "Ingrese un mensaje.",
			});
			return false;
		}
		return true;
	}

	async handleSubmit(e) {
		e.preventDefault();
		var email = this.validateEmail();
		var name = this.validateName();
		var message = this.validateMessage();
		if (email && name && message) {
			var data = {
				name: this.state?.name,
				email: this.state?.email,
				message: this.state?.message,
			};
			try {
				const response = await axios.post(
					`${backendUrl}api/mailer/receive`,
					data,
					{
						headers: encodeUser(),
					}
				);

				if (response) {
					if (200 === response.status) {
						if (response.data?.error) {
							alert(response.data?.error);
						} else {
							// Success
							this.setState({
								name: "",
								email: "",
								message: "",
							});
							toast.success("Mensaje enviado exitosamente!", {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
							});
						}
					}
				}
			} catch (error) {
				// If another error
				alert(error, "red");
			}
		}
	}

	render() {
		var isMobile = false;
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}
		return (
			<section className="contact-area ptb-100 special-ptb special-pb">
				<div className="container pt-50 special-ptb special-pb">
					<div className="row">
						<div className="col-lg-2 col-md-2"></div>
						<div className="col-lg-8 col-md-8">
							<div className="contact-form">
								<h3>Contacto</h3>

								<form id="contactForm">
									<div className="row">
										<div className="col-lg-12 col-md-6">
											<div className="form-group">
												<label>
													Nombre <span>*</span>
												</label>
												<input
													type="text"
													name="name"
													id="name"
													value={this.state.name}
													onChange={this.handleNameChange}
													className="form-control"
													required
													placeholder="Nombre"
												/>
												{this.state.nameError && (
													<p style={errorStyle}>{this.state.nameError}</p>
												)}
											</div>
										</div>

										<div className="col-lg-12 col-md-6">
											<div className="form-group">
												<label>
													Email <span>*</span>
												</label>
												<input
													type="email"
													name="email"
													id="email"
													value={this.state.email}
													onChange={this.handleEmailChange}
													className="form-control"
													required
													placeholder="Email"
												/>
												{this.state.emailError && (
													<p style={errorStyle}>{this.state.emailError}</p>
												)}
											</div>
										</div>

										<div className="col-lg-12 col-md-12">
											<div className="form-group">
												<label>
													Mensaje <span>*</span>
												</label>
												<textarea
													name="message"
													id="message"
													cols="30"
													value={this.state.message}
													onChange={this.handleMessageChange}
													rows="5"
													required
													className="form-control"
													placeholder="Escriba su mensaje..."
												></textarea>
												{this.state.messageError && (
													<p style={errorStyle}>{this.state.messageError}</p>
												)}
											</div>
										</div>

										<div className="col-lg-12 col-md-12">
											<button
												onClick={this.handleSubmit}
												className="default-btn"
											>
												Enviar
											</button>
											{this.state.loading && (
												<BeatLoader
													css={override}
													size={isMobile ? 50 : 100}
													color={"#4d7c8a"}
													loading={true}
												/>
											)}

											<ToastContainer />
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default ContactForm;
