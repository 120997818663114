import React, { Component } from "react";
import { PAYMENT_TRANSLATIONS } from "../../../constants/payment_types";
import { toast } from "react-toastify";
import { firebase } from "../../../firebase";

class OrderTR extends Component {
	state = {
		orderUpdateModal: true,
	};

	toggleOrderUpdateModal = () => {
		this.setState({
			orderUpdateModal: !this.state.orderUpdateModal,
		});
		this.props.onOrderModal(this.state.orderUpdateModal);
	};

	passId = (order) => {
		this.props.onPassId(order);
	};

	convertDateTime = (d) => {
		if (d) {
			let t = new Date(1970, 0, 1);
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		} else {
			return "";
		}
	};

	convertToFilter = (d) => {
		if (d) {
			let t = new Date(1970, 0, 1);
			return t.setSeconds(d.seconds);
		}
		return null;
	};

	deleteOrder = (e, order) => {
		e.stopPropagation();
		const db = firebase.firestore();
		const dbOrderRef = db.collection("orders");
		dbOrderRef
			.doc(order.id)
			.delete()
			.then(() => {
				const { items } = order;

				let coursesToUpdate = [];

				items.forEach((c) => {
					coursesToUpdate.push(c);
				});

				if (coursesToUpdate && coursesToUpdate.length > 0) {
					coursesToUpdate.forEach((course) => {
						const courseId = course.id;
						const db = firebase.firestore();
						const dbOrderRef = db.collection("courses");
						dbOrderRef
							.doc(courseId)
							.get()
							.then((doc) => {
								if (doc.exists) {
									let ob = doc.data();
									db.collection("courses")
										.doc(courseId)
										.update({ quantity: ob.quantity + 1 })
										.then(() => {
											// Delete completed!
											toast.success(
												"La inscipción fue eliminada exitosamente.",
												{
													position: "bottom-left",
													autoClose: 3000,
													hideProgressBar: false,
													closeOnClick: true,
													pauseOnHover: true,
													draggable: true,
												}
											);
											setTimeout(() => {
												window.location.reload();
											}, 3000);
										});
								} else {
									// Delete completed!
									toast.success("La inscipción fue eliminada exitosamente.", {
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									});
									setTimeout(() => {
										window.location.reload();
									}, 3000);
								}
							});
					});
				} else {
					toast.success("La inscipción fue eliminada exitosamente.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				}
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	statusBtn = (status) => {
		if (status === "pending") {
			return <span className="badge badge_warning">Pendiente</span>;
		} else if (status === "cancel") {
			return <span className="badge badge_danger">Rechazado</span>;
		} else {
			return <span className="badge badge_success">Aprobado</span>;
		}
	};

	fakeStatus = (status) => {
		if (status === "pending") {
			return "Red de cobranza";
		} else if (status === "cancel") {
			return "-";
		} else {
			return "Tarjeta";
		}
	};

	render() {
		let { orders } = this.props;
		let filteredOrders = orders;
		// if (orders?.length) {
		// 	filteredOrders = orders.sort((a, b) => {
		// 		if (a.createdAt && b.createdAt) {
		// 			return (
		// 				new Date(this.convertToFilter(b.createdAt)) -
		// 				new Date(this.convertToFilter(a.createdAt))
		// 			);
		// 		} else if (a.createdAt && !b.createdAt) {
		// 			return -1;
		// 		} else if (!a.createdAt && b.createdAt) {
		// 			return 1;
		// 		} else {
		// 			return 0;
		// 		}
		// 	});
		// }

		return (
			<React.Fragment>
				{filteredOrders?.length &&
					filteredOrders.map((order, i) => {
							console.log(order.paymentType)

						
						return (
							<tr
								key={i}
								onClick={(e) => {
									this.toggleOrderUpdateModal();
									this.passId(order);
								}}
								style={{
									backgroundColor:
										order.courseStatus === "approved" ? "#00800014" : "",
								}}
							>
								<td>{`${order?.customerDetails?.lastName?.toUpperCase()}, ${order?.customerDetails?.firstName?.toUpperCase()}`}</td>
								<td>{this.convertDateTime(order.createdAt)}</td>
								<td style={{ fontSize: "10px" }}>
									{order?.items?.length ? order.items[0]?.title : "-"}
								</td>
								<td>{order.customerDetails.phone || "-"}</td>
								<td>{order.customerDetails.email || "-"}</td>
								<td>{order.customerDetails.country || "-"}</td>
								<td>
									<strong>
										{order.amount > 0 ? `$${order.amount}` : "GRATUITO"}
									</strong>
								</td>
								<td>{order.usedCoupon ? "Si" : "No"}</td>
								<td>
									{order.amount == 0
										? "-"
										: order.paymentType
										? PAYMENT_TRANSLATIONS.find(
												(p) => p.id == order.paymentType
										  )
											? PAYMENT_TRANSLATIONS.find(
													(p) => p.id == order.paymentType
											  )?.label
											: "No hay info"
										: "No hay info"}
								</td>
								<td>{this.statusBtn(order.status)}</td>
								<td>{order.isPartOfPlan ? "Si" : "No"}</td>
								<td>{order.payedBy || "-"}</td>
								<td>
									{" "}
									<button
										onClick={(e) => {
											if (
												window.confirm(
													"¿Está seguro que desea eliminar ésta inscirpción?"
												)
											)
												this.deleteOrder(e, order);
										}}
										className="invisible-btn"
										style={{ background: "#194E5F" }}
									>
										<a>
											<i className="bx bx-x"></i>
											<span
												className="tooltip-label"
												style={{ fontWeight: "bold" }}
											>
												Eliminar
											</span>
										</a>
									</button>
								</td>
							</tr>
						);
					})}
			</React.Fragment>
		);
	}
}

export default OrderTR;
