import React, { Component } from 'react';
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/Layouts/Footer';
import { Link } from 'react-router-dom';

class Failure extends Component {
    render() {
        return (
            <React.Fragment>
                <ColoredNavbar />

                <section className="error-area ptb-100">
                    <div className="container">
                        <div className="error-content">
                            <h3>Error en la suscripción</h3>
                            <p>Ha habido un error en tu proceso de suscripción. Intentalo más tarde.</p>
                            <Link to="/">
                                <a className="default-btn text-white">Ir al inicio</a>
                            </Link>
                        </div>
                    </div>
                </section>

                <Footer />
            </React.Fragment>
        );
    }
}

export default Failure ;