import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import cloud from "../../images/icons/cloud.png";
import { firebase } from "../../firebase";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import { isMobile } from "../../helpers/generic";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

const options = {
	loop: true,
	nav: false,
	dots: true,
	autoplayHoverPause: false,
	autoplay: true,
	center: true,
	autoplayTimeout: 5500,
	autoplaySpeed: 2000,
	navText: [
		"<i class='flaticon-left'></i>",
		"<i class='flaticon-right-arrow'></i>",
	],
	responsive: {
		0: {
			items: 1,
		},
		576: {
			items: 2,
		},
		768: {
			items: 2,
		},
		1200: {
			items: 3,
		},
	},
};

class Testimonials extends Component {
	_isMounted = false;
	state = {
		loading: false,
		testimonials: [],
	};
	componentDidMount() {
		this._isMounted = true;
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("testimonies");
		let testimoniesArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let testimoniesObj = doc.data();
					testimoniesObj.id = doc.id;
					testimoniesArray.push(testimoniesObj);
				});
				testimoniesArray = testimoniesArray.sort((p1, p2) => {
					if (p1.priority && !p2.priority) {
						return -1;
					} else if (!p1.priority && p2.priority) {
						return 1;
					} else {
						return p2.priority - p1.priority;
					}
				});
				this.setState({
					testimonials: testimoniesArray,
					loading: false,
				});
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<section className="testimonials-area pt-100">
				<div className="container">
					<div className="section-title">
						<h2>TESTIMONIOS</h2>
					</div>

					{/* {!this.state.loading ? ( */}
						<OwlCarousel
							className="testimonials-slides owl-carousel owl-theme"
							{...options}
						>
							{this.state.testimonials?.length ? (
								this.state.testimonials.map((t) => (
									<div className="single-testimonials-item">
										<p>{`“${t.content}”`}</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												{`${t.name} ${t.year ? `, ${t.year}` : ""}`}
											</h6>
										</div>
									</div>
								))
							) : (
								<>
									<div className="single-testimonials-item">
										<p>
											“Cuando dijeron que lo iban a hacer por Zoom creí que no
											iba a estar tan bueno como fue la parte presencial, pero
											la verdad es que estuvo genial y Gabriel hace que la clase
											sea espectacular”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Taller presencial a distancia, 2020
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“El taller te da un método y organiza la manera que
											negociamos naturalmente. Potencia mucho lo natural a la
											vez que da nuevas herramientas”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Taller abierto al público, 2017
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Brinda herramientas muy útiles para la negociación. Muy
											bien organizada la clase y el profesor tenía un gran
											dominio del tema.”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Taller abierto al público, 2017
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“El taller es de gran utilidad en todos los ámbitos de la
											vida. Lo recomendaría por el contenido y por el profesor.”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Taller abierto al público, 2017
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Es excelente el enfoque y los conocimientos de Vallone.
											Muy buen programa, buenos ejemplos y valioso para aplicar
											en el día a día”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Taller abierto al público, 2018
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Lo recomendaría por la claridad y practicidad del
											contenido. Por su gran utilidad ante las más diversas
											situaciones personales y profesionales”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Taller abierto al público, 2019
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Además de dominar la técnica, es muy entretenida la forma
											de presentación. Realmente fue un enorme placer haber
											concurrido a este curso"
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												ADT, 2010
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“El taller me resultó excelente, bien planteado
											principalmente por la gran variedad de conceptos y
											ejemplos reales”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Disco, 2015
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“El profesor transmite los conceptos con claridad. Gran
											dominio de la materia, muy dinámico y atrapante”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Disco, 2015
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Me encantó. Muy bueno y valorable. Transmitió mucha
											experiencia en la dinámica de negociación y en la
											aplicación del método”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Ducsa, 2018
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>“Excelente dinámica y utilización de casos prácticos”</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Banco Itaú, Comunicación Efectiva, 2013
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Muy buena disposición. Interactivo, ágil, divertido.
											Gabriel es un excelente profesor y persona”
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Banco Itaú, Comunicación Efectiva, 2016
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“El taller fue muy dinámico y ágil. Me gustó mucho, me
											resultó de mucha utilidad. El profesor súper claro y
											dinámico.”{" "}
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Nuvó, 2013
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Muy buen seminario, práctico, dinámico. Lo comencé a
											aplicar desde el primer día en mi trabajo y realmente
											funciona. Excelente profesor!”{" "}
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Roche, 2019
											</h6>
										</div>
									</div>

									<div className="single-testimonials-item">
										<p>
											“Realmente me pareció excelente el manejo que tiene el
											profesor del curso, el dinamismo y capacidad de transmitir
											los conceptos y los ejemplos fue realmente excelente”{" "}
										</p>

										<div className="info">
											<img
												src={cloud}
												className="shadow rounded-circle"
												style={{ borderColor: "#184d5e", padding: "10px" }}
												alt="image"
											/>
											<h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
												Roemmers Hi-Tech, 2008{" "}
											</h6>
										</div>
									</div>
								</>
							)}
						</OwlCarousel>
					{/* ) : (
						<BeatLoader
							css={override}
							size={isMobile() ? 50 : 100}
							color={"#4d7c8a"}
							loading={true}
						/>
					)} */}
				</div>
			</section>
		);
	}
}

export default Testimonials;
