import React, { Component } from "react";
import { Link } from "react-router-dom";

class LookbookGrid4 extends Component {
	render() {
		return (
			<section className="lookbook-area  pb-70">
				<h3 style={{ fontSize: "30px", marginBottom: "50px", textAlign: "center" }}>
					Empresas que confiaron en nosotros:
				</h3>

				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/roemmers.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Laboratorios Roemmers</h7>
								</div>
							</div>
						</div>

						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Roche.jpg")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Roche Uruguay</h7>
								</div>
							</div>
						</div>

						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Megapharma.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Mega Pharma</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/UPM.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Botnia-UPM</h7>
								</div>
							</div>
						</div>

						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/HSBC.jpg")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Banco HSBC</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Banco Surinvest.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Banco Surinvest</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Pronto.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Pronto</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Linde.jpg")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Linde Uruguay</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Cementos Artigas.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Cementos Artigas</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Moviecentr.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Moviecenter</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Hotel Nirvana.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Hotel Nirvana</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Atma.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Plásticos ATMA</h7>
								</div>
							</div>
						</div>

						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Neutral.png")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Neutral Free Shop</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/TCC.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>TCC - Televisión cable digital</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/CUTI.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>CUTI</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Logipark.png")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Logipark</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/AGESIC.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Agesic</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/ADT.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>ADT Security Services</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Canal 10 .png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Canal 10</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/canarias.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Canarias</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/DFA Duty Free.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Duty Free Américas</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Montes del plata.jpeg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Montes del Plata</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Specialized.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Specialized</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/berkes.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Berkes</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Itau.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Banco Itaú</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Advice.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Advice</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Lacnic.svg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Lacnic</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Ministerio de economia y finanzas.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Ministerio de economía y finanzas</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Integracion Afap.svg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Integración AFAP</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Consorcio del uruguay.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Consorcio del Uruguay</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/San Roque.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>San Roque</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Banco Bandes.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Banco Bandes</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Nuvo Tupperware.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Nuvó - Taperware</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Urufarma.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Urufarma</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/ANII.jpg")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>ANII</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Grupo Disco.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Grupo Disco</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Lideco.png")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Liga de defensa comercial</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Montecon.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Montecon</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Pernod Ricard.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Pernod Ricard</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/AIG seguros.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>American International Group</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Itau.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>PYMES clientes de banco itaú</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Behar y cia .png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Behar & Cia.</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Corporacion nacional desarrollo.png")}
										alt="image"
									/>
								</div>
								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Corporación Nacional para el Desarrollo</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/BBVA.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Banco BBVA</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/UPM.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>UPM - Forestal Oriental</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/ANDE.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Agencia Nacional de Desarrollo</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Campiglia Pilay.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Campiglia - Pilay</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Summum.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Summum</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/DUCSA.jpg")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>DUCSA</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/Megalabs.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Megalabs</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/vistage.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Vistage</h7>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="single-lookbook-box">
								<div className="image-div">
									<img
										src={require("../../images/partner/ALUR.png")}
										alt="image"
									/>
								</div>

								<div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
									<h7 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>Alur</h7>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default LookbookGrid4;
