import React, { Component } from "react";
import TopHeader from "../components/Layouts/TopHeader";
import Navbar from "../components/Layouts/Navbar";
import PageBanner from "../components/Common/PageBanner";
import FacilitySlider from "../components/Common/FacilitySlider";
import InstagramFeed from "../components/Common/InstagramFeed";
import Footer from "../components/Layouts/Footer";
import { Link } from "react-router-dom";
import OfferProducts from "../components/HomeFour/OfferProducts";
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import LookbookGrid4 from "../components/Lookbook/LookbookGrid4";

class CustomerService extends Component {
	render() {
		return (
			<React.Fragment>
				<ColoredNavbar />
				<PageBanner
					pageTitle="IN COMPANY"
					homePageUrl="/"
					homePageText="Home"
					activePageText="In company" 
				/>

				<section className="customer-service-area ptb-100">
					<div className="container">
						<div className="customer-service-content">
						<h3 style={{ fontSize: "30px", marginBottom: "20px" }}>
								Conozca la dinámica <i>In Company</i>
							</h3>
							<p>
								Todos los talleres pueden ser realizados en formato In-Company,
								de manera presencial y presencial a distancia. Se trata de una
								modalidad ajustada a la realidad y necesidades específicas del
								grupo objetivo al que va dirigida la capacitación.
							</p>

                            <h3 style={{  marginBottom: "20px" }}>
								Aporte herramientas para su empresa
							</h3>

							<p>
								Más de seis mil personas pertenecientes a empresas de diversos
								sectores y actividades han recibido los talleres de negociación,
								conversaciones difíciles, comunicación persuasiva, negociación
								avanzada, cultura de atención al cliente y otros programas, y en
								todos los casos fueron evaluados en niveles de excelencia.
							</p>

                            <h3 style={{ fontSize: "30px", marginBottom: "20px" }}>
								Propuesta
							</h3>

							<p>
								Los programas de negociación y comunicación In-Company están
								diseñados para que cada participante logre aprovechar la
								metodología propuesta y adaptarla de manera productiva a su
								función en la organización. Han funcionado con éxito a todo
								nivel dentro de la organización, desde la primera línea hasta la
								alta gerencia.
							</p>

                            <h3 style={{ fontSize: "30px", marginBottom: "20px" }}>
								Metodología
							</h3>

							<p>
								La efectividad de los talleres In-Company se debe principalmente
								a su diseño focalizado y práctico, basado en casos reales y
								cargado de vivencias y ejemplos que logran transferir a los
								participantes una parte de mi experiencia como negociador
								profesional, en cargos directivos y como consultor en
								negociación.
							</p>

							<OfferProducts />
							<LookbookGrid4 />

						</div>
					</div>
				</section>

				<Footer />
			</React.Fragment>
		);
	}
}

export default CustomerService;
