import React from 'react';
import { Route, BrowserRouter as VendorRouter, Switch } from 'react-router-dom';

import { routes } from './routes';

const Router = () => (
  <VendorRouter>
    <Switch>
      {routes.map(route => (
        <Route key={route.path} {...route} />
      ))}
    </Switch>
  </VendorRouter>
);

export { Router };
