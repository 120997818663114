import React, { useState, useEffect } from "react";
import { firebase } from "../../firebase";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import { isMobile } from "../../helpers/generic";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

const InstitutionalVideo = () => {
	const [currentVideo, setCurrentVideo] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			const db = firebase.firestore();
			let dbOrderRef = db.collection("institutionalVideos");
			let videosArray = [];
			try {
				const res = await dbOrderRef.get();
				res.forEach((doc) => {
					let bannersObj = doc.data();
					bannersObj.id = doc.id;
					videosArray.push(bannersObj);
				});
				setCurrentVideo(videosArray?.length ? videosArray[0] : null);
			} catch (err) {
				console.log("error", err);
			}
			setLoading(false);
		};
		fetchData();
	}, []);

	let youtubeLink = "";
	if (currentVideo?.videoLink) {
		const aux = currentVideo?.videoLink.split("?v=");
		if (aux && aux.length > 1) {
			youtubeLink = aux[1].split("&")[0];
		}
	}
	return (
		<section
			className="offer-area bg-image5 ptb-100 jarallax"
			data-jarallax='{"speed": 0.3}'
		>
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12 col-md-12">
						{loading ? (
							<BeatLoader
								css={override}
								size={isMobile() ? 50 : 100}
								color={"#4d7c8a"}
								loading={true}
							/>
						) : (
							currentVideo?.videoLink && (
								<div className="institutional-video">
									<iframe
										allow="autoplay; encrypted-media"
										allowFullScreen
										frameborder="0"
										src={`https://www.youtube.com/embed/${youtubeLink}?autoplay=1&mute=1&controls=0&loop=1&modestbranding=1&showinfo=0`}
									></iframe>
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default InstitutionalVideo;
