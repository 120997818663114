import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditProfessorModal from "../../components/Admin/Modals/EditProfessorModal";
import withAuth from "../../helpers/withAuth";
import { removeProfessor, editCourse } from "../../store/actions/cartActions";
import { connect } from "react-redux";
import noPhoto from "../../images/products/noImage.png";
import ViewProfessorModal from "../../components/Admin/Modals/ViewProfessorModal";

const containerStyle = css({
	position: "relative",
});

class Professors extends Component {
	// Edit Professor Modal
	state = {
		display: false,
		professors: [],
		loading: true,
		showEditModal: false,
		modalProfessor: null,
	};

	// View Professor Modal
	toggleViewProfessorModal = () => {
		this.setState({
			ViewProfessorModal: !this.state.ViewProfessorModal,
		});
	};

	toggleEditProfessorModal = (professor = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalProfessor: professor,
		});
	};

	dataModal = (professor) => {
		this.setState({
			modalProfessor: professor,
		});
	};

	deleteProfessor = (professor) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("professors");
		dbOrderRef
			.doc(professor.id)
			.delete()
			.then(() => {
				this.props.removeProfessor(professor.id);

				const { courses } = this.props;

				let coursesToUpdate = [];

				courses.forEach((c) => {
					if (c.professor.id == professor.id) {
						coursesToUpdate.push(c);
					}
				});

				if (coursesToUpdate && coursesToUpdate.length > 0) {
					coursesToUpdate.forEach((course) => {
						const newCourse = { ...course };
						newCourse.professor = null;

						const courseId = course.id;
						const db = firebase.firestore();
						const dbOrderRef = db.collection("courses");
						dbOrderRef
							.doc(courseId)
							.update(newCourse)
							.then(() => {
								this.props.editCourse(newCourse);
							});
					});
				}
				// Delete completed!
				toast.success("El profesor fue eliminado/a exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("professors");
		let professorsArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let professorsObj = doc.data();
					professorsObj.id = doc.id;
					professorsArray.push(professorsObj);
				});
				this.setState({
					professors: professorsArray,
					loading: false,
				});
				this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { professors, loading } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Profesores</h2>
					</div>

					<div className="admin-products">
					<ToastContainer className={containerStyle} />
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : professors.length ? (
								professors.map((professor, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
											<div className="products-image">
												<Link to="#viewproduct">
													<a
														onClick={(e) => {
															e.preventDefault();
															this.toggleViewProfessorModal();
															this.dataModal(professor);
														}}
													>
														{" "}
														<img
															src={
																professor.profilePicture
																	? professor.profilePicture
																	: noPhoto
															}
															className="main-image"
															alt="image"
														/>
														<img
															src={
																professor.profilePicture
																	? professor.profilePicture
																	: noPhoto
															}
															className="hover-image"
															alt="image"
														/>
													</a>
												</Link>

												<div className="products-button">
													<ul>
														<li>
															<div className="wishlist-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleViewProfessorModal();
																			this.dataModal(professor);
																		}}
																	>
																		<i className="bx bx-search-alt"></i>
																		<span className="tooltip-label">Ver</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditProfessorModal(professor);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() =>
																		this.deleteProfessor(professor)
																	}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<h4 style={{marginTop: '10px'}}>{professor?.fullName}</h4>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay profesores.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditProfessorModal
						onClick={this.toggleEditProfessorModal}
						active={this.state.showEditModal ? "active" : ""}
						professor={this.state.modalProfessor}
					/>
				)}

				<ViewProfessorModal
					onClick={this.toggleViewProfessorModal}
					active={this.state.ViewProfessorModal ? "active" : ""}
					professor={this.state.modalProfessor}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		courses: state.courses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeProfessor: (id) => {
			dispatch(removeProfessor(id));
		},
		editCourse: (module) => {
			dispatch(editCourse(module));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAuth(Professors));
