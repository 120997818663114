import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditVideoModal from "../../components/Admin/Modals/EditVideoModal";
import withAuth from "../../helpers/withAuth";

class InstitutionalVideos extends Component {
	state = {
		display: false,
		institutionalVideos: [],
		loading: true,
		showEditModal: false,
		modalVideo: null,
	};

	toggleEditVideoModal = (video = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalVideo: video,
		});
	};

	dataModal = (video) => {
		this.setState({
			modalVideo: video,
		});
	};

	deleteVideo = (institutionalVideo) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("institutionalVideos");
		dbOrderRef
			.doc(institutionalVideo.id)
			.delete()
			.then(() => {
				// Delete completed!
				toast.success("El video fue eliminado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("institutionalVideos");
		let institutionalVideosArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let institutionalVideosObj = doc.data();
					institutionalVideosObj.id = doc.id;
					institutionalVideosArray.push(institutionalVideosObj);
				});
				this.setState({
					institutionalVideos: institutionalVideosArray,
					loading: false,
				});
				this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { institutionalVideos, loading } = this.state;

		let youtubeLink = "";
		let currentVideo = institutionalVideos?.length
			? institutionalVideos[0]
			: null;
		if (currentVideo?.videoLink) {
			const aux = currentVideo?.videoLink.split("?v=");
			if (aux && aux.length > 1) {
				youtubeLink = aux[1].split("&")[0];
			}
		}
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Video institucional</h2>
					</div>

					<div className="admin-products">
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : institutionalVideos.length ? (
								institutionalVideos.map((video, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
											<div className="products-image no-centered-short  products-content">
												<Link to="#viewproduct">
													<a
														onClick={(e) => {
															e.preventDefault();
															this.dataModal(video);
														}}
													></a>
												</Link>

												<iframe
													allow="autoplay; encrypted-media"
													allowFullScreen
													frameborder="0"
													src={`http://www.youtube.com/embed/${youtubeLink}?autoplay=1&mute=1&controls=0&loop=1&modestbranding=1&showinfo=0`}
												></iframe>

												<a href={video.videoLink}>
													{`Link: ${video.videoLink}`}
												</a>
												<div className="products-button special-btn">
													<ul className="horizontal-menu">
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditVideoModal(video);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() => this.deleteVideo(video)}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay videos.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditVideoModal
						onClick={this.toggleEditVideoModal}
						active={this.state.showEditModal ? "active" : ""}
						video={this.state.modalVideo}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withAuth(InstitutionalVideos);
