import React, { Component } from "react";
import { Link } from "react-router-dom";
import geometric from "../../../images/courses/geometric-new.png";
import geometricAlt from "../../../images/courses/geometric-alt-new.png";
import { ModuleModal } from "../Modules/ModuleModal";

class ModulesContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			course: this.props.course,
			ModuleModal: false,
			modalModule: {},
		};
	}

	toggleModal = () => {
		this.setState({
			ModuleModal: !this.state.ModuleModal,
		});

		this.props.ontoggleModal(this.state.ModuleModal);
	};

	async handleModalModule(module) {
		this.props.onhandleModalModule(module);
	}

	render() {
		const { course } = this.state;
		var modules =
			course &&
			course.modules &&
			course.modules.map((module, i) => {
				return (
					<div className="col-lg-6 col-sm-6">
						<div
							className="single-categories-box"
							onClick={(e) => {
								e.preventDefault();
								this.toggleModal();
								this.handleModalModule(module);
							}}
						>
							<img
								src={i % 2 === 0 ? geometric : geometricAlt}
								alt="image"
								style={{ width: "80%" }}
							/>

							<div className="content ">
								<span>Módulo {i + 1}</span>
								<h3>{module.title}</h3>
								<a className="default-btn">Leer más</a>
							</div>

							<Link to="#">
								<a className="link-btn special"></a>
							</Link>
						</div>
					</div>
				);
			});
		return (
			<section >
				<div className="container">
					<div className="row">
						<div className="section-title">
							<h2>MÓDULOS</h2>
						</div>
					</div>
					<div className="row" style={{ justifyContent: "center" }}>
						{modules}
					</div>
				</div>
			</section>
		);
	}
}
export default ModulesContainer;
