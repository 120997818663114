import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import noPhoto from "../../images/products/noImage.png";
import { css } from "@emotion/core";

class CoursePaymentModes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			course: this.props.course,
		};
	}

	dateExpired = (modules) => {
		let disabled = false;
		if (modules && modules.length > 0) {
			modules.forEach((m) => {
				const oldDate = m.date && m.date.length ? m.date.split("-") : null;
				if (oldDate) {
					const newDate = oldDate.slice(1, 3);
					newDate.push(oldDate[0]);

					let newest = new Date();
					newest.setDate(newDate[1]);
					newest.setMonth(newDate[0] - 1);
					newest.setFullYear(newDate[2]);

					if (newest < Date.now()) {
						disabled = true;
					}
				}
			});
		}
		return disabled;
	};

	render() {
		const { course } = this.state;
		let disableForOne = false;
		let disableForTwo = false;
		let disableForThree = false;
		let noDatesSet = false;
		let hasExpired =
			course && course.modules?.length > 0
				? this.dateExpired(course.modules)
				: false;
		if (!course) {
			return <Redirect to="/" />;
		} else {
			let allHaveDates = true;
			if (course?.modules?.length > 0) {
				course.modules.forEach((m) => {
					if (!m.date) {
						allHaveDates = false;
					}
				});
			}

			noDatesSet =
				!course ||
				(course && !course.modules) ||
				!course?.modules?.length > 0 ||
				!allHaveDates;

			disableForOne =
				(course.maxParticipants > 0 && course.quantity == 0) ||
				course.quantity < 0 ||
				hasExpired ||
				!course.modules?.length > 0 ||
				noDatesSet;
			disableForTwo =
				(course.maxParticipants > 0 && course.quantity < 2) ||
				hasExpired ||
				!course.modules?.length > 0 ||
				noDatesSet;
			disableForThree =
				(course.maxParticipants > 0 && course.quantity < 3) ||
				hasExpired ||
				!course.modules?.length > 0 ||
				noDatesSet;
		}
		var isMobile = false;
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}
		return (
			<section className="blog-area pt-50 ">
				{
					!course.modules?.length > 0 ||
						noDatesSet ? (
						<div className="container" style={{marginBottom: 50}}>
							<div className="section-title" style={{marginBottom: 0}}>
								<h2>CONSULTA</h2>
							</div>
							<div className="row-justify-center pt-0">
								<div className="price-contact">
									Por consultas contactanos a{" "}
									<a href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20por%20más%20cupos%20">
										<strong>cursos@gabrielvallone.com.uy</strong>{" "}
									</a>
								</div>
							</div>
						</div>
					) : (
						<div className="container">

							<div className="section-title">
								<h2>INSCRIPCIONES</h2>
							</div>

							{course && course.isFree ? (
								<div className="row row-justify-center">
									<div className="col-lg-4 col-md-6 single-course-price-container">
										<div className="single-course-price">
											<div className="course-price-img">
												<img
													src={require("../../images/business-man.svg")}
													alt="image"
												/>
											</div>

											<div className="post-content">
												<div className="row row-justify-center">
													<h5
														style={{
															marginBottom: "20px",
															padding: "0px 4px",
															fontSize: "20px",
														}}
													>
														ACTIVIDAD SIN COSTO
													</h5>
												</div>
												<div className="row row-justify-center">
													<Link
														to={"/checkout/" + course.id + "/1"}
														style={disableForOne ? { pointerEvents: "none" } : null}
													>
														<div
															class={
																disableForOne
																	? course?.notAvailable
																		? "default-course-btn-disabled-custom"
																		: "default-course-btn-disabled"
																	: "course-btn"
															}
														>
															{disableForOne
																? !hasExpired
																	? noDatesSet
																		? "PRÓXIMAMENTE"
																		: course?.notAvailable
																			? course?.notAvailable
																			: "NO HAY MÁS CUPOS"
																	: "NO DISPONIBLE"
																: "INSCRIBIRME"}
														</div>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className="row row-justify-center">
									<div className="col-lg-4 col-md-6 single-course-price-container">
										<div className="single-course-price">
											<div className="course-price-img">
												<img
													src={require("../../images/business-man.svg")}
													alt="image"
												/>
												{course.discount && course.discountReason ? (
													<div className="new-tag">{course.discountReason}</div>
												) : null}
												{course.onSale ? (
													<div className="sale-tag">Sale!</div>
												) : null}
											</div>

											<div className="post-content">
												<div className="row row-justify-center">
													{course.oldPrice ? (
														<div className="last-price">
															{course.currency ? course.currency : "USD"}{" "}
															{course.oldPrice}
														</div>
													) : null}
												</div>
												<div className="row row-justify-center">
													<h5>
														{course.currency ? course.currency + " " : "USD "}
													</h5>
													<h3 style={{ fontSize: "55px", paddingLeft: "5px" }}>
														{course.newPrice}
													</h3>
												</div>
												<div className="row row-justify-center">
													<Link
														to={"/checkout/" + course.id + "/1"}
														style={disableForOne ? { pointerEvents: "none" } : null}
													>
														<div
															class={
																disableForOne
																	? course?.notAvailable
																		? "default-course-btn-disabled-custom"
																		: "default-course-btn-disabled"
																	: "course-btn"
															}
														>
															{disableForOne
																? !hasExpired
																	? noDatesSet
																		? "PRÓXIMAMENTE"
																		: course?.notAvailable
																			? course?.notAvailable
																			: "NO HAY MÁS CUPOS"
																	: "NO DISPONIBLE"
																: "INSCRIBIRME"}
														</div>
													</Link>
												</div>
												{hasExpired && (
													<div
														style={{
															marginTop: "-10px",
															fontSize: "12px",
															display: "flex",
															justifyContent: "center",
														}}
													>
														Ya ha sido dictado.
													</div>
												)}
											</div>
										</div>
									</div>

									{course && (course.oldPriceForTwo || course.newPriceForTwo) ? (
										<div className="col-lg-4 col-md-6 single-course-price-container">
											<div className="single-course-price">
												<div className="course-price-img">
													<img
														src={require("../../images/two_business.svg")}
														alt="image"
													/>
													{course.discount && course.discountReason ? (
														<div className="new-tag">{course.discountReason}</div>
													) : null}
												</div>

												<div className="post-content">
													<div className="row row-justify-center">2 personas</div>
													<div className="row row-justify-center">
														{course.oldPriceForTwo ? (
															<div className="last-price">
																{course.currency ? course.currency : "USD"}{" "}
																{course.oldPriceForTwo}
															</div>
														) : null}
													</div>
													<div className="row row-justify-center">
														<h5>
															{course.currency ? course.currency + " " : "USD "}
														</h5>
														<h3 style={{ fontSize: "55px", paddingLeft: "5px" }}>
															{course.newPriceForTwo}
														</h3>
														<h5>c/u</h5>
													</div>
													<div className="row row-justify-center">
														<Link
															to={"/checkout/" + course.id + "/2"}
															style={
																disableForTwo ? { pointerEvents: "none" } : null
															}
														>
															<div
																class={
																	course.quantity < 2
																		? "default-course-btn-disabled"
																		: "course-btn"
																}
															>
																{disableForTwo
																	? !hasExpired
																		? noDatesSet
																			? "PRÓXIMAMENTE"
																			: course?.notAvailable
																				? course?.notAvailable
																				: "NO HAY MÁS CUPOS"
																		: "NO DISPONIBLE"
																	: "INSCRIBIRME"}
															</div>
														</Link>
													</div>
												</div>
											</div>
										</div>
									) : null}

									{course &&
										(course.oldPriceForThree || course.newPriceForThree) ? (
										<div className="col-lg-4 col-md-6 single-course-price-container">
											<div className="single-course-price">
												<div className="course-price-img">
													<img
														src={require("../../images/four-business.svg")}
														alt="image"
													/>
													{course.discount && course.discountReason ? (
														<div className="new-tag">{course.discountReason}</div>
													) : null}
												</div>

												<div className="post-content">
													<div className="row row-justify-center">3 personas</div>
													{course.oldPriceForThree ? (
														<div className="row row-justify-center">
															<div className="last-price">
																{course.currency ? course.currency : "USD"}{" "}
																{course.oldPriceForThree}
															</div>
														</div>
													) : null}
													<div className="row row-justify-center">
														<h5>
															{course.currency ? course.currency + " " : "USD "}
														</h5>
														<h3 style={{ fontSize: "55px", paddingLeft: "5px" }}>
															{course.newPriceForThree}
														</h3>
														<h5>c/u</h5>
													</div>
													<div className="row row-justify-center">
														<Link
															to={"/checkout/" + course.id + "/3"}
															style={
																disableForThree ? { pointerEvents: "none" } : null
															}
														>
															<div
																class={
																	course.quantity < 3
																		? "default-course-btn-disabled"
																		: "course-btn"
																}
															>
																{disableForThree
																	? !hasExpired
																		? noDatesSet
																			? "PRÓXIMAMENTE"
																			: course?.notAvailable
																				? course?.notAvailable
																				: "NO HAY MÁS CUPOS"
																		: "NO DISPONIBLE"
																	: "INSCRIBIRME"}
															</div>
														</Link>
													</div>
												</div>
											</div>
										</div>
									) : null}
								</div>
							)}

							<div className="row-justify-center pt-40">
								<div className="price-contact">
									Por otros grupos de más integrantes o consultas, contactanos a{" "}
									<a href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20por%20más%20cupos%20">
										<strong>cursos@gabrielvallone.com.uy</strong>{" "}
									</a>
								</div>
							</div>
						</div>
					)
				}

			</section>
		);
	}
}

export default CoursePaymentModes;
