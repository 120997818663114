import React, { Component } from "react";
import { Link } from "react-router-dom";
import noPhoto from "../../../images/products/noImage.png";

class ViewCourseModal extends Component {
	state = {
		modal: false,
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	render() {
		let { course } = this.props;
		let previewImages = [];
		if(course){
			if (course.imageUrlSecond) {
				previewImages.push(course.imageUrlSecond);
			}
			if (course.imageUrlThird) {
				previewImages.push(course.imageUrlThird);
			}
			if (course.imageUrlFourth) {
				previewImages.push(course.imageUrlFourth);
			}
		}
		return (
			<React.Fragment>
				{course && (
					<div
						className={`admin-product-modal view-product ${this.props.active}`}
					>
						<div className="modal-innter-content">
							<button type="button" className="close" onClick={this.closeModal}>
								<span aria-hidden="true">
									<i className="bx bx-x"></i>
								</span>
							</button>

							<div className="modal-body">
								<h3>Ver Curso</h3>
								<p>*La información está resumida</p>

								<div className="view-product-content">
									<div className="row align-items-center">
										<div className="col-lg-12">
											<div className="products-details-desc">
												<h3>{course.title}</h3>
												<p>{course.description}</p>

												<div className="price">
													<p>Precio para 1:</p>
													{course.offer ? (
														<React.Fragment>
															<span className="old-price">
																${course.oldPrice}
															</span>
															<span className="new-price">
																${course.newPrice}
															</span>
														</React.Fragment>
													) : (
														<span className="new-price">
															${course.newPrice}
														</span>
													)}
												</div>

												<div className="price">
													<p>Precio para 2:</p>
													{course.offer && course.newPriceForTwo ? (
														<React.Fragment>
															<span className="old-price">
																${course.oldPriceForTwo}
															</span>
															<span className="new-price">
																${course.newPriceForTwo}
															</span>
														</React.Fragment>
													) : (
														<span className="new-price">
															{course.newPriceForTwo
																? `${course.newPriceForTwo}`
																: "-"}
														</span>
													)}
												</div>

												<div className="price">
													<p>Precio para 3:</p>
													{course.offer && course.newPriceForThree ? (
														<React.Fragment>
															<span className="old-price">
																${course.oldPriceForThree}
															</span>
															<span className="new-price">
																${course.newPriceForThree}
															</span>
														</React.Fragment>
													) : (
														<span className="new-price">
															{course.newPriceForThree
																? `${course.newPriceForThree}`
																: "-"}
														</span>
													)}
												</div>

												<ul className="products-info">
													<li>
														<span>Disponibilidad:</span>
														<Link to="#">
															<a onClick={(e) => e.preventDefault()}>
																{
																	course.hasMaxParticipants ? course.quantity : 'Sin límite'
																}
															</a>
														</Link>
													</li>
													<li>
														<span>Categoría:</span>
														<Link to="#">
															<a onClick={(e) => e.preventDefault()}>
																{course.category
																	? course.category.title
																	: "Sin categoría"}
															</a>
														</Link>
													</li>
												</ul>

												<ul className="products-info">
													<li>
														<span>Módulos:</span>
														<ul>
															{course.modules && course.modules.length > 0
																? course.modules.map((i) => (
																		<li>
																			<strong>{`${i.title} - ${i.date} (${i.startTime} - ${i.endTime})`}</strong>
																			<ul>
																				{i.content &&
																					i.content.length > 0 &&
																					i.content.map((j) => (
																						<li>{j.title}</li>
																					))}
																			</ul>
																		</li>
																  ))
																: "No has ingresado módulos."}
														</ul>
													</li>
												</ul>
											</div>

											<div className="product-img">
												<img
													src={course.imageUrl ? course.imageUrl : noPhoto}
													className="main-image"
													alt="image"
												/>
												{course.onSale ? (
													<div className="sale-tag">Sale!</div>
												) : null}

												{course.new ? (
													<div className="new-tag">Nuevo!</div>
												) : null}

												{course.discount ? (
													<div className="discount">
														{course.discountOff}% OFF
													</div>
												) : null}
											</div>
											<div>
												<div className="special-msg">Más fotos:</div>
												{previewImages && previewImages.length > 0 ? (
													<div className="special-uploaded-img">
														{previewImages.map((e) => (
															<img
																src={e}
																alt="Image"
																className="img-thumbnail"
															/>
														))}
													</div>
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default ViewCourseModal;
