import { firebase } from '../../firebase';

const db = firebase.firestore();
const dbOrderRef = db.collection('courses');
const coursesArray = [];
dbOrderRef.get()
.then(res => {
    res.forEach(doc => {
        let coursesObj = doc.data();
        coursesObj.id = doc.id;
        coursesArray.push(coursesObj)
    });
});

const data = []

export {data, coursesArray}