import React, { Component } from 'react';
//import TopHeader from '../components/Layouts/TopHeader';
import ColoredNavbar from '../components/Layouts/ColoredNavbar';
import Footer from '../components/Layouts/Footer';
import PageBanner from '../components/Common/PageBanner';
import BlogDetailsOne from '../components/BlogDetails/BlogDetailsOne';

class Blog1 extends Component {
    render() {
        return (
            <React.Fragment>
                <ColoredNavbar />
                <PageBanner 
                    pageTitle="BLOG POST" 
                    homePageUrl="/" 
                    homePageText="Home" 
                    activePageText="Blogs" 
                /> 
                <BlogDetailsOne blogId={this.props.match.params.slug}/>
                <Footer />
            </React.Fragment>
        );
    }
}

// <TopHeader />

export { Blog1 };


//