import React, { useState, useEffect } from 'react';
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import { Link, useParams, withRouter, Redirect } from "react-router-dom";
import { css } from "@emotion/core";
import Footer from '../components/Layouts/Footer';
import { extractPaymentIdFromUrl } from '../constants/payment_types'
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { firebase } from "../firebase";
import { encodeUser } from "../methods/api-calls";
import axios from "axios";
import backendUrl from "../constants/urls";
import { connect } from "react-redux";
import {
    addSingleOrder,
    editCourse,
    removeCoupon,
    addMercadoPagoItems,
    removeMercadoPagoItems,
} from "../store/actions/cartActions";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

const PaymentSuccess = (props) => {
    const params = useParams()
    const [loading, setLoading] = useState(true);
    const mercadoNumber = params?.id;


    var isMobile = false;
    // device detection
    if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4)
        )
    ) {
        isMobile = true;
    }

    useEffect(async () => {

        const fetchData = async (mercadoNumber) => {
            const db = firebase.firestore();
            let dbOrderRef = db.collection("orders");
            let ordersArray = [];
            try {
                const res = await dbOrderRef.where('mercadoNumber', '==', mercadoNumber).get();
                res.forEach((doc) => {
                    let bannersObj = doc.data();
                    bannersObj.id = doc.id;
                    if (bannersObj.status == "mercado") {
                        ordersArray.push(bannersObj);
                    }
                });
                const finalArray = ordersArray.filter((v,i,a)=>a.findIndex(v2=>(v2.customerDetails.email===v.customerDetails.email))===i)
                return finalArray;
            } catch (err) {
                console.log("error", err);
            }
            setLoading(false);
        };

        if (mercadoNumber) {
            const paymentData = props.location.search;
            const { paymentId, paymentType } = extractPaymentIdFromUrl(paymentData);
            const allOrders = await fetchData(mercadoNumber);
            const order = allOrders.length ? allOrders[0] : null;
            const order2 = allOrders.length > 1 ? allOrders[1] : null;
            const order3 = allOrders.length > 2 ? allOrders[2] : null;

            if (allOrders.length) {
                let db = firebase.firestore();
                let dbOrderRef = db.collection("orders");

                for (var i = 0; i < allOrders.length; i++) {
                    let o = allOrders[i];
                    if (o.id) {
                        await dbOrderRef.doc(o.id).update({
                            ...o, status: 'pending',
                            paymentType: paymentType, paymentId: paymentId
                        });
                    }
                    props.addSingleOrder({ ...o, status: 'pending', paymentType: paymentType, paymentId: paymentId });
                }

                let coupon = null
                let course = null;
                let amountOfPeople = null;
                if (order && order.extraInfo) {
                    coupon = order.extraInfo.coupon;
                    course = order.extraInfo.course;
                    amountOfPeople = order.extraInfo.amountOfPeople;
                }

                if (coupon && coupon.id) {
                    try {
                        const res = await axios.post(
                            `${backendUrl}api/coupons/use-coupon`,
                            {
                                coupon: coupon,
                            },
                            {
                                headers: encodeUser(),
                            }
                        );
                    } catch (e) {
                        alert(e);
                        setLoading(false)
                    }
                }


                const courseId = course.id;
                if (course.hasMaxParticipants) {
                    course.quantity -= amountOfPeople;
                }
                db = firebase.firestore();
                dbOrderRef = db.collection("courses");
                dbOrderRef
                    .doc(courseId)
                    .update(course)
                    .then(() => {
                        props.editCourse(course);
                        const data = {
                            allOrders: [...allOrders.map(o => ({ ...o, status: 'pending' }))],
                        };

                        const authHeader = encodeUser();

                        axios
                            .post(`${backendUrl}api/mailer/notify`, data, {
                                headers: {
                                    accept: "application/json",
                                    "Accept-Language": "en-US,en;q=0.8",
                                    "Content-Type": `application/json`,
                                    ...authHeader,
                                },
                            })
                            .then((response) => {

                                props.removeMercadoPagoItems();
                                props.removeCoupon();

                                if (200 === response.status) {
                                    if (response.data.error) {
                                        alert(response.data.error);
                                        toast.error("Ha ocurrido un problema.", {
                                            position: "bottom-left",
                                            autoClose: 3000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                        });
                                    } else {
                                        // Success
                                        console.log("email sent");
                                        var users = [];
                                        users.push(order?.customerDetails);
                                        if (amountOfPeople > 1) users.push(order2?.customerDetails);
                                        if (amountOfPeople > 2) users.push(order3?.customerDetails);
                                        const body = {
                                            users: users,
                                        };
                                        const authHeaderTwo = encodeUser();
                                        axios
                                            .post(`${backendUrl}api/mailer/createUsers`, body, {
                                                headers: {
                                                    accept: "application/json",
                                                    "Accept-Language": "en-US,en;q=0.8",
                                                    "Content-Type": `application/json`,
                                                    ...authHeaderTwo,
                                                },
                                            })
                                            .then((response) => { })
                                            .catch((error) => {
                                                toast.warn(
                                                    "Ha ocurrido un problema creando tu usuario.",
                                                    {
                                                        position: "bottom-left",
                                                        autoClose: 3000,
                                                        hideProgressBar: true,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                    }
                                                );
                                                alert(error, "red");
                                            });
                                    }
                                }
                            })
                            .catch((error) => {
                                toast.error("Ha ocurrido un problema con la confirmación.", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                alert(error, "red");
                            });
                        setLoading(false)
                    })
                    .catch((error) => {
                        toast.error("Ha ocurrido un problema.", {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        alert(error, "red");
                        setLoading(false)

                    });
            }
        }
        setLoading(false)
    }, []);



    useEffect(() => {
        if (!loading) {
            setTimeout(function () {
                toast.warning(
                    "Cuando recibamos tu pago aprobaremos tu compra.",
                    {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }, 1500);
        }
    }, [loading]);


    return (
        <React.Fragment>
            <ColoredNavbar />
            <ToastContainer />

            <section className="error-area ptb-100">
                <div className="container">
                    <div className="error-content" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>

                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <ClipLoader
                                    css={override}
                                    size={isMobile ? 20 : 50}
                                    color={"#4d7c8a"}
                                    loading={true}
                                />
                            </div>

                        ) : (
                            <div>
                                <h3>Compra pendiente</h3>
                                <p>Comunicate con nosotros para finalizar correctamente la suscripción. Te llegará un correo cuando aprobemos tu inscripción.</p>
                                <Link to="/">
                                    <a className="default-btn text-white">Ir al inicio</a>
                                </Link>
                            </div>
                        )}

                    </div>
                </div>
            </section>

            <Footer />
        </React.Fragment>
    );

}

const mapStateToProps = (state) => {
    return {
        coupon: state.coupon,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSingleOrder: (order) => {
            dispatch(addSingleOrder(order));
        },
        editCourse: (course) => {
            dispatch(editCourse(course));
        },
        removeCoupon: () => {
            dispatch(removeCoupon());
        },
        addMercadoPagoItems: (items) => {
            dispatch(addMercadoPagoItems(items));
        },
        removeMercadoPagoItems: () => {
            dispatch(removeMercadoPagoItems());
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
);