import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { data, coursesArray } from "./products";
import { persistStore } from "redux-persist";
import promise from "redux-promise-middleware";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import {
	ADD_TO_CART,
	REMOVE_ITEM,
	SUB_QUANTITY,
	ADD_QUANTITY,
	ADD_QUANTITY_WITH_NUMBER,
	RESET_CART,
	ADD_COURSES,
	ADD_CATEGORY,
	ADD_SINGLE_ORDER,
	ADD_MODULE,
	ADD_SINGLE_COURSE,
	EDIT_COURSE,
	UPDATE_USER,
	REMOVE_COURSE,
	ADD_FEED,
	ADD_SINGLE_PROFESSOR,
	ADD_COURSE_WITH_NUMBER_OF_PEOPLE,
	EDIT_CATEGORY,
	REMOVE_CATEGORY,
	REMOVE_PROFESSOR,
	EDIT_PROFESSOR,
	LOGIN_USER,
	LOGOUT_USER,
	ADD_COUPON,
	REMOVE_COUPON,
	ADD_MERCADOPAGO_ITEMS,
	REMOVE_MERCADOPAGO_ITEMS,
} from "../actions/action-types/cart-actions";

const dataArr = coursesArray.length ? coursesArray : data;

const initState = {
	courses: dataArr, //before it was products
	categories: [],
	addedItems: [],
	professors: [],
	feed: [],
	user: null,
	total: 0,
	coupon: null,
	mercadoPagoItems: [],
};

const cartReducer = (state = initState, action) => {
	if (action.type === ADD_FEED) {
		let existed_item = state.feed.find(
			(item) => item.link === action.feed.link
		);
		if (!existed_item) {
			return {
				...state,
				feed: [...state.feed, action.feed],
			};
		}
	}

	if (action.type === ADD_COURSES) {
		return {
			...state,
			courses: action.courses,
		};
	}

	if (action.type === ADD_MERCADOPAGO_ITEMS) {
		return {
			...state,
			mercadoPagoItems: action.items,
		};
	}

	if (action.type === REMOVE_MERCADOPAGO_ITEMS) {
		return {
			...state,
			mercadoPagoItems: [],
		};
	}

	if (action.type === ADD_MODULE) {
		const module = action.module;
		const newState = {
			...state,
			courses: [],
		};
		if (state.courses) {
			newState.courses = state.courses.map((c) => {
				if (c.id === module.courseId) {
					c.modules.push(module);
				}
				return c;
			});
		}
		return newState;
	}

	if (action.type === ADD_SINGLE_COURSE) {
		let toAdd = action.course;
		let existed_item = state.courses.find((item) => toAdd.id === item.id);
		if (!existed_item) {
			return {
				...state,
				courses: [...state.courses, toAdd],
			};
		}
	}

	if (action.type === ADD_SINGLE_ORDER) {
		let toAdd = { ...action.order };
		return {
			...state,
			addedItems: [...state.addedItems, toAdd],
			total: state.total + toAdd.amount,
		};
	}

	if (action.type === ADD_COUPON) {
		let toAdd = { ...action.coupon };
		return {
			...state,
			coupon: toAdd,
		};
	}

	if (action.type === REMOVE_COUPON) {
		return {
			...state,
			coupon: null,
		};
	}

	if (action.type === ADD_SINGLE_PROFESSOR) {
		let toAdd = action.professor;
		let existed_item = state.professors.find((item) => toAdd.id === item.id);
		if (!existed_item) {
			return {
				...state,
				professors: [...state.professors, toAdd],
			};
		}
	}

	if (action.type === ADD_CATEGORY) {
		let toAdd = action.category;
		let existed_item = state.categories.find((item) => toAdd.id === item.id);
		if (!existed_item) {
			return {
				...state,
				categories: [...state.categories, toAdd],
			};
		}
	}

	if (action.type === EDIT_COURSE) {
		let course = action.course;
		const newState = {
			...state,
			courses: [],
		};
		newState.courses = state.courses
			? state.courses.map((c) => {
					if (c.id === course.id) {
						return { ...course };
					}
					return c;
			  })
			: [course];

		return newState;
	}

	if (action.type === EDIT_PROFESSOR) {
		let professor = action.professor;
		const newState = {
			...state,
			professors: [],
		};
		newState.professors = state.professors
			? state.professors.map((c) => {
					if (c.id === professor.id) {
						return { ...professor };
					}
					return c;
			  })
			: [professor];

		return newState;
	}

	if (action.type === EDIT_CATEGORY) {
		let category = action.category;
		const newState = {
			...state,
			categories: [],
		};
		newState.categories = state.categories
			? state.categories.map((c) => {
					if (c.id === category.id) {
						return { ...category };
					}
					return c;
			  })
			: [category];

		return newState;
	}

	if (action.type === REMOVE_COURSE) {
		let courseToRemove = state.courses.find((item) => action.id === item.id);
		if (courseToRemove) {
			let new_courses = state.courses.filter((item) => action.id !== item.id);

			return {
				...state,
				courses: new_courses,
			};
		}
	}

	if (action.type === REMOVE_CATEGORY) {
		let categoryToRemove = state.categories.find(
			(item) => action.id === item.id
		);
		if (categoryToRemove) {
			let new_categories = state.categories.filter(
				(item) => action.id !== item.id
			);

			return {
				...state,
				categories: new_categories,
			};
		}
	}

	if (action.type === REMOVE_PROFESSOR) {
		let professorToRemove = state.professors.find(
			(item) => action.id === item.id
		);
		if (professorToRemove) {
			let new_professors = state.professors.filter(
				(item) => action.id !== item.id
			);

			return {
				...state,
				professors: new_professors,
			};
		}
	}

	if (action.type === ADD_TO_CART) {
		let addedItem = state.courses.find((item) => item.id === action.id);
		//check if the action id exists in the addedItems
		let existed_item = state.addedItems.find((item) => action.id === item.id);
		if (existed_item) {
			if (!addedItem.quantity) {
				addedItem.quantity = 1;
			} else {
				addedItem.quantity += 1;
			}
			return {
				...state,
				total: state.total + addedItem.newPrice,
			};
		} else {
			addedItem.quantity = 1;
			//calculating the total
			let newTotal = state.total + addedItem.newPrice;

			return {
				...state,
				addedItems: [...state.addedItems, addedItem],
				total: newTotal,
			};
		}
	}

	if (action.type === ADD_COURSE_WITH_NUMBER_OF_PEOPLE) {
		const { id } = action;
		const { comboQuantity } = action;

		let existed_item = state.addedItems.find(
			(c) => c.id === id && c.comboQuantity && c.comboQuantity === comboQuantity
		);
		let addedItem = state.courses.find((c) => c.id === id);

		if (existed_item) {
			if (addedItem && addedItem.quantity > 0) {
				//Hay stock
				if (!existed_item.totalQty) {
					existed_item.totalQty = 1;
				} else {
					existed_item.totalQty += 1;
				}

				let newTotal = state.total;

				if (comboQuantity) {
					if (comboQuantity == 1) {
						newTotal += addedItem.newPrice;
					} else if (comboQuantity == 2) {
						newTotal += addedItem.newPriceForTwo;
					} else if (comboQuantity == 3) {
						newTotal += addedItem.newPriceForThree;
					}
				}

				return {
					...state,
					addedItems: state.addedItems.map((item) =>
						item.id === addedItem.id &&
						item.comboQuantity === addedItem.comboQuantity
							? existed_item
							: item
					),
					total: newTotal,
				};
			}
		} else {
			if (addedItem) {
				if (addedItem.quantity > 0) {
					addedItem.totalQty = 1;
					addedItem.comboQuantity = comboQuantity;

					//calculating the total

					let newTotal = state.total;

					if (comboQuantity) {
						if (comboQuantity == 1) {
							newTotal += addedItem.newPrice;
						} else if (comboQuantity == 2) {
							newTotal += addedItem.newPriceForTwo;
						} else if (comboQuantity == 3) {
							newTotal += addedItem.newPriceForThree;
						}
					}
					return {
						...state,
						addedItems: [...state.addedItems, addedItem],
						total: newTotal,
					};
				}
			}
		}
	}

	if (action.type === REMOVE_ITEM) {
		//REMOVES ALL ITEMS WITH THIS ID, NOT JUST 1 FROM TOTAL QTY.
		const { course } = action;

		let itemToRemove = null;

		if (course) {
			//If product has taste and chocolate flavour as extra vale
			if (course.comboQuantity) {
				itemToRemove = state.addedItems.find(
					(item) =>
						course.id === item.id &&
						item.comboQuantity &&
						item.comboQuantity == course.comboQuantity
				);
			}

			let new_items = state.addedItems.filter(
				(item) =>
					course.id !== item.id ||
					(course.id === item.id &&
						item.comboQuantity !== itemToRemove.comboQuantity)
			);

			//calculating the total
			let newTotal = state.total;

			if (itemToRemove.comboQuantity == 1) {
				newTotal -= itemToRemove.newPrice;
			} else if (itemToRemove.comboQuantity == 2) {
				newTotal -= itemToRemove.newPriceForTwo;
			} else if (itemToRemove.comboQuantity == 3) {
				newTotal -= itemToRemove.newPriceForThree;
			}

			return {
				...state,
				addedItems: new_items,
				total: newTotal,
			};
		}
	}

	if (action.type === ADD_QUANTITY) {
		//ADDS +1 to TOTALQTY WITH SAME IF AND SAME COMBOQTY
		const { course } = action;
		let addedItem = state.addedItems.find(
			(item) =>
				item.id === course.id && item.comboQuantity == course.comboQuantity
		);
		if (!addedItem.totalQty) {
			addedItem.totalQty = 1;
		} else {
			addedItem.totalQty += 1;
		}
		let newTotal = state.total;

		if (addedItem.comboQuantity == 1) {
			newTotal += addedItem.newPrice;
		} else if (addedItem.comboQuantity == 2) {
			newTotal += addedItem.newPriceForTwo;
		} else if (addedItem.comboQuantity == 3) {
			newTotal += addedItem.newPriceForThree;
		}
		return {
			...state,
			total: newTotal,
		};
	}

	if (action.type === SUB_QUANTITY) {
		//REMOVES JUST 1 FROM TOTAL QTY FOR THIS ID.
		const { course } = action;
		let addedItem = state.addedItems.find(
			(item) =>
				item.id === course.id && item.comboQuantity == course.comboQuantity
		);
		//if the qt == 0 then it should be removed
		if (addedItem.totalQty === 1) {
			let new_items = state.addedItems.filter((item) => item.id !== course.id);

			let newTotal = state.total;

			if (addedItem.comboQuantity == 1) {
				newTotal -= addedItem.newPrice;
			} else if (addedItem.comboQuantity == 2) {
				newTotal -= addedItem.newPriceForTwo;
			} else if (addedItem.comboQuantity == 3) {
				newTotal -= addedItem.newPriceForThree;
			}
			return {
				...state,
				addedItems: new_items,
				total: newTotal,
			};
		} else {
			if (!addedItem.totalQty) {
				addedItem.totalQty = 0;
			} else {
				addedItem.totalQty -= 1;
			}
			let newTotal = state.total;

			if (addedItem.comboQuantity == 1) {
				newTotal -= addedItem.newPrice;
			} else if (addedItem.comboQuantity == 2) {
				newTotal -= addedItem.newPriceForTwo;
			} else if (addedItem.comboQuantity == 3) {
				newTotal -= addedItem.newPriceForThree;
			}
			return {
				...state,
				total: newTotal,
			};
		}
	}

	if (action.type === UPDATE_USER) {
		let user = action.user;
		return {
			...state,
			user: user,
		};
	}

	if (action.type === LOGIN_USER) {
		let user = action.user;
		return {
			...state,
			addedItems: [],
			user: user,
		};
	}

	if (action.type === LOGOUT_USER) {
		return {
			...state,
			user: null,
			addedItems: [],
			total: 0,
		};
	}

	if (action.type === RESET_CART) {
		return {
			...state,
			addedItems: [],
			total: 0,
		};
	} else {
		return state;
	}
};

const cartPersistConfig = {
	storage,
	key: "gabrielvallone",
	blacklist: ["cart"],
};

const defaultReducer = persistReducer(cartPersistConfig, cartReducer);

const middleware = [thunk, promise];
const enhancers = composeWithDevTools(applyMiddleware(...middleware));

export const store = createStore(defaultReducer, initState, enhancers);
export const persistor = persistStore(store);

// export const store =
// 	 createStore(
// 			cartReducer,
// 			initState,
// 			composeWithDevTools(applyMiddleware(thunk))
// 	)
