import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { connect } from "react-redux";

import { editCourse } from "../../../store/actions/cartActions";

import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";

const containerStyle = css({
	position: "relative",
});

class EditModuleModal extends Component {
	state = {
		modal: false,
		progress: 0,
		title: "",
		mainDescription: "",
		startTime: "",
		endTime: "",
		isComingSoon: false,
		date: "",
		content: [],
		currentTitle: "",
		allCourses: [],
		moduleId: "",
		createdAt: "",
		currentDescription: "",
		course: null,
		courseId: "",
	};

	baseState = this.state;

	componentDidMount() {
		const { module } = this.props;
		if (module) {
			this.setState({
				isComingSoon: !module.date,
				courseId: module.courseId,
				title: module.title,
				mainDescription: module.mainDescription,
				startTime: module.startTime,
				endTime: module.endTime,
				date: module.date,
				content: module.content,
				createdAt: module.createdAt,
				moduleId: module.moduleId,
				allCourses: this.props.courses,
				course: this.props.courses && this.props.courses.length > 0 ? (this.props.courses.find(c => c.id == module.courseId) ? this.props.courses.find(c => c.id == module.courseId) : null) : null,
			});
		} else {
			this.closeModal();
		}

	}

	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	addSection = (e) => {
		const { currentDescription, currentTitle } = this.state;
		const newSection = {
			title: currentTitle,
			text: currentDescription,
		};
		this.setState((prevState) => ({
			content: [...prevState.content, newSection],
			currentTitle: "",
			currentDescription: "",
		}));
		e.preventDefault();
	};

	removeSections = (e) => {
		e.preventDefault();
		this.setState({
			content: [],
		});
	};

	changeText = (e) => {
		this.setState((prevState) => (e) => ({
			objeto: {
				...prevState.objeto,
				parrafo: e.target.value,
			},
		}));
	};

	changeParragraph = (e) => {
		this.setState((prevState) => ({
			objeto: {
				...prevState.objeto,
				parrafo: e.target.value,
			},
		}));
	};

	parseToBoolean = (val) => {
		if (typeof val === "boolean") {
			return Boolean(val);
		} else if (val == "true") {
			return Boolean(true);
		} else {
			return Boolean(false);
		}
	};

	editModule = (e) => {
		e.preventDefault();
		let {
			courseId,
			course,
			date,
			startTime,
			endTime,
			title,
			allCourses,
			isComingSoon,
			moduleId,
			mainDescription,
			content,
			createdAt,
		} = this.state;
		const comingSoon = this.parseToBoolean(isComingSoon)

		const module = {
			title: title,
			courseId: course ? course.id : null,
			date: !comingSoon ? date : '',
			startTime: !comingSoon ? startTime : '',
			moduleId: moduleId,
			endTime: !comingSoon ? endTime : '',
			mainDescription: mainDescription,
			content: content,
			createdAt: createdAt,
		};
		if (allCourses && allCourses.length > 0) {
			if (
				moduleId != "" &&
				title != "" &&
				course &&
				((!comingSoon && endTime != "" && date != "" && startTime != "") ||
					comingSoon)
			) {
				const splittedStart = startTime.split(":");
				const splittedEnd = endTime.split(":");
				const start = new Date(date).setHours(
					splittedStart[0],
					splittedStart[1]
				);
				const end = new Date(date).setHours(splittedEnd[0], splittedEnd[1]);

				if (
					comingSoon ||
					(!comingSoon &&
						(end > start ||
							(splittedEnd &&
								splittedEnd.length &&
								splittedEnd[0] === "00" &&
								splittedStart &&
								splittedStart.length &&
								splittedStart[0] != "00")))
				) {
					const newModules = course.modules ? course.modules.map(m => m.moduleId == moduleId ? module : m) : [];

					const toSort = newModules;

					const sorted = toSort.sort((a, b) => {
						const sub = new Date(a.date) - new Date(b.date);
						if (sub < 0) {
							return -1;
						} else if (sub > 0) {
							return 1;
						} else if (sub === 0) {
							//Check for times;
							if (a.startTime < b.startTime) {
								return -1;
							} else {
								return 1;
							}
						}
					});

					const courseToUpdate = {
						...course,
						modules: sorted,
					};
					const courseId = course.id;
					const db = firebase.firestore();
					const dbOrderRef = db.collection("courses");
					dbOrderRef
						.doc(courseId)
						.update(courseToUpdate)
						.then(() => {
							toast.success("Modulo fue editado exitosamente.", {
								position: "bottom-left",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
							});
							this.props.editCourse(courseToUpdate)

							setTimeout(() => {
								window.location.reload();
								this.closeModal();
							}, 2000);
							this.resetForm();
							// this.props.resetCart();
						});
				} else {
					toast.error("La hora de fin debe ser mayor a la hora de inicio", {
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
				}
			} else {
				toast.error("Complete el formulario", {
					zIndex: 19999,
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		} else {
			toast.error("Deben haber cursos ingresados primero", {
				zIndex: 19999,
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer className={containerStyle} />

					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Editar módulo de un curso</h3>

							<form onSubmit={this.editModule}>
								<h4 className="title">Información general</h4>

								<div className="form-group">
									<label>Curso al que pertenece:</label>
									<p>
										{this.state.course ? this.state.course.title : 'No hay información.'}
									</p>
								</div>

								<div className="form-group">
									<label>Titulo principal (Ej. "Los fundamentos")</label>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>

								<div className="form-group">
									<label>Descripción general (opcional)</label>
									<textarea
										className="form-control"
										value={this.state.mainDescription}
										onChange={(e) =>
											this.setState({ mainDescription: e.target.value })
										}
										rows="5"
									/>
								</div>

								<div className="form-group">
									<label>¿Es PRÓXIMAMENTE? </label>
									<p style={{ fontSize: "12px" }}>
										*En caso de que sí, se permite no incluir una fecha y hora.
									</p>
									<select
										className="form-control"
										value={this.state.isComingSoon}
										onChange={(e) =>
											this.setState({ isComingSoon: e.target.value })
										}
									>
										<option value="false">No</option>
										<option value="true">Si</option>
									</select>
								</div>

								<div className="form-group">
									<label>Fecha en que se dictará el módulo</label>
									<input
										type="date"
										className="form-control"
										value={this.state.date}
										onChange={(e) => this.setState({ date: e.target.value })}
									/>
								</div>

								<div className="row row-justify-space-evenly">
									<div className="form-group">
										<label>Horario de inicio</label>
										<input
											type="time"
											className="form-control"
											value={this.state.startTime}
											onChange={(e) =>
												this.setState({ startTime: e.target.value })
											}
										/>
									</div>

									<div className="form-group">
										<label>Horario de fin</label>
										<input
											type="time"
											className="form-control"
											value={this.state.endTime}
											onChange={(e) =>
												this.setState({ endTime: e.target.value })
											}
										/>
									</div>
								</div>

								<h4 className="title">Secciones del módulo</h4>

								<div className="form-group">
									<label>Titulo subsección</label>
									<input
										type="text"
										className="form-control"
										value={this.state.currentTitle}
										onChange={(e) =>
											this.setState({ currentTitle: e.target.value })
										}
									/>

									<label>Descripción (opcional)</label>
									<textarea
										className="form-control"
										value={this.state.currentDescription}
										onChange={(e) =>
											this.setState({ currentDescription: e.target.value })
										}
										rows="5"
									/>

									<button onClick={this.addSection}>+</button>
									<button onClick={this.removeSections}>Borrar todo</button>
									<p>*Para editar una sección existente, deberá borrar todo e ingresarlo nuevamente, o simplemente agregar una nueva sección. El contenido se puede visualizar debajo:</p>
									<ul>
										{this.state.content && this.state.content.length > 0
											? this.state.content.map((i) => (
												<li>
													<strong>{i.title}</strong>
													<div className="content-paragraph">{i.text}</div>
												</li>
											))
											: "No has agregado secciones."}
									</ul>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Editar Módulo
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		courses: state.courses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editCourse: (module) => {
			dispatch(editCourse(module));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EditModuleModal));
