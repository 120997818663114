import React, { Component } from "react";
import Navbar from "../components/Layouts/Navbar";
import MainBanner from "../components/HomeOne/MainBanner";
import OfferStyleOne from "../components/Common/OfferStyleOne";
import CategoriesBanner from '../components/HomeTwo/CategoriesBanner';
import Categories from '../components/HomeFour/Categories';
import RecentBlogPost from "../components/Common/RecentBlogPost";
import Footer from "../components/Layouts/Footer";
import ContactForm from "../components/Contact/ContactForm";
import Testimonials from '../components/Common/Testimonials';
import BannerMessage from '../components/Common/BannerMessage';
import InstitutionalVideo from '../components/Common/InstitutionalVideo';

import {
	addCourses,
} from "../store/actions/cartActions";
import {
	fetchCourses,
} from "../methods/api-calls";
import { connect } from "react-redux";
import Subscribe from "../components/Subscribe/subscribe";


class Home extends Component {
	async componentDidMount() {
		const newCourses = await fetchCourses();
		this.props.addCourses(newCourses);
	}

	render() {
		return (
			<React.Fragment>
				<Navbar currentRoute={this.props.location.pathname} />
				<MainBanner />
				<Categories /> {/* Cursos */}
				<Testimonials/>
				<InstitutionalVideo />
				<BannerMessage />
				<OfferStyleOne /> 	{/* Propuesta de valor */}
				<CategoriesBanner />  {/* Palabras de valor */}
				<RecentBlogPost />
				<ContactForm/>
				<Subscribe/>
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		courses: state.courses,
		user: state.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addCourses: (courses) => {
			dispatch(addCourses(courses));
		},
	};
};
const WrappedHome = connect(mapStateToProps, mapDispatchToProps)(Home);
export { WrappedHome as Home };
