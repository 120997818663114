import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import CommentsList from "./CommentsList";
import BlogSidebar from "../Blog/BlogSidebar";
import { fetchBlogPostById } from "../../methods/api-calls";
import noPhoto from "../../images/products/noImage.png";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

class BlogDetailsOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blogPost: null,
		};
	}
	async componentDidMount() {
		const slug = this.props.match.params.slug;
		if (slug) {
			const blogPost = await fetchBlogPostById(slug);
			this.setState({
				blogPost: blogPost,
			});
		}
	}

	async componentDidUpdate() {
		const slug = this.props.match.params.slug;
		if (slug !== this.state?.blogPost?.slug) {
			const blogPost = await fetchBlogPostById(slug);
			this.setState({
				blogPost: blogPost,
			});
		}
	}

	convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		if (t && d) {
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		}
	};

	render() {
		var isMobile = false;
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}
		const { blogPost } = this.state;
		let imageUrls = [];
		let youtubeLink = "";
		if (!blogPost) {
			// return <Redirect to='/blog' />;
		} else {
			if (blogPost.imageUrlSecond) {
				imageUrls.push(blogPost.imageUrlSecond);
			}
			if (blogPost.imageUrlThird) {
				imageUrls.push(blogPost.imageUrlThird);
			}
			if (blogPost.imageUrlFourth) {
				imageUrls.push(blogPost.imageUrlFourth);
			}
			if (blogPost.videoLink) {
				const aux = blogPost.videoLink.split("?v=");
				if (aux && aux.length > 1) {
					youtubeLink = aux[1];
				}
			}
		}
		return (
			<section className="blog-details-area pt-10 pb-100">
				<div className="container">
					{blogPost ? (
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<div className="blog-details-desc">
									<div className="row">
										<div className="article-image col-lg-8 col-md-12">
											<img
												src={blogPost.coverImageUrl || noPhoto}
												alt="image"
											/>
										</div>

										<div className="col-lg-4 col-md-12">
											{/* Blog Sidebar */}
											<BlogSidebar />
										</div>
									</div>

									<div className="widget-blog-title"></div>

									<div className="article-content">
										<div className="entry-meta">
											<ul>
												<li>
													<i className="bx bx-calendar"></i>
													<Link to="#">
														<a>{this.convertDateTime(blogPost.createdAt)}</a>
													</Link>
												</li>
											</ul>
										</div>

										<h1>{blogPost.mainTitle}</h1>

										{imageUrls && imageUrls.length > 0 && (
											<ul className="wp-block-gallery columns-3">
												{imageUrls.map((i) => (
													<li className="blocks-gallery-item">
														<figure>
															<img src={i} alt="image" />
														</figure>
													</li>
												))}
											</ul>
										)}

										{blogPost &&
											blogPost.content &&
											blogPost.content.length > 0 &&
											blogPost.content.map((c) => (
												<div>
													<h3>{c.title}</h3>
													<p className="content-paragraph">{c.text}</p>
												</div>
											))}

										{blogPost.videoLink && (
											<div className="article-video">
												<iframe
													src={`https://www.youtube.com/embed/${youtubeLink}`}
												></iframe>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="loader-background">
							<BeatLoader
								css={override}
								size={isMobile ? 50 : 100}
								color={"#4d7c8a"}
								loading={true}
							/>
						</div>
					)}
				</div>
			</section>
		);
	}
}

const WrappedBlogDetailsOne = withRouter(BlogDetailsOne);

export default WrappedBlogDetailsOne;
