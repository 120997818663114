import React, { Component } from 'react';
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/Layouts/Footer';
import { Link } from 'react-router-dom';

class Error404 extends Component {
    render() {
        return (
            <React.Fragment>
                <ColoredNavbar />

                <section className="error-area ptb-100">
                    <div className="container">
                        <div className="error-content">
                            <img src={require("../images/not-found/404.png")} alt="error" />
                            <h3>Oops...</h3>
                            <p>La página que estas buscando pudo haberse modificado o está momentáneamente fuera de servicio.</p>
                            <Link to="/">
                                <a className="default-btn text-white">Ir al inicio</a>
                            </Link>
                        </div>
                    </div>
                </section>

                <Footer />
            </React.Fragment>
        );
    }
}

export { Error404 };