import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { fetchAllBlogPosts } from "../../methods/api-calls";
import noPhoto from "../../images/products/noImage.png";

class BlogSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blogs: null,
		};
	}
	async componentDidMount() {
		this.setState({
			loading: true,
		});
		const blogs = await fetchAllBlogPosts();
		this.setState({
			blogs: blogs,
			loading: false,
		});
	}

	convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		let time = t.setSeconds(d.seconds);
		const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(time);
		const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
		const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
		return `${da}-${mo}-${ye}`;
	};

	shuffleArray = (array) => {
		const copy = array;
    for (let i = copy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [copy[i], copy[j]] = [copy[j], copy[i]];
    }
		return copy.slice(0,4);
}

	render() {
		const { blogs } = this.state;
		const id = this.props.match.params.id;
		const popularBlogs =
			blogs && blogs.length > 0 ? blogs.filter((b) => b.id != id) : [];
		return (
			<div className="custom-widget-area">
				<div className="widget widget_posts_thumb">
					<h3 className="widget-title">Otros Posts</h3>
					{popularBlogs && popularBlogs.length > 0 ? (
						this.shuffleArray(popularBlogs).map((b) => (
							<article className="item">
								<Link to={`/blog-post/${b.slug}`}>
									<a className="thumb">
										<img
											className="fullimage cover"
											src={b.coverImageUrl || noPhoto}
											role="img"
										></img>
									</a>
								</Link>

								<div className="info">
									<span>{this.convertDateTime(b.createdAt)}</span>
									<h4 className="title usmall">
										<Link to={`/blog-post/${b.slug}`}>
											<a>{b.mainTitle}</a>
										</Link>
									</h4>
								</div>

								<div className="clear"></div>
							</article>
						))
					) : this.state.loading ? (
						<div>Cargando...</div>
					) : (
						<div>No hay más publicaciones.</div>
					)}
				</div>
			</div>
		);
	}
}

const WrappedBlogSidebar = withRouter(BlogSidebar);

export default WrappedBlogSidebar;
