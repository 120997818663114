import React, { Component } from "react";
import TopHeader from "../components/Layouts/TopHeader";
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import PageBanner from "../components/Common/PageBanner";
import OfferStyleOne from "../components/Common/OfferStyleOne";
import Testimonials from "../components/Common/Testimonials";
import FacilitySlider from "../components/Common/FacilitySlider";
import InstagramFeed from "../components/Common/InstagramFeed";
import Footer from "../components/Layouts/Footer";

import gab_1 from "../images/about/gabriel_1.png";
import gab_2 from "../images/about/gabriel_3.png";
import gab_3 from "../images/about/gabriel_2.jpg";

import gab_4 from "../images/about/gabriel_4.png";
import gab_5 from "../images/about/graduation.jpg";
import gab_6 from "../images/about/gabriel_5.png";

class About extends Component {
	render() {
		return (
			<React.Fragment>
				<ColoredNavbar />
				<PageBanner
					pageTitle="ABOUT"
					homePageUrl="/"
					homePageText="Home"
					activePageText="About"
				/>

				<section className="about-area pb-100">
					<div className="container pt-70">
						<div className="row align-items-center">
							<div
								className="col-lg-6 col-md-12"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<div>
									<img
										src={require("../images/about/about1.jpg")}
										className="shadow"
										alt="image"
									/>
								</div>
								<div style={{marginTop: '30px'}}>
									<img
										src={require("../images/about/speech.jpg")}
										className="shadow"
										alt="image"
									/>
								</div>
							</div>

							<div className="col-lg-6 col-md-12">
								<div className="about-content">
									<span className="sub-title">Acerca de mí</span>
									<h2>Gabriel Vallone Blandamuro</h2>

									<div className="about-inner-area">
										<div className="row">
											<div className="col-12">
												<div className="about-text">
													<ul className="features-list no-pad-list">
														<li>
															Casado con Dinorah, tengo tres hermosos hijos:
															Francesca, Tommaso y Tiziana.
														</li>
														<li>
															Soy contador público y economista por la
															Universidad de la República y Máster en Dirección
															y Administración de Empresas por EAE y Universidad
															de Barcelona. Estoy especializado en negociación,
															toma de decisiones estratégicas y dirección de
															negocios.
														</li>
														<li>
															Estudié negociación en la Escuela de Leyes de
															Harvard, tengo un postgrado en Administración y
															Resolución de Conflictos en la Universidad de
															Belgrano y obtuve el certificado en Decisiones
															Estratégicas y Manejo de Riesgos de la Escuela de
															Ingeniería de la Universidad de Stanford y en
															Dirección Estratégica de la Universidad de
															Cornell.
														</li>
														<li>
															Participé en el Advanced Negotiation Master Class
															junto a otros negociadores profesionales y
															profesores de MIT, Sloan School of Management,
															Harvard Business School, y Harvard Medical School.
														</li>
														<li>
															Soy profesor de Negociación y Finanzas en UCU
															Business School y en la Universidad de Montevideo.
															He dictado mis talleres de Negociación Estratégica
															y Avanzada en el IEEM, en la Universidad ORT, en
															el ISE de San Pablo-Brasil y en la Universidad de
															Belgrano de Argentina.{" "}
														</li>
														<li>
															Tengo una larga trayectoria como consultor y
															capacitador in company en negociación, M&amp;A,
															comunicación, finanzas y liderazgo y estoy
															certificado como coach ontológico.{" "}
														</li>
														<li>
															Por más de veinticinco años he ocupado cargos
															gerenciales y de dirección general. Actualmente
															participo como asesor y consejero en la dirección
															de varias empresas pertenecientes a diversos
															sectores de la economía.
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row pt-70 align-items-center">
							<div className="col-lg-4 col-sm-6">
								<div className="about-text">
									<img src={gab_1} className="about-banner-image" />
								</div>
							</div>

							<div className="col-lg-4 col-sm-6">
								<div className="about-text">
									<img src={gab_2} className="about-banner-image" />
								</div>
							</div>

							<div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
								<div className="about-text">
									<img src={gab_3} className="about-banner-image" />
								</div>
							</div>
						</div>

						<div className="row pt-70 align-items-center">
							<div className="col-lg-4 col-sm-6">
								<div className="about-text">
									<img src={gab_4} className="about-banner-image" />
								</div>
							</div>

							<div className="col-lg-4 col-sm-6">
								<div className="about-text">
									<img src={gab_5} className="about-banner-image" />
								</div>
							</div>

							<div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
								<div className="about-text">
									<img src={gab_6} className="about-banner-image" />
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</React.Fragment>
		);
	}
}

export { About };
