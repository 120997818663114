import React, { Component } from "react";
import OrderUpdateModal from "../Modals/OrderUpdateModal";
import { firebase } from "../../../firebase";
import OrderTR from "../orders/OrderTR";

import ReactExport from "react-export-excel";

import { DOCUMENT_TYPES } from "../../../constants/document_types";
import axios from "axios";
import backendUrl from "../../../constants/urls";
import { encodeUser } from "../../../methods/api-calls";
import ReactPaginate from "react-paginate";
import up from "../../../images/icons/up-chevron.svg";
import down from "../../../images/icons/down-chevron.svg";

class OrdersTable extends Component {
	state = {
		ordersData: [],
		mailchimpData: [],
		showAll: false,
		pageCount: 0,
		loading: true,
		currentPage: 0,
		loading2: true,
		orderUpdateModal: false,
		currentSearch: "",
		order: "",
		currentSort: "",
	};

	async componentDidMount() {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("orders");
		let orderArray = [];
		dbOrderRef.where('status', '!=', 'mercado')
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let orderObj = doc.data();
					orderObj.id = doc.id;
					orderArray.push(orderObj);
				});
				this.setState({
					ordersData: orderArray,
					pageCount:
						orderArray && orderArray?.length
							? Math.ceil(orderArray.length / 100)
							: 0,
					loading: false,
				});
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});

		try {
			const res = await axios.get(`${backendUrl}api/mailchimp/members`, {
				headers: encodeUser(),
			});

			if (res.data) {
				const { members } = res.data;
				this.setState({
					mailchimpData: members,
				});
			}
		} catch (e) {
			alert(e);
		}
		this.setState({
			loading2: false,
		});
	}

	toggleOrderUpdateModal = (e) => {
		this.setState({
			orderUpdateModal: e,
		});
	};

	closeOrderUpdateModal = () => {
		this.setState({
			orderUpdateModal: false,
		});
	};

	handleShowAll = () => {
		this.setState({
			currentPage: 0,
			showAll: true,
		});
	};

	handleSearch = (e) => {
		const { value } = e?.target;
		this.setState({
			currentSearch: value,
		});
	};

	handlePageClick = (e) => {
		const { selected } = e;
		this.setState({
			currentPage: selected,
			showAll: false,
		});
	};

	getId = (e) => {
		this.setState({
			order: e,
		});
	};

	handleSort = () => {
		if (this.state.currentSort === "ASC") {
			this.setState({ currentSort: "DESC" });
		} else {
			this.setState({ currentSort: "ASC" });
		}
	};

	convertDateTime = (d) => {
		if (d) {
			let t = new Date(1970, 0, 1);
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		} else {
			return "";
		}
	};

	convertToFilter = (d) => {
		if (d) {
			let t = new Date(1970, 0, 1);
			return t.setSeconds(d.seconds);
		}
		return null;
	};

	render() {
		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		const {
			ordersData,
			showAll,
			loading,
			currentSearch,
			currentSort,
		} = this.state;

		let csvData = ordersData?.length
			? ordersData.map((o) => ({
					name: o.customerDetails?.firstName,
					surname: o.customerDetails?.lastName,
					date: this.convertDateTime(o.createdAt),
					email: o.customerDetails?.email,
					country: o.customerDetails?.country,
					city: o.customerDetails?.city,
					phone: o.customerDetails?.phone,
					documentType: o.customerDetails?.documentType
						? DOCUMENT_TYPES.find(
								(d) => d.code == o.customerDetails?.documentType
						  )?.name
						: "",
					document: o.customerDetails?.document,
					amount: o.amount,
					course: o.items && o.items.length ? o.items[0].title : "No hay info",
			  }))
			: [];

		const csvData2 = this.state.mailchimpData || [];

		let finalData = [];

		if (ordersData?.length) {
			finalData = ordersData.sort((a, b) => {
				if (a.createdAt && b.createdAt) {
					return (
						new Date(this.convertToFilter(b.createdAt)) -
						new Date(this.convertToFilter(a.createdAt))
					);
				} else if (a.createdAt && !b.createdAt) {
					return -1;
				} else if (!a.createdAt && b.createdAt) {
					return 1;
				} else {
					return 0;
				}
			});
		}

		finalData =
			currentSearch && finalData?.length
				? finalData.filter(
						(o) =>
							`${o?.customerDetails?.lastName?.toUpperCase()} ${o?.customerDetails?.firstName?.toUpperCase()}`?.includes(
								currentSearch?.toUpperCase()
							) ||
							`${o?.customerDetails?.lastName?.toUpperCase()}, ${o?.customerDetails?.firstName?.toUpperCase()}`?.includes(
								currentSearch?.toUpperCase()
							)
				  )
				: finalData?.length && !showAll
				? finalData.slice(
						this.state.currentPage * 100,
						((this.state.currentPage * 100) + 100)
				  )
				: [...finalData];

		if (currentSort) {
			let sortedByName = [...finalData];
			if (currentSort === "DESC") {
				sortedByName = finalData.sort((p1, p2) => {
					if (p1?.items?.length && !p2?.items?.length) {
						return -1;
					} else if (!p1?.items?.length && p2?.items?.length) {
						return 1;
					} else {
						return p2.items[0].title < p1.items[0].title ? -1 : 1;
					}
				});
			} else {
				sortedByName = finalData.sort((p1, p2) => {
					if (p1?.items?.length && !p2?.items?.length) {
						return -1;
					} else if (!p1?.items?.length && p2?.items?.length) {
						return 1;
					} else {
						return p1.items[0].title < p2.items[0].title ? -1 : 1;
					}
				});
			}

			finalData = sortedByName;
		}

		return (
			<div className="admin-main-content d-flex flex-column">
				<div className="page-header">
					<h2>Ordenes</h2>
				</div>

				<p style={{ marginBottom: "0px" }}>
					{" "}
					Nota: Para ver el detalle de la compra y/o cambiar el estado tanto del
					pago como del alumno seleccione una fila.
				</p>
				<p style={{ marginTop: "0px" }}>
					Los cursos que han sido aprobados figuran en verde.
				</p>

				<div style={{marginBottom: '10px', display: 'flex', flexDirection: 'column'}}>
					{!loading && csvData?.length ? (
						<ExcelFile
							element={<button> Descargar ordenes en archivo .xlsx</button>}
							filename="datos-usuarios"
						>
							<ExcelSheet data={csvData} name="Ordenes">
								<ExcelColumn label="Apellido" value="surname" />
								<ExcelColumn label="Nombre" value="name" />
								<ExcelColumn label="Fecha" value="date" />
								<ExcelColumn label="Email" value="email" />
								<ExcelColumn label="País" value="country" />
								<ExcelColumn label="Ciudad" value="city" />
								<ExcelColumn label="Teléfono" value="phone" />
								<ExcelColumn label="Tipo de documento" value="documentType" />
								<ExcelColumn label="Documento" value="document" />
								<ExcelColumn label="Monto" value="amount" />
								<ExcelColumn label="Curso" value="course" />
							</ExcelSheet>
						</ExcelFile>
					) : (
						<p>Cargando datos de inscripciones para exportar...</p>
					)}
					{!loading && csvData2?.length ? (
						<ExcelFile
							element={
								<button>
									{" "}
									Descargar suscriptores al blog en archivo .xlsx
								</button>
							}
							filename="datos-suscriptores"
						>
							<ExcelSheet data={csvData2} name="Suscriptores">
								<ExcelColumn label="Email" value="email" />
								<ExcelColumn label="País" value="country" />
							</ExcelSheet>
						</ExcelFile>
					) : (
						<p>Cargando datos del blog para exportar...</p>
					)}
				</div>

				{loading ? (
					<p style={{ textAlign: "center" }}>Cargando...</p>
				) : (
					<>
						<input
							placeholder="Busca por apellido, nombre..."
							onChange={this.handleSearch}
							value={this.state.currentSearch}
						></input>

						<div className="admin-table admin-orders-table height-555">
							<div className="table-responsive">
								<table
									className="table table-hover mb-0"
									style={{ display: !finalData?.length ? "grid" : "" }}
								>
									<thead>
										<tr>
											<th>Nombre</th>
											<th>Fecha</th>
											<th>
												{" "}
												<button
													onClick={() => this.handleSort()}
													style={{
														background: "none",
														outline: "none",
														border: "0",
														fontWeight: "bold",
													}}
												>
													Curso
													<img
														style={{ height: "8px", paddingLeft: "2px" }}
														src={this.state.currentSort === "ASC" ? up : down}
													/>
												</button>
											</th>
											<th>Telefono</th>
											<th>Mail</th>
											<th>País</th>
											<th>Monto</th>
											<th>Usó cupon</th>
											<th>Medio de pago</th>
											<th>Pago</th>
											<th>Combo</th>
											<th>Quien pagó</th>
											<th>Acciones</th>
										</tr>
									</thead>
									<tbody>
										{finalData?.length ? (
											<OrderTR
												onOrderModal={this.toggleOrderUpdateModal}
												onPassId={this.getId}
												orders={finalData}
											/>
										) : (
											<p style={{ textAlign: "center" }}>No hay órdenes.</p>
										)}
									</tbody>
								</table>
							</div>
						</div>
						<ReactPaginate
							previousLabel={"anterior"}
							nextLabel={"siguiente"}
							breakLabel={"..."}
							breakClassName={"break-me"}
							previousClassName={"break-me"}
							nextClassName={"break-me"}
							pageClassName={"break-me"}
							nextLinkClassName={"break-me-link"}
							previousLinkClassName={"break-me-link"}
							pageCount={this.state.pageCount}
							marginPagesDisplayed={2}
							initialPage={0}
							pageRangeDisplayed={5}
							onPageChange={this.handlePageClick}
							containerClassName={"pagination"}
							breakLinkClassName={"break-me-link"}
							pageLinkClassName={"break-me-link"}
							activeLinkClassName={"break-me-link"}
							subContainerClassName={"pages pagination"}
							activeClassName={"special-active-alt"}
						/>
						<button
							style={{ width: "150px", marginBottom: "10px" }}
							onClick={this.handleShowAll}
						>
							Mostrar todo
						</button>
					</>
				)}

				{this.state.orderUpdateModal && (
					<OrderUpdateModal
						onClick={this.closeOrderUpdateModal}
						order={this.state.order}
						active={this.state.orderUpdateModal ? "active" : ""}
					/>
				)}

				{/* Footer */}
				<div className="flex-grow-1"></div>
			</div>
		);
	}
}

export default OrdersTable;
