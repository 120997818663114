import React, { Component } from 'react';
import ColoredNavbar from '../components/Layouts/ColoredNavbar';
import Footer from '../components/Layouts/Footer';
import BlogGridThree from '../components/Blog/BlogGridThree';
import  PageBanner  from '../components/Common/PageBanner';

class AllBlogs extends Component {
    render() {
        return (
            <div className="contact-page">
            <React.Fragment>
                <ColoredNavbar />
                <PageBanner 
                    pageTitle="BLOG" 
                    homePageUrl="/" 
                    homePageText="Home" 
                    activePageText="Blogs" 
                /> 
                <BlogGridThree />
                <Footer />
            </React.Fragment>
            </div>
        );
    }
}

export { AllBlogs };