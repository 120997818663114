import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link, withRouter, Redirect } from "react-router-dom";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import BarLoader from "react-spinners/BarLoader";
import { parse, v4 as uuidv4 } from "uuid";
import { generateUniqueId } from "../../utils/id";
import MercadoPago from "../MercadoPago/MercadoPago";

import axios from "axios";
import {
	SIEMPRE_PAGO_CODES,
	SIEMPRE_PAGO_TRANSACTION_CODES,
} from "../../constants/siempre_pago_codes";

import SiemprePago from "../../scripts/SiemprePago";
import {
	addSingleOrder,
	editCourse,
	removeCoupon,
	addMercadoPagoItems,
	removeMercadoPagoItems,
} from "../../store/actions/cartActions";
import { firebase } from "../../firebase";
import backendUrl from "../../constants/urls";
import { fetchCourses } from "../../methods/api-calls";
import { setOrderStatus } from "../../constants/payment_types";
import { encodeUser } from "../../methods/api-calls";
import visa from "../../images/cards/visa.png";
import diners from "../../images/cards/diners.png";
import amex from "../../images/cards/amex.png";
import master from "../../images/cards/master.png";
import oca from "../../images/cards/oca.png";

const COUPON_TYPES = {
	INFLUENCER: 1,
	GENERIC: 2,
	NO_COST: 3,
	INVITATION: 4,
};

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

class CheckoutForm extends Component {
	constructor(props) {
		super(props);
		this.subscribeFree = this.subscribeFree.bind(this);
	}
	state = {
		qty: 1,
		max: 10,
		min: 1,
		info: null,
		loading: true,
		canSuscribe: false,
		loadingSuscriptionStatus: false,
		data: null,
		courses: null,
		course: null,
		amountOfPeople: null,
		redirectHome: false,
		siemprePagoReady: false,
	};

	calculateDiscount = (totalAmount, coupon) => {
		if (coupon && coupon?.type != COUPON_TYPES.NO_COST) {
			return totalAmount - Math.ceil((totalAmount * coupon.percentage) / 100);
		} else {
			return totalAmount;
		}
	};

	handleEmptyForm = () => {
		toast.error("Por favor, complete el formulario", {
			position: "bottom-left",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	};

	prepareMPagoInfo = async (
		mercadoNumber
	) => {
		const { stateData, amountOfPeople, coupon } = this.props;
		const { course } = this.state;
		if (stateData && course && amountOfPeople) {
			let allOrders = [];
			let order2;
			let order3;

			const order = {
				mercadoNumber: mercadoNumber,
				customerDetails: {
					firstName: stateData.firstName?.value,
					lastName: stateData.lastName?.value,
					email: stateData.email?.value,
					country: stateData.country?.value,
					phone: stateData.phone?.value,
					city: stateData.city?.value,
					documentType: stateData.documentType?.value,
					document: stateData.document?.value,
				},
				payedBy: stateData.payedBy?.value
					? stateData.payedBy?.value
					: stateData.email?.value,
				items: [course],
				amount:
					coupon && coupon.percentage
						? this.calculateDiscount(course.newPrice, coupon)
						: course.newPrice,
				// status: status ? status.toLowerCase() : "approved",
				status: 'mercado',
				paymentType: "No hay info",
				isPartOfPlan: false,
				createdAt: new Date(),
				courseStatus: "pending",
				usedCoupon: coupon ? true : false,
				paymentId: "",
				transactionId: "",
			};

			if (amountOfPeople == 2) {
				order.amount =
					coupon && coupon.percentage
						? this.calculateDiscount(course.newPriceForTwo, coupon)
						: course.newPriceForTwo;
				order.isPartOfPlan = true;
				order2 = {
					mercadoNumber: mercadoNumber,
					customerDetails: {
						firstName: stateData.firstName2?.value,
						lastName: stateData.lastName2?.value,
						email: stateData.email2?.value,
						country: stateData.country2?.value,
						phone: stateData.phone2?.value,
						city: stateData.city2?.value,
						documentType: stateData.documentType2?.value,
						document: stateData.document2?.value,
					},
					payedBy: stateData.payedBy?.value
						? stateData.payedBy?.value
						: stateData.email?.value,
					items: [course],
					amount:
						coupon && coupon.percentage
							? this.calculateDiscount(course.newPriceForTwo, coupon)
							: course.newPriceForTwo,
					// status: status ? status.toLowerCase() : "approved",
					status: 'mercado',
					paymentType: "No hay info",
					isPartOfPlan: true,
					createdAt: new Date(),
					courseStatus: "pending",
					usedCoupon: coupon ? true : false,
					paymentId: "",
					transactionId: "",
				};
				allOrders.push(order2);
			} else if (amountOfPeople == 3) {
				order.amount =
					coupon && coupon.percentage
						? this.calculateDiscount(course.newPriceForThree, coupon)
						: course.newPriceForThree;
				order.isPartOfPlan = true;

				order2 = {
					mercadoNumber: mercadoNumber,
					customerDetails: {
						firstName: stateData.firstName2?.value,
						lastName: stateData.lastName2?.value,
						email: stateData.email2?.value,
						country: stateData.country2?.value,
						phone: stateData.phone2?.value,
						city: stateData.city2?.value,
						documentType: stateData.documentType2?.value,
						document: stateData.document2?.value,
					},
					payedBy: stateData.payedBy?.value
						? stateData.payedBy?.value
						: stateData.email,
					items: [course],
					amount:
						coupon && coupon.percentage
							? this.calculateDiscount(course.newPriceForThree, coupon)
							: course.newPriceForThree,
					status: 'mercado',
					paymentType: "No hay info",
					isPartOfPlan: true,
					createdAt: new Date(),
					courseStatus: "pending",
					usedCoupon: coupon ? true : false,
					paymentId: "",
					transactionId: "",
				};

				order3 = {
					mercadoNumber: mercadoNumber,
					customerDetails: {
						firstName: stateData.firstName3?.value,
						lastName: stateData.lastName3?.value,
						email: stateData.email3?.value,
						country: stateData.country3?.value,
						phone: stateData.phone3?.value,
						city: stateData.city3?.value,
						documentType: stateData.documentType3?.value,
						document: stateData.document3?.value,
					},
					payedBy: stateData.payedBy?.value
						? stateData.payedBy?.value
						: stateData.email,
					items: [course],
					amount:
						coupon && coupon.percentage
							? this.calculateDiscount(course.newPriceForThree, coupon)
							: course.newPriceForThree,
					status: 'mercado',
					paymentType: "No hay info",
					isPartOfPlan: true,
					createdAt: new Date(),
					courseStatus: "pending",
					usedCoupon: coupon ? true : false,
					paymentId: "",
					transactionId: "",
				};

				allOrders.push(order2);
				allOrders.push(order3);
			}
			allOrders.push(order);

			let db = firebase.firestore();
			let dbOrderRef = db.collection("orders");
			const moreInfoOrders = [...allOrders.map(o => ({
				...o, extraInfo:
					{ stateData: stateData, coupon: coupon, course: course, amountOfPeople: amountOfPeople }
			}))]

			for (var i = 0; i < moreInfoOrders.length; i++) {
				let o = moreInfoOrders[i];
				if (o.id) {
					await dbOrderRef.doc(o.id).set({ ...o });
				} else {
					await dbOrderRef.add(o);
				}
				// this.props.addSingleOrder(o);
			}

		}
		return null;
	};

	addToCart = async (
		paymentType,
		inProcess = false,
	) => {
		const { stateData, amountOfPeople, coupon } = this.props;
		const { course } = this.state;
		if (stateData && course && amountOfPeople) {
			let allOrders = [];
			let order2;
			let order3;

			const order = {
				customerDetails: {
					firstName: stateData.firstName?.value,
					lastName: stateData.lastName?.value,
					email: stateData.email?.value,
					country: stateData.country?.value,
					phone: stateData.phone?.value,
					city: stateData.city?.value,
					documentType: stateData.documentType?.value,
					document: stateData.document?.value,
				},
				payedBy: stateData.payedBy?.value
					? stateData.payedBy?.value
					: stateData.email?.value,
				items: [course],
				amount:
					coupon && coupon.percentage
						? this.calculateDiscount(course.newPrice, coupon)
						: course.newPrice,
				// status: status ? status.toLowerCase() : "approved",
				status: setOrderStatus(paymentType, inProcess),
				paymentType: paymentType || "No hay info",
				isPartOfPlan: false,
				createdAt: new Date(),
				courseStatus: "pending",
				usedCoupon: coupon ? true : false,
				paymentId: "",
				transactionId: "",
			};

			if (amountOfPeople == 2) {
				order.amount =
					coupon && coupon.percentage
						? this.calculateDiscount(course.newPriceForTwo, coupon)
						: course.newPriceForTwo;
				order.isPartOfPlan = true;
				order2 = {
					customerDetails: {
						firstName: stateData.firstName2?.value,
						lastName: stateData.lastName2?.value,
						email: stateData.email2?.value,
						country: stateData.country2?.value,
						phone: stateData.phone2?.value,
						city: stateData.city2?.value,
						documentType: stateData.documentType2?.value,
						document: stateData.document2?.value,
					},
					payedBy: stateData.payedBy?.value
						? stateData.payedBy?.value
						: stateData.email?.value,
					items: [course],
					amount:
						coupon && coupon.percentage
							? this.calculateDiscount(course.newPriceForTwo, coupon)
							: course.newPriceForTwo,
					// status: status ? status.toLowerCase() : "approved",
					status: setOrderStatus(paymentType, inProcess),
					paymentType: paymentType || "No hay info",
					isPartOfPlan: true,
					createdAt: new Date(),
					courseStatus: "pending",
					usedCoupon: coupon ? true : false,
					paymentId: "",
					transactionId: "",
				};
				allOrders.push(order2);
			} else if (amountOfPeople == 3) {
				order.amount =
					coupon && coupon.percentage
						? this.calculateDiscount(course.newPriceForThree, coupon)
						: course.newPriceForThree;
				order.isPartOfPlan = true;

				order2 = {
					customerDetails: {
						firstName: stateData.firstName2?.value,
						lastName: stateData.lastName2?.value,
						email: stateData.email2?.value,
						country: stateData.country2?.value,
						phone: stateData.phone2?.value,
						city: stateData.city2?.value,
						documentType: stateData.documentType2?.value,
						document: stateData.document2?.value,
					},
					payedBy: stateData.payedBy?.value
						? stateData.payedBy?.value
						: stateData.email,
					items: [course],
					amount:
						coupon && coupon.percentage
							? this.calculateDiscount(course.newPriceForThree, coupon)
							: course.newPriceForThree,
					paymentType: paymentType || "No hay info",
					// status: status ? status.toLowerCase() : "approved",
					status: setOrderStatus(paymentType, inProcess),
					isPartOfPlan: true,
					createdAt: new Date(),
					courseStatus: "pending",
					usedCoupon: coupon ? true : false,
					paymentId: "",
					transactionId: "",
				};

				order3 = {
					customerDetails: {
						firstName: stateData.firstName3?.value,
						lastName: stateData.lastName3?.value,
						email: stateData.email3?.value,
						country: stateData.country3?.value,
						phone: stateData.phone3?.value,
						city: stateData.city3?.value,
						documentType: stateData.documentType3?.value,
						document: stateData.document3?.value,
					},
					payedBy: stateData.payedBy?.value
						? stateData.payedBy?.value
						: stateData.email,
					items: [course],
					amount:
						coupon && coupon.percentage
							? this.calculateDiscount(course.newPriceForThree, coupon)
							: course.newPriceForThree,
					paymentType: paymentType || "No hay info",
					// status: status ? status.toLowerCase() : "approved",
					status: setOrderStatus(paymentType, inProcess),
					isPartOfPlan: true,
					createdAt: new Date(),
					courseStatus: "pending",
					usedCoupon: coupon ? true : false,
					paymentId: "",
					transactionId: "",
				};

				allOrders.push(order2);
				allOrders.push(order3);
			}
			allOrders.push(order);

			let db = firebase.firestore();
			let dbOrderRef = db.collection("orders");

			for (var i = 0; i < allOrders.length; i++) {
				let o = allOrders[i];
				if (o.id) {
					await dbOrderRef.doc(o.id).set({ ...o });
				} else {
					await dbOrderRef.add(o);
				}
				this.props.addSingleOrder(o);
			}

			if (coupon && coupon.id) {
				try {
					const res = await axios.post(
						`${backendUrl}api/coupons/use-coupon`,
						{
							coupon: coupon,
						},
						{
							headers: encodeUser(),
						}
					);
				} catch (e) {
					alert(e);
					this.setState({ loadingSuscriptionStatus: false });
				}
			}

			const courseId = course.id;
			if (course.hasMaxParticipants) {
				course.quantity -= amountOfPeople;
			}
			db = firebase.firestore();
			dbOrderRef = db.collection("courses");
			dbOrderRef
				.doc(courseId)
				.update(course)
				.then(() => {
					this.props.editCourse(course);
					const data = {
						allOrders,
					};

					const authHeader = encodeUser();

					axios
						.post(`${backendUrl}api/mailer/notify`, data, {
							headers: {
								accept: "application/json",
								"Accept-Language": "en-US,en;q=0.8",
								"Content-Type": `application/json`,
								...authHeader,
							},
						})
						.then((response) => {
							this.setState({
								redirectHome: true,
							});

							this.props.removeMercadoPagoItems();
							this.props.removeCoupon();
							this.props.onFinish();

							if (200 === response.status) {
								if (response.data.error) {
									alert(response.data.error);
									toast.error("Ha ocurrido un problema.", {
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									});
								} else {
									// Success
									console.log("email sent");
									var users = [];
									users.push(order?.customerDetails);
									if (amountOfPeople > 1) users.push(order2?.customerDetails);
									if (amountOfPeople > 2) users.push(order3?.customerDetails);
									const body = {
										users: users,
									};
									const authHeaderTwo = encodeUser();
									axios
										.post(`${backendUrl}api/mailer/createUsers`, body, {
											headers: {
												accept: "application/json",
												"Accept-Language": "en-US,en;q=0.8",
												"Content-Type": `application/json`,
												...authHeaderTwo,
											},
										})
										.then((response) => { })
										.catch((error) => {
											toast.warn(
												"Ha ocurrido un problema creando tu usuario.",
												{
													position: "bottom-left",
													autoClose: 3000,
													hideProgressBar: true,
													closeOnClick: true,
													pauseOnHover: true,
													draggable: true,
												}
											);
											alert(error, "red");
										});
								}
							}
						})
						.catch((error) => {
							toast.error("Ha ocurrido un problema con la confirmación.", {
								position: "bottom-left",
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
							});
							alert(error, "red");
						});
					this.setState({ loadingSuscriptionStatus: false });
				})
				.catch((error) => {
					toast.error("Ha ocurrido un problema.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					alert(error, "red");
					this.setState({ loadingSuscriptionStatus: false });
				});
		} else {
			toast.error("Ha ocurrido un problema.", {
				position: "bottom-left",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			this.setState({ loadingSuscriptionStatus: false });
		}
	};

	async componentDidMount() {
		const id = this.props.course_id;
		let { courses, stateData, course } = this.props;
		let c = null;
		let data = null;
		if (course) {
			this.setState({
				loading: false,
				course: course,
				amountOfPeople: this.props.amountOfPeople,
			});
		} else {
			courses = await fetchCourses();
			data =
				courses && courses.length > 0
					? courses.filter((courses) => courses.id == id)
					: null;
			c = data ? data[0] : null;
			this.setState({
				loading: false,
				course: c,
				amountOfPeople: this.props.amountOfPeople,
			});
		}

		let totalAmount = 0;
		if (c != null) {
			if (this.state.amountOfPeople == 1) {
				totalAmount = c.newPrice;
			} else if (this.state.amountOfPeople == 2) {
				totalAmount = c.newPriceForTwo * 2;
			} else if (this.state.amountOfPeople == 3) {
				totalAmount = c.newPriceForThree * 3;
			} else {
				totalAmount = this.state.amountOfPeople * c.newPrice;
			}
		}

		this.setState({ totalAmount: totalAmount });

		if (c && !c.isFree) {
			const info = {
				items: [
					{
						courseId: c.id,
						title: c.title,
						unit_price: totalAmount / this.props.amountOfPeople,
						quantity: this.props.amountOfPeople
							? Number(this.props.amountOfPeople)
							: 1,
						currency_id: c.currency || "USD",
					},
				],
			};

			this.props.addMercadoPagoItems(info);
			// this.setState({ info: info });
		}

		if (stateData) {
			const { email, email2, email3 } = stateData;
			if (email?.value) {
				this.setState({ firstEmail: email?.value });
			}
			if (email2?.value) {
				this.setState({ secondEmail: email2?.value });
			}
			if (email3?.value) {
				this.setState({ thirdEmail: email3?.value });
			}
		}
	}

	// loadSiemprePago = (quotaAmount) => {
	// 	const { stateData, coupon } = this.props;
	// 	const { totalAmount, course } = this.state;

	// 	let finalEmail = "";
	// 	if (stateData.payedBy?.value) {
	// 		finalEmail = stateData.payedBy?.value;
	// 	} else if (stateData.email?.value) {
	// 		finalEmail = stateData.email?.value;
	// 	}

	// 	let finalAmount = totalAmount;

	// 	if (coupon && coupon.percentage) {
	// 		finalAmount = this.calculateDiscount(totalAmount, coupon);
	// 	}

	// 	if (course && totalAmount) {
	// 		SiemprePago(() => {
	// 			// eslint-disable-next-line no-undef
	// 			PWCheckout.SetProperties({
	// 				name: "Gabriel Vallone - SiemprePago",
	// 				email: finalEmail,
	// 				checkout_card: true,
	// 				button_label: "Pagar #monto#",
	// 				description: "",
	// 				currency: course?.currency,
	// 				amount: finalAmount || 0,
	// 				lang: "ESP",
	// 				form_id: "commerce_form",
	// 				autoSubmit: "false",
	// 				close_onclick: "false",
	// 			});

	// 			// eslint-disable-next-line no-undef
	// 			this.openIframe(PWCheckout, quotaAmount);

	// 			var formGroup = document.getElementsByClassName("form-group");

	// 			for (var i = 0, len = formGroup.length; i < len; i++) {
	// 				formGroup[i].style.display = "flex";
	// 				formGroup[i].style["flex-direction"] = "column";
	// 				formGroup[i].style["align-items"] = "stretch";
	// 			}
	// 		});
	// 	}
	// };

	// onTokenCreated = async (tokenString) => {
	// 	const token = JSON.stringify(tokenString);
	// 	const parsedToken = JSON.parse(token);
	// 	const cardToken = parsedToken?.TokenId;
	// 	const paymentType = parsedToken?.CardType;

	// 	if (!cardToken) return;

	// 	const fakeID = generateUniqueId();

	// 	const { totalAmount, course } = this.state;
	// 	const { stateData, coupon } = this.props;

	// 	let finalEmail = "";
	// 	if (stateData.payedBy?.value) {
	// 		finalEmail = stateData.payedBy?.value;
	// 	} else if (stateData.email?.value) {
	// 		finalEmail = stateData.email?.value;
	// 	}

	// 	let finalAmount = totalAmount;

	// 	if (coupon && coupon.percentage) {
	// 		finalAmount = this.calculateDiscount(totalAmount, coupon);
	// 	}

	// 	finalAmount = Number(`${finalAmount}00`);

	// 	try {
	// 		const body = {
	// 			token: cardToken,
	// 			orderId: fakeID,
	// 			amount: finalAmount,
	// 			currency: course?.currency,
	// 			email: finalEmail,
	// 			stateData: stateData,
	// 			course: course,
	// 			quotaAmount: stateData?.quotaAmount?.value
	// 				? Number(stateData?.quotaAmount?.value)
	// 				: 1,
	// 			customer: {
	// 				country: stateData?.country?.value,
	// 				location: stateData?.location?.value,
	// 				city: stateData?.city?.value,
	// 				address: stateData?.address?.value,
	// 				documentType: stateData?.documentType?.value,
	// 				document: stateData?.document?.value,
	// 				phone: stateData?.phone?.value,
	// 			},
	// 		};
	// 		this.setState({ loadingSuscriptionStatus: true });

	// 		const res = await axios.post(
	// 			`${backendUrl}api/orders/siempre-pago-payment`,
	// 			body,
	// 			{
	// 				headers: encodeUser(),
	// 			}
	// 		);

	// 		if (res) {
	// 			const { data } = res;
	// 			if (data) {
	// 				const {
	// 					errors,
	// 					orderId,
	// 					purchaseId,
	// 					transactionErrorCode,
	// 					transactionId,
	// 					transactionStatus,
	// 					transactionStatusId,
	// 				} = data;
	// 				if (errors && errors.length > 0) {
	// 					const firstError = errors[0];
	// 					const translatedError = SIEMPRE_PAGO_CODES.find(
	// 						(er) => er.id == firstError.ErrorCode
	// 					);
	// 					const finalMessage =
	// 						translatedError && translatedError.message
	// 							? translatedError.message
	// 							: "Ha ocurrido un error procesando el pago.";
	// 					toast.error(finalMessage, {
	// 						position: "bottom-left",
	// 						hideProgressBar: false,
	// 						closeOnClick: true,
	// 						pauseOnHover: true,
	// 						draggable: true,
	// 					});
	// 					this.setState({ loadingSuscriptionStatus: false });
	// 				} else {
	// 					if (
	// 						transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.APPROVED
	// 					) {
	// 						await this.addToCart(
	// 							orderId,
	// 							purchaseId,
	// 							transactionId,
	// 							paymentType,
	// 							transactionStatus
	// 						);
	// 						this.setState({ loadingSuscriptionStatus: false });
	// 						toast.success("Tu pago se ha completado con éxito.", {
	// 							position: "bottom-left",
	// 							autoClose: 3000,
	// 							hideProgressBar: true,
	// 							closeOnClick: true,
	// 							pauseOnHover: true,
	// 							draggable: true,
	// 						});
	// 					} else if (
	// 						transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.PENDING
	// 					) {
	// 						//PENDING, have to check for commerce action.
	// 						toast.warn(
	// 							"Estamos procesando tu pago, cuando esté aprovado recibirás la confirmación.",
	// 							{
	// 								position: "bottom-left",
	// 								hideProgressBar: true,
	// 								closeOnClick: true,
	// 								pauseOnHover: true,
	// 								draggable: true,
	// 							}
	// 						);
	// 						this.setState({ loadingSuscriptionStatus: false });
	// 					} else if (
	// 						transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.REJECTED
	// 					) {
	// 						//REJECTED
	// 						if (transactionErrorCode) {
	// 							const translatedError = SIEMPRE_PAGO_CODES.find(
	// 								(er) => er.id == transactionErrorCode
	// 							);
	// 							const finalMessage =
	// 								translatedError && translatedError.message
	// 									? translatedError.message
	// 									: "Ha ocurrido un error procesando el pago.";
	// 							toast.error(finalMessage, {
	// 								position: "bottom-left",
	// 								hideProgressBar: true,
	// 								closeOnClick: true,
	// 								pauseOnHover: true,
	// 								draggable: true,
	// 							});
	// 							this.setState({ loadingSuscriptionStatus: false });
	// 						} else {
	// 							toast.error("Ha ocurrido un error procesando el pago.", {
	// 								position: "bottom-left",
	// 								hideProgressBar: true,
	// 								closeOnClick: true,
	// 								pauseOnHover: true,
	// 								draggable: true,
	// 							});
	// 							this.setState({ loadingSuscriptionStatus: false });
	// 						}
	// 					} else if (
	// 						transactionStatusId == SIEMPRE_PAGO_TRANSACTION_CODES.CANCELLED
	// 					) {
	// 						//CANCELLED
	// 						if (transactionErrorCode) {
	// 							const translatedError = SIEMPRE_PAGO_CODES.find(
	// 								(er) => er.id == transactionErrorCode
	// 							);
	// 							const finalMessage =
	// 								translatedError && translatedError.message
	// 									? translatedError.message
	// 									: "Ha ocurrido un error procesando el pago.";
	// 							toast.error(finalMessage, {
	// 								position: "bottom-left",
	// 								hideProgressBar: true,
	// 								closeOnClick: true,
	// 								pauseOnHover: true,
	// 								draggable: true,
	// 							});
	// 						} else {
	// 							toast.error("Ha ocurrido un error procesando el pago.", {
	// 								position: "bottom-left",
	// 								hideProgressBar: true,
	// 								closeOnClick: true,
	// 								pauseOnHover: true,
	// 								draggable: true,
	// 							});
	// 						}
	// 						this.setState({ loadingSuscriptionStatus: false });
	// 					}
	// 				}
	// 			}
	// 		} else {
	// 			this.setState({ loadingSuscriptionStatus: false });
	// 		}
	// 	} catch (e) {
	// 		this.setState({ loadingSuscriptionStatus: false });

	// 		const { data } = e;

	// 		if (data) {
	// 			const { errors } = data;
	// 			const { message } = data;

	// 			if (errors && errors.length > 0) {
	// 				const firstError = errors[0];
	// 				const translatedError = SIEMPRE_PAGO_CODES.find(
	// 					(er) => er.id == firstError.ErrorCode
	// 				);
	// 				const finalMessage =
	// 					translatedError && translatedError.message
	// 						? translatedError.message
	// 						: "Ha ocurrido un error procesando el pago.";
	// 				toast.error(finalMessage, {
	// 					position: "bottom-left",
	// 					hideProgressBar: false,
	// 					closeOnClick: true,
	// 					pauseOnHover: true,
	// 					draggable: true,
	// 				});
	// 			} else if (message) {
	// 				toast.error(message, {
	// 					position: "bottom-left",
	// 					hideProgressBar: false,
	// 					closeOnClick: true,
	// 					pauseOnHover: true,
	// 					draggable: true,
	// 				});
	// 			}
	// 		} else {
	// 			toast.error("No hemos podido procesar su pago.", {
	// 				position: "bottom-left",
	// 				hideProgressBar: false,
	// 				closeOnClick: true,
	// 				pauseOnHover: true,
	// 				draggable: true,
	// 			});
	// 		}
	// 	}
	// };

	// openIframe = (pWCheckout, quotaAmount) => {
	// 	pWCheckout.SetStyle({
	// 		buttonColor: "37626F",
	// 		backgroundColor: "f5f2e8",
	// 		buttonHoverColor: "5c8591",
	// 		buttonTextColor: "ffffff",
	// 		buttonTextHoverColor: "ffffff",
	// 		inputBackgroundColor: "f5f4f0",
	// 		inputTextColor: "767676",
	// 		inputErrorColor: "ff0000",
	// 		inputAddonBackgroundColor: "37626F",
	// 		labelColor: "5c8591",
	// 	});

	// 	// <!-- Evento que se dispara al generarse el token -->
	// 	pWCheckout.Bind("tokenCreated", this.onTokenCreated);

	// 	if (quotaAmount > 1) {
	// 		pWCheckout.Iframe.OpenIframeWithPaymentMediaOptions([1, 2, 4]);
	// 	} else {
	// 		pWCheckout.Iframe.OpenIframeWithPaymentMediaOptions([1, 2, 4, 6, 7, 14]);
	// 	}
	// };

	checkIfAllowedFreeFlow = async () => {
		const { stateData } = this.props;
		const {course} = this.state;
		if (course.id && stateData) {
			const isAllowed = await this.check(course, stateData);
			if (isAllowed) {
				this.props.disableFields();
				const { course } = this.state;
				if (course && course.isFree) {
					try {
						await this.subscribeFree();
					} catch (e) {
						alert(e?.message || e);
					}
				}
			}
		}
	};

	checkIfAllowedInvitationFlow = async () => {
		const { stateData } = this.props;
		const { course } = this.state;
		if (course.id && stateData) {
			const isAllowed = await this.check(course, stateData);
			if (isAllowed) {
				this.props.disableFields();
				const { course } = this.state;
				if (course && !course.isFree) {
					const fakeID = generateUniqueId();
					const orderId = fakeID ? fakeID.toString() : null;

					this.setState({ loadingSuscriptionStatus: true });

					try {
						await this.addToCart(orderId);
						this.setState({ loadingSuscriptionStatus: false });
						toast.success("Tu suscripción se ha completado con éxito.", {
							position: "bottom-left",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});

						this.setState({ loadingSuscriptionStatus: false });
					} catch (e) {
						console.log(e);
						toast.error("No hemos podido procesar la suscripción.", {
							position: "bottom-left",
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						this.setState({ loadingSuscriptionStatus: false });
					}
				}
			}
		}
	};

	checkIfAllowedNoCostFlow = async () => {
		const { stateData } = this.props;
		const { course} = this.state;
		if (course.id && stateData) {
			const isAllowed = await this.check(course, stateData);
			if (isAllowed) {
				this.props.disableFields();
				const { course } = this.state;
				if (course && !course.isFree) {
					const fakeID = generateUniqueId();
					const orderId = fakeID ? fakeID.toString() : null;

					this.setState({ loadingSuscriptionStatus: true });

					try {
						await this.addToCart(orderId);
						this.setState({ loadingSuscriptionStatus: false });
						toast.success("Tu suscripción se ha completado con éxito.", {
							position: "bottom-left",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});

						this.setState({ loadingSuscriptionStatus: false });
					} catch (e) {
						console.log(e);
						toast.error("No hemos podido procesar la suscripción.", {
							position: "bottom-left",
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						this.setState({ loadingSuscriptionStatus: false });
					}
				}
			}
		}
	};

	checkIfAllowedPaymentFlow = async () => {
		const { stateData } = this.props;
		const { course } = this.state
		if (course.id && stateData) {
			const isAllowed = await this.check(course, stateData);
			if (isAllowed) {
				this.props.disableFields();
				const { course } = this.state;
				if (course && !course.isFree) {
					this.setState({
						siemprePagoReady: true,
					});
					// let installments = stateData?.quotaAmount?.value
					// 	? Number(stateData?.quotaAmount?.value)
					// 	: 1;
					// this.loadSiemprePago(installments);
				}
			}
		}
	};

	async check(course, stateData) {
		if (stateData && course) {
			this.setState({ loadingSuscriptionStatus: true });

			let emails = [];
			const { email, email2, email3 } = stateData;

			if (email?.value) {
				emails.push(email.value);
			}
			if (email2?.value) {
				emails.push(email2.value);
			}
			if (email3?.value) {
				emails.push(email3.value);
			}
			const body = {
				emails: emails,
				course: course
			};
			try {
				const response = await axios.post(
					`${backendUrl}api/orders/check-if-allowed`,
					body,
					{
						headers: encodeUser(),
					}
				);
				this.setState({ loadingSuscriptionStatus: false });

				if (response) {
					const { data } = response;
					if (!data?.allowed) {
						this.setState({ canSuscribe: false });
						toast.warn("Ya está inscripto a este curso", {
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						return false;
					} else {
						this.setState({ canSuscribe: true });
						return true;
					}
				}
			} catch (error) {
				alert(error?.message || error);
				this.setState({ loadingSuscriptionStatus: false });

				return false;
			}
		}
	}

	async subscribeFree() {
		await this.addToCart(null);
		setTimeout(function () {
			toast.success("Tu suscripción se ha completado con éxito.", {
				position: "bottom-left",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}, 1500);
	}

	dateExpired = (modules) => {
		let disabled = false;
		if (modules && modules.length > 0) {
			modules.forEach((m) => {
				const oldDate = m.date && m.date.length ? m.date.split("-") : null;
				if (oldDate) {
					const newDate = oldDate.slice(1, 3);
					newDate.push(oldDate[0]);

					let newest = new Date();
					newest.setDate(newDate[1]);
					newest.setMonth(newDate[0] - 1);
					newest.setFullYear(newDate[2]);

					if (newest < Date.now()) {
						disabled = true;
					}
				}
			});
		}
		return disabled;
	};

	render() {
		let totalAmount = 0;
		let quantity = 0;
		let price = 0;
		if (this.state.course != null) {
			if (this.state.amountOfPeople == 1) {
				totalAmount = this.state.course.newPrice;
				price = this.state.course.newPrice;
			} else if (this.state.amountOfPeople == 2) {
				totalAmount = this.state.course.newPriceForTwo * 2;
				price = this.state.course.newPriceForTwo;
			} else if (this.state.amountOfPeople == 3) {
				totalAmount = this.state.course.newPriceForThree * 3;
				price = this.state.course.newPriceForThree;
			} else {
				totalAmount = this.state.amountOfPeople * this.state.course.newPrice;
				quantity = this.state.amountOfPeople;
			}
		}
		let course = this.state.course;
		let { disabled, coupon } = this.props;

		if (coupon && coupon.percentage) {
			totalAmount = this.calculateDiscount(totalAmount, coupon);
		}

		var isMobile = false;
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}

		if (
			course &&
			((course.maxParticipants > 0 && course.quantity === 0) ||
				(course.modules?.length > 0 && this.dateExpired(course.modules)))
		) {
			return <Redirect to="/" />;
		}
		return (
			<div className="col-lg-6 col-md-12">
				{this.state.redirectHome ? (
					<div className="success-container">
						<div className="success-hint">
							Recibimos tu suscripción. Gracias.
						</div>
						<Link to="/" className={"default-btn"}>
							VOLVER AL INICIO
						</Link>
					</div>
				) : (
					<div className="order-details">
						<h3 className="title">SU INSCRIPCIÓN</h3>

						<div className="order-table table-responsive">
							{this.state.loading ? (
								<BarLoader
									css={override}
									size={isMobile ? 20 : 50}
									color={"#4d7c8a"}
									loading={true}
								/>
							) : (
								<table className="table table-bordered">
									<thead>
										<tr>
											<th scope="col">Curso</th>
											{!isMobile && <th scope="col">Cant. personas</th>}
											<th scope="col">Precio</th>
										</tr>
									</thead>
									<tbody>
										{course != null && (
											<tr>
												<td className="product-name">{course.title}</td>
												{!isMobile && (
													<td className="">{this.state.amountOfPeople}</td>
												)}
												<td className="">
													{course.isFree
														? "GRATUITO"
														: this.props.coupon?.type == COUPON_TYPES.NO_COST
															? "PAGO REALIZADO"
															: this.props.coupon?.type == COUPON_TYPES.INVITATION
																? "SIN COSTO"
																: isMobile
																	? `${this.state.amountOfPeople} x ${price}`
																	: `${course?.currency} ${price}`}
												</td>
											</tr>
										)}
										{this.props.coupon != null &&
											this.props?.coupon?.type != COUPON_TYPES.NO_COST &&
											this.props?.coupon?.type != COUPON_TYPES.INVITATION && (
												<tr>
													<td className="product-name">
														{this.props.coupon.code}
													</td>
													{!isMobile && <td className=""></td>}
													<td>{`- ${this.props.coupon.percentage}%`}</td>
												</tr>
											)}
										<tr>
											<td className="total-price">
												<span>TOTAL</span>
											</td>

											{!isMobile && (
												<td className="product-subtotal">
													<span className="subtotal-amount"></span>
												</td>
											)}

											<td className="product-subtotal">
												<span className="subtotal-amount">
													{course.currency}{" "}
													{this.props.coupon?.type == COUPON_TYPES.NO_COST
														? 0
														: totalAmount}
												</span>
											</td>
										</tr>
										<tr></tr>
									</tbody>
								</table>
							)}
						</div>

						{!this.state?.course?.isFree && !this.state.loading && (
							<div style={{ marginTop: "10px" }}>
								<div>
									<img src={visa} style={{ height: "35px" }} />
									<img src={master} style={{ height: "35px" }} />
									<img src={oca} style={{ height: "35px" }} />
									<img src={amex} style={{
										height: '28px',
										width: '38px',
										marginLeft: '4px',
										borderRadius: '3px',
										border: '0.5px solid gray'
									}} />
									<img src={diners} style={{
										height: '28px',
										padding: '2px 0px',
										width: '38px',
										marginLeft: '4px',
										borderRadius: '3px',
										border: '0.5px solid gray'
									}} />
								</div>
								<p
									style={{
										fontSize: "12px",
										marginLeft: "5px",
										marginBottom: "5px",
									}}
								>
									Trabajamos con las tarjetas de crédito y débito <strong>VISA</strong>,
									<strong> MASTERCARD</strong>,<strong> American Express</strong>, <strong> Diners</strong>  y <strong>OCA</strong>{" "}. Hasta 12 cuotas <strong>sin recargo</strong>.
								</p>
								<p
									style={{
										fontSize: "16px",
										marginLeft: "5px",
										marginTop: "5px",
									}}
								>
									Por transferencia bancaria{" "}
									<a href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20Transferencia%20" style={{ color: "#184d5e", fontWeight: 'bold' }}>
										consulte aquí.
									</a>
								</p>
							</div>
						)}

						<div
							className={
								!disabled ? "payment-box" : "payment-box payment-box-disabled"
							}
						>
							{disabled ? (
								<button className={["default-btn", "green"].join(" ")}>
									Confirmar inscripción
								</button>
							) : !this.state.loadingSuscriptionStatus ? (
								this.state.course && this.state.course.isFree ? (
									<button
										className={["default-btn", "green"].join(" ")}
										onClick={this.checkIfAllowedFreeFlow}
									>
										Confirmar inscripción
									</button>
								) : this.props.coupon &&
									this.props.coupon.type == COUPON_TYPES.NO_COST ? (
									<>
										<button
											className={["default-btn", "green"].join(" ")}
											onClick={this.checkIfAllowedNoCostFlow}
										>
											Confirmar inscripción
										</button>
									</>
								) : this.props.coupon &&
									this.props.coupon.type == COUPON_TYPES.INVITATION ? (
									<>
										<button
											className={["default-btn", "green"].join(" ")}
											onClick={this.checkIfAllowedInvitationFlow}
										>
											Confirmar inscripción
										</button>
									</>
								) : !this.state.siemprePagoReady ? (
									<>
										<button
											className={["default-btn", "green"].join(" ")}
											onClick={this.checkIfAllowedPaymentFlow}
										>
											Confirmar inscripción
										</button>
									</>
								) : (
									!isMobile ? (
										<MercadoPago paymentCallback={this.prepareMPagoInfo} mercadoNumber={uuidv4()} />
									) : (
										<p>
											Debes ingresar desde una computadora para realizar el
											pago, lo sentimos.
										</p>
									)
								)
							) : (
								<ClipLoader
									css={override}
									size={isMobile ? 20 : 50}
									color={"#4d7c8a"}
									loading={true}
								/>
							)}
						</div>
						{disabled && (
							<p
								style={{
									fontSize: "12px",
									fontWeight: "bold",
									textAlign: "center",
									marginTop: "5px",
									color: "red",
								}}
							>
								{" "}
								Atención:
								<i
									style={{
										color: "black",
									}}
								>
									{" "}Debe completar los campos{" "}
									<span style={{ color: "red" }}>*</span> requeridos.{" "}
								</i>
							</p>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		coupon: state.coupon,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addSingleOrder: (order) => {
			dispatch(addSingleOrder(order));
		},
		editCourse: (course) => {
			dispatch(editCourse(course));
		},
		removeCoupon: () => {
			dispatch(removeCoupon());
		},
		addMercadoPagoItems: (items) => {
			dispatch(addMercadoPagoItems(items));
		},
		removeMercadoPagoItems: () => {
			dispatch(removeMercadoPagoItems());
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
);
