import React, { Component } from "react";
import { Link } from "react-router-dom";
import video_1 from "../../videos/course_2.mp4";
import video_2 from "../../videos/course_1.mp4";
import photo_1 from "../../videos/course_2-photo.png";
import photo_2 from "../../videos/course_1-photo.png";
import { isMobile } from "../../helpers/generic";
import dateformat from "dateformat";
import ReactPlayer from 'react-player/lazy'

import { css } from "@emotion/core";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

const regularStyle = {
	margin: "0px",
	color: "white",
	paddingRight: "5px",
	paddingLeft: "5px",
	color: "white",
	backgroundColor: "#fff9f933",
};

const mobileStyle = {
	margin: "15px 5px 0px 0px",
	color: "white",
	paddingLeft: "2px",
	paddingRight: "2px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	backgroundColor: "#fff9f933",
};

class CoursesGridFour extends Component {
	constructor(props) {
		super(props);
		this.state = {
			courses: null,
			orderBy: "asc",
		};
	}

	comingSoon = (course) => {
		let allHaveDates = true;
		let noDatesSet = false;

		if (course?.modules?.length > 0) {
			course.modules.forEach((m) => {
				if (!m.date) {
					allHaveDates = false;
				}
			});
		}

		noDatesSet =
			!course ||
			(course && !course.modules) ||
			!course?.modules?.length > 0 ||
			!allHaveDates;

		return noDatesSet;
	};

	getCourseDates = (course) => {
		let dates = [];
		course.modules &&
			course.modules.length > 0 &&
			course.modules.map((a) => {
				const oldDate = a.date && a.date.length ? a.date.split("-") : null;

				if (oldDate && oldDate.length > 0) {
					const newDate = oldDate.slice(1, 3);
					newDate.push(oldDate[0]);
					let newest = new Date();
					newest.setDate(newDate[1]);
					newest.setMonth(newDate[0] - 1);
					newest.setFullYear(newDate[2]);
					// let date = new Date(newDate);
					// console.log(newDate, date, newest)

					let formatted = dateformat(newest, "d/mm");
					dates.push(formatted);
				}
			});
		return dates.join(", ");
	};

	render() {
		const mobile = isMobile();
		let sortedCourses = this.props.courses;
		if (sortedCourses && sortedCourses.length > 0) {
			//OLD SORT:
			// sortedCourses = this.props.courses.sort((p1, p2) => {
			// 	if (p1.modules?.length > 0 && p2.modules?.length > 0) {
			// 		if (p1.modules[0]?.date && !p2.modules[0]?.date) {
			// 			return -1;
			// 		} else if (!p1.modules[0]?.date && p2.modules[0]?.date) {
			// 			return 1;
			// 		} else {
			// 			return (
			// 				new Date(p1.modules[0]?.date) - new Date(p2.modules[0]?.date)
			// 			);
			// 		}
			// 	} else {
			// 		if (p1.modules?.length > 0 && !p2.modules?.length > 0) {
			// 			return -1;
			// 		} else if (!p1.modules?.length > 0 && !p2.modules?.length > 0) {
			// 			return 1;
			// 		}
			// 	}
			// });

			//NEW SORT:
			sortedCourses = this.props.courses.sort((p1, p2) => {
				if (p1.priority && !p2.priority) {
					return -1;
				} else if (!p1.priority && p2.priority) {
					return 1;
				} else {
					return p2.priority - p1.priority;
				}
			});
		}
		return (
			<section className="courses-area pb-70">
				<div className="container">
					<div className="row row-justify-center">
						{sortedCourses && sortedCourses.length > 0 ? (
							sortedCourses.map((b, index) => {
								return (
									<div className="col-lg-6 col-md-6 col-sm-6">
										<div className="single-categories-box">
											{mobile ? (
												<img
													className="course-presentation"
													src={index % 2 == 0 ? photo_1 : photo_2}
												/>
											) : (index % 2 == 0 ? (

												<ReactPlayer
													playing={true}
													className="course-presentation"
													muted={true}
													loop={true}
													playsinline={true}
													controls={false}
													volume={0}
													url={video_1} />
											) : (
												<ReactPlayer
													playing={true}
													className="course-presentation"
													muted={true}
													loop={true}
													playsinline={true}
													controls={false}
													volume={0}
													url={video_2} />
											))}

											<div className="content text-white">
												{b.header && (
													<span style={{ marginBottom: '22px' }}>
														<strong style={{ color: "#E6B966" }}>
															{b.header.toUpperCase()}
														</strong>
													</span>
												)}
												<span>
													{this.comingSoon(b) ? (
														<strong>PRÓXIMAMENTE</strong>
													) : b.modules?.length > 1 ? (
														`${b.modules?.length} módulos / ${b.totalDuration == 1
															? `${b.totalDuration} hora`
															: `${b.totalDuration} horas`
														}`
													) : b.modules?.length == 1 ? (
														`${b.modules?.length} módulo / ${b.totalDuration == 1
															? `${b.totalDuration} hora`
															: `${b.totalDuration} horas`
														}`
													) : (
														`${b.totalDuration == 1
															? `${b.totalDuration} hora`
															: `${b.totalDuration} horas`
														}`
													)}
												</span>

												<h3>{b.title}</h3>
												<div className="description block-with-text">
													{b.shortDescription}
												</div>
												<div
													className="row"
													style={{
														display: "flex",
														justifyContent:
															!mobile && !this.comingSoon(b)
																? "space-between"
																: "flex-end",
													}}
												>
													{!this.comingSoon(b) && (
														<p style={mobile ? mobileStyle : regularStyle}>
															<strong>
																{b && b.modules?.length > 1
																	? `Fechas: `
																	: `Fecha: `}
															</strong>
															{this.getCourseDates(b)}
														</p>
													)}

													{(!mobile || this.comingSoon(b)) && (
														<Link to={"/cursos/" + b.slug}>
															<a className="default-btn">Leer más</a>
														</Link>
													)}
												</div>
											</div>
											<Link to={"/cursos/" + b.slug}>
												<a
													className={mobile ? "mobile-link-btn" : "link-btn"}
												></a>
											</Link>
										</div>
									</div>
								);
							})
						) : (
							<div className="no-blogs">No hay cursos. </div>
						)}
					</div>
				</div>
			</section>
		);
	}
}

export { CoursesGridFour };
