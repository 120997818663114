import React, { Component } from "react";
import dateformat from "dateformat";

class ModuleModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			module: this.props.module,
		};
	}

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	render() {
		const { module } = this.props;
		let content = null;
		if (module?.content && module?.content?.length) {
			content = module.content.map((content, i) => {
				return (
					<div class="module-modal-column">
						<h6>{content.title}</h6>{" "}
						{content.text != null && content.text != "" && (
							<p>{content.text}</p>
						)}
					</div>
				);
			});
		}
		let formatted = "";
		const oldDate =
			module?.date && module?.date.length ? module.date.split("-") : null;
		if (oldDate && oldDate.length > 0) {
			const newDate = oldDate.slice(1, 3);
			newDate.push(oldDate[0]);

			let newest = new Date();
			newest.setDate(newDate[1]);
			newest.setMonth(newDate[0]-1)
			newest.setFullYear(newDate[2])
			// let date = new Date(newDate);
			// console.log(newDate, date, newest)
			formatted = dateformat(newest, "dd/mm");
		}
		var hour = module.startTime + " - " + module.endTime;

		return (
			<div className={`modal fade productsQuickView ${this.props.active}`}>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="row align-items-center">
							<div className="col-lg-12 col-md-12">
								<div className="products-content">
									<h3 className="capitalized">{module.title}</h3>
									<p className="break-description">{module.mainDescription}</p>
									<div class="module-modal-row">
										<h5>Fecha:</h5> <h6>{module.date ? formatted : 'A definir'}</h6>
									</div>
									<div class="module-modal-row">
										<h5>Hora:</h5> <h6>{module.startTime ? hour : 'A definir'}</h6>
									</div>
									{content ? (
										<>
											<div class="module-modal-row">
												<h5>Contenido</h5>
											</div>
											{content}
										</>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export { ModuleModal };