import React, { Component } from "react";
import { Link } from "react-router-dom";

class OfferStyleOne extends Component {
	render() {
		return (
			<section className="offer-area bg-image1 ptb-100 jarallax">
				<div className="container-fluid">
					<div className="row">
						<div className="offer-content">
							<div className="about-inner-area">
								<div className="row">
									<div className="col-lg-6 col-sm-6">
										<div className="about-text">
											<h3 className="centered">Propuesta de valor</h3>

											<ul className="features-list">
												<li>
													<i className="bx bx-check"></i> Taller presentado con
													un formato dinámico, basado en el concepto de{" "}
													<strong>“learning by doing” </strong>y en el análisis
													de negociaciones por el método del caso.
												</li>
												<li>
													<i className="bx bx-check"></i> Modelo probado a
													partir de la investigación y de su aplicación práctica
													durante <strong>más de 20 años</strong>, incluso
													aplicado a situaciones extremas.
												</li>
												<li>
													<i className="bx bx-check"></i> El taller está
													diseñado para transferir esas{" "}
													<strong>experiencias exitosas </strong>de negociación
													potenciando la integración de las herramientas
													propuestas.
												</li>
												<li>
													<i className="bx bx-check"></i> Los talleres en
													formato <strong>“Presencial a Distancia”</strong> a
													través de Zoom han sido diseñados con la misma
													dinámica, role-playings, negociaciones en equipos y
													discusión plenaria aplicando el método del caso.
												</li>
											</ul>
										</div>
									</div>

									<div className="col-lg-6 col-sm-6">
										<div className="about-text">
											<ul className="features-list-special">
												<li>
													<i className="bx bx-check"></i> Modelo de{" "}
													<strong>amplio espectro</strong>, aplicable 360
													grados: trasciende el dominio de las negociaciones
													tradicionales.
												</li>
												<li>
													<i className="bx bx-check"></i> Enfoque basado en un{" "}
													<strong>análisis estratégico </strong>del proceso de
													negociación, para proteger la creación de valor y
													asegurar el máximo resultado.
												</li>
												<li>
													<i className="bx bx-check"></i> Metodología probada:
													los participantes, en todos los casos, cualquiera sea
													su nivel de base (incluso los experimentados){" "}
													<strong>mejoran sus habilidades negociadoras</strong>{" "}
													apenas comienzan a aplicar el modelo de negociación
													propuesto en el taller.
												</li>
												<li>
													<i className="bx bx-check"></i> Se entrega <strong>Certificado de cumplimiento</strong>, ampliamente reconocido.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default OfferStyleOne;
