import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { firebase } from "../../../firebase";
import { withRouter } from "react-router-dom";

const containerStyle = css({
	position: "relative",
});

class EditVideoModal extends Component {
	state = {
		modal: false,
		videoLink: "",
	};

	componentDidMount() {
		const { video } = this.props;
		if (video) {
			this.setState({
				videoId: video.id,
				videoLink: video.videoLink,
			});
		}
	}

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	editVideo = (e) => {
		e.preventDefault();
		let { videoLink, videoId } = this.state;

		const institutionalVideo = {
			videoLink: videoLink,
		};

		if (videoLink != "") {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("institutionalVideos");
			dbOrderRef
				.doc(videoId)
				.update(institutionalVideo)
				.then(() => {
					toast.success("El video fue editado exitosamente.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						window.location.reload();
					}, 3000);
					this.closeModal();
					this.resetForm();
				});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer className={containerStyle} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Editar Video Institucional</h3>
							<label>
								*Si existía un video, se sobreescribirá la información. Los
								campos con <strong>*</strong> son obligatorios. En caso de no
								haber video no se mostrará la sección.
							</label>
							<form onSubmit={this.editVideo}>
								<div className="form-group">
									<label>
										Link a video <strong>*</strong> (link completo público a video en
										youtube. Ej:
										https://www.youtube.com/watch?v=1AyxdYP1SNc{" "}
									</label>
									<input
										type="text"
										className="form-control"
										value={this.state.videoLink}
										onChange={(e) =>
											this.setState({ videoLink: e.target.value?.trim() })
										}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Editar Video
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(EditVideoModal);
