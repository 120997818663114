import React, { Component } from 'react';
import TopHeader from '../components/Layouts/TopHeader';
import ColoredNavbar from '../components/Layouts/ColoredNavbar';
import CourseDetailsStyleOne from '../components/Courses/CourseDetailsStyleOne';
import FacilitySlider from '../components/Common/FacilitySlider';
import Footer from '../components/Layouts/Footer';

class CoursesTypeOne extends Component {
    render() {
        return (
            <React.Fragment>
                <ColoredNavbar />
                <CourseDetailsStyleOne />
                <Footer />
            </React.Fragment>
        );
    }
}

export { CoursesTypeOne };