import React, { useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { loginUser } from "../store/actions/cartActions";
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import Footer from "../components/Layouts/Footer";
import { ALL_COUNTRIES } from "../constants/countries";
import { css } from "@emotion/core";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import backendUrl from "../constants/urls";
import { encodeUser } from "../methods/api-calls";

const override = css`
	display: flex;
	justify-content: center;
	margin: auto;
	margin-bottom: 50px;
	margin-top: 50px;
`;

function Signup(props) {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [country, setCountry] = useState("Uruguay");
	const [error, setError] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	if (props.user) {
		return <Redirect to="/" />;
	}

	const handleRegister = async (e) => {
		setLoading(true);
		e.preventDefault();

		if (
			firstName &&
			firstName.trim() &&
			lastName &&
			lastName.trim() &&
			password &&
			password.trim() &&
			country &&
			country.trim() &&
			phone &&
			phone.trim() &&
			email &&
			email.trim()
		) {
			const body = {
				user: {
					firstName,
					lastName,
					password,
					country,
					phone,
					email,
				},
			};
			try {
				const res = await axios.post(
					`${backendUrl}api/mailer/create-single-user`,
					body,
					{
						headers: encodeUser(),
					}
				);

				if (res.data) {
					const { user } = res.data;
					props.loginUser(user);
					toast.success("Se ha creado tu cuenta exitosamente.", {
						position: "bottom-left",
						autoClose: 1000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});

					setTimeout(function () {
						props.history.push("/");
					}, 1500);
				}
			} catch (e) {
				if (e.response) {
					const { data } = e.response;
					setError(data.message);
				} else {
					setError("Ha ocurrido un error.");
				}
			}
		} else {
			setError("Todos los campos son requeridos.");
		}
		setLoading(false);
	};

	var isMobile = false;
	// device detection
	if (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
			navigator.userAgent
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			navigator.userAgent.substr(0, 4)
		)
	) {
		isMobile = true;
	}

	return (
		<React.Fragment>
			<ColoredNavbar />

			<section className="signup-area pt-150 pb-100">
				<div className="container">
					<div className="signup-content">
						<h2>Crear una cuenta</h2>

						<form onSubmit={handleRegister} className="signup-form">
							<div className="form-group">
								<label>
									Nombre <span className="required">*</span>
								</label>
								<input
									type="text"
									className="form-control"
									id="fname"
									name="fname"
									onChange={({ target }) => setFirstName(target.value)}
									value={firstName}
								/>
							</div>

							<div className="form-group">
								<label>
									Apellido <span className="required">*</span>
								</label>
								<input
									type="text"
									className="form-control"
									id="lname"
									onChange={({ target }) => setLastName(target.value)}
									name="lname"
									value={lastName}
								/>
							</div>

							<div className="form-group">
								<label>
									Email <span className="required">*</span>
								</label>
								<input
									type="email"
									className="form-control"
									id="name"
									name="name"
									onChange={({ target }) => setEmail(target.value)}
									value={email}
								/>
							</div>

							<div className="form-group">
								<label>
									País <span className="required">*</span>
								</label>
								<div className="select-box">
									<select
										onChange={({ target }) => setCountry(target.value)}
										className="form-control"
										name="country"
										value={country}
									>
										{ALL_COUNTRIES.map((z, i) => (
											<option key={i} value={z.name}>
												{z.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className="form-group">
								<label>
									Celular <span className="required">*</span>
								</label>
								<input
									type="text"
									className="form-control"
									id="lname"
									onChange={({ target }) =>
										target.value == "" || parseInt(target.value)
											? setPhone(target.value)
											: ""
									}
									name="lname"
									value={phone}
								/>
							</div>

							<div className="form-group">
								<label>
									Contraseña <span className="required">*</span>
								</label>
								<input
									type="password"
									className="form-control"
									onChange={({ target }) => setPassword(target.value)}
									id="password"
									name="password"
									value={password}
								/>
							</div>

							<p className="login-error">{error}</p>
							{loading && (
								<BeatLoader
									css={override}
									size={isMobile ? 50 : 50}
									color={"#4d7c8a"}
									loading={true}
								/>
							)}

							<button type="submit" className="default-btn">
								Crear cuenta
							</button>

							<div className="text-center">
								<a className="return-store">
									Ya tienes una cuenta? <Link to="login">Inicia sesión</Link>
								</a>
							</div>
						</form>
					</div>
				</div>
			</section>

			<Footer />
		</React.Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginUser: (user) => {
			dispatch(loginUser(user));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
