import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import noPhoto from "../../../images/products/noImage.png";


class ViewProfessorModal extends Component {
	state = {
		modal: false,
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	render() {
		let { professor } = this.props;
		return (
			<React.Fragment>
				{professor && (
					<div
						className={`admin-product-modal view-product ${this.props.active}`}
					>
						<div className="modal-innter-content">
							<button type="button" className="close" onClick={this.closeModal}>
								<span aria-hidden="true">
									<i className="bx bx-x"></i>
								</span>
							</button>

							<div className="modal-body">
								<h3>Ver Profesor</h3>

								<div className="view-product-content">
									<div className="row ">
										<div className="col-lg-6">
											<div className="products-details-desc">
												<h3>{professor.fullName}</h3>
												<ul className="products-info">
													<li>
														<span>Linkedin link:</span>
														<Link to="#">
															<a onClick={(e) => e.preventDefault()}>
																{professor.linkedinLink || "-"}
															</a>
														</Link>
													</li>
												</ul>
												<p>{`Descripción: ${!professor.personDescription ? 'sin desripción.' : '' }`}</p>
												{professor.personDescription && (
													<textarea
														className="non-resizable-text special-textarea content-paragraph"
														value={professor.personDescription}
														disabled
													/>
												)}

											</div>
										</div>

										<div className="col-lg-6">
											<div className="product-img">
												<img
													src={professor.profilePicture ? professor.profilePicture : noPhoto}
													className="main-image"
													alt="image"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		courses: state.courses,
	};
};

export default connect(mapStateToProps, null)(ViewProfessorModal);
