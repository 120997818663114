import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
const config = {
	apiKey: "AIzaSyAtzIVxuJoKUxuf8WqHGM_P52CG0LGqkZk",
	authDomain: "g-vallone.firebaseapp.com",
	databaseURL: "https://g-vallone.firebaseio.com",
	projectId: "g-vallone",
	storageBucket: "g-vallone.appspot.com",
	messagingSenderId: "494982162404",
	appId: "1:494982162404:web:9665ebd54dfd8b68b809f1",
	measurementId: "G-4PR6FELMFW",
	// apiKey: "AIzaSyDf93gPnDXY2YLVYTVizSCLtIueJvE8oqI",
	// authDomain: "g-vallone-front-dda43.firebaseapp.com",
	// databaseURL: "https://g-vallone-front-dda43.firebaseio.com",
	// projectId: "g-vallone-front-dda43",
	// storageBucket: "g-vallone-front-dda43.appspot.com",
	// messagingSenderId: "469433166169",
	// appId: "1:469433166169:web:a00375f49da5e1bcd14448",
	// measurementId: "G-Q5EL2LFJ2K",
};
if (!firebase.apps.length) {
	firebase.analytics(firebase.initializeApp(config));
	firebase.firestore().settings({ experimentalForceLongPolling: true });
}
const auth = firebase.auth();
export { auth, firebase };
