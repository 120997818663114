import React, { Component } from 'react';
import { Link } from "react-router-dom";

class OfferArea extends Component {
    render() {
        return (
            <section className="offer-area covid-19-offer bg-image3 pb-100 pt-100 jarallax">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 offset-lg-7 offset-md-6">
                            <div className="offer-content-box">
                                <h3>Metodología online o presencial</h3>
                                <Link to="/contact">
                                    <a className="default-btn">Consultar</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default OfferArea;