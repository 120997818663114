import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class CourseDetailsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course: this.props.course
        }
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs-item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove("fadeInUp");
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        document.getElementById(tabNmae).className += " fadeInUp animated";
        evt.currentTarget.className += "current";
    }

    render() {
        const { course } = this.state;
        if(!course) {
            return <Redirect to="/"/>;
        }
        return (
            <div className="tab products-details-tab">
                <ul className="tabs">
                    <li
                        onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab1') }}
                        className="current"
                    >
                        <a href="#">
                            <div className="dot"></div> DESCRIPCIÓN
                        </a>
                    </li>

                    <li
                        onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab2') }}
                    >
                        <a href="#">
                            <div className="dot"></div> OBJETIVOS
                        </a>
                    </li>

                    <li
                        onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab3') }}
                    >
                        <a href="#">
                            <div className="dot"></div> CONTENIDO
                        </a>
                    </li>

                    <li
                        onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab4') }}
                    >
                        <a href="#">
                            <div className="dot"></div> METODOLOGÍA
                        </a>
                    </li>
                    {course.targetPublic != null && course.targetPublic != "" &&
                        <li
                            onClick={(e) => { e.preventDefault(); this.openTabSection(e, 'tab5') }}
                        >
                            <a href="#">
                                <div className="dot"></div> DIRIGIDO A
                    </a>
                        </li>
                    }

                </ul>

                <div className="tab-content">
                    <div id="tab1" className="tabs-item">
                        <div className="products-details-tab-content">
                            <p className="content-paragraph">{course.description || 'No hay información disponible'}</p>
                        </div>
                    </div>

                    <div id="tab2" className="tabs-item">
                        <div className="products-details-tab-content">
                            <div className="table-responsive">
                                <p className="content-paragraph">{course.objectives  || 'No hay información disponible'}</p>
                            </div>
                        </div>
                    </div>

                    <div id="tab3" className="tabs-item">
                        <div className="products-details-tab-content">
                            <div className="table-responsive">
                                <p className="content-paragraph">{course.content  || 'No hay información disponible'}</p>
                            </div>
                        </div>
                    </div>

                    <div id="tab4" className="tabs-item">
                        <div className="products-details-tab-content">
                            <p className="content-paragraph">{course.methodology  || 'No hay información disponible'}</p>
                        </div>
                    </div>
                    {course.targetPublic != null && course.targetPublic != "" &&
                        <div id="tab5" className="tabs-item">
                            <div className="products-details-tab-content">
                                <p className="content-paragraph">{course.targetPublic  || 'No hay información disponible'}</p>
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default CourseDetailsTab;
