import React, { Component } from "react";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/Layouts/Footer";
import ConsultoriaProducts from "../components/consultorias/ConsultoriaProducts";
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import BannerWithText from "../components/Common/BannerWithText";
import BannerWithTextAlt from "../components/Common/BannerWithTextAlt";

import gab_1 from "../images/consultoria/meeting.jpg";
import gab_3 from "../images/consultoria/hands.jpg";
import gab_2 from "../images/about/gabriel_2.jpg";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

class Consultorias extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadedImages: [false, false, false, false],
		};
	}

	handleUpload(index) {
		const { loadedImages } = this.state;
		let copy = [...loadedImages];
		copy[index] = true;
		this.setState({ loadedImages: [...copy] });
	}

	render() {
		const { loadedImages } = this.state;
		const allIsLoaded =
			loadedImages?.length &&
			loadedImages[0] &&
			loadedImages[1] &&
			loadedImages[2] &&
			loadedImages[3];

		var isMobile = false;
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}
		return (
			<React.Fragment>
				<ColoredNavbar />
				<PageBanner
					pageTitle="CONSULTORÍA"
					homePageUrl="/"
					homePageText="Home"
					activePageText="Consultoría"
				/>

				<section className="customer-service-area ptb-100">
					<div className="container">
						<div className="customer-service-content">
							<h3 style={{ fontSize: "30px", marginBottom: "50px" }}>
								Servicio de <i>consultoría</i>
							</h3>
							{!allIsLoaded && (
								<BeatLoader
									css={override}
									size={isMobile ? 50 : 100}
									color={"#4d7c8a"}
									loading={!allIsLoaded}
								/>
							)}
							<div style={{ display: !allIsLoaded ? "none" : "block" }}>
								<BannerWithTextAlt onImageLoad={() => this.handleUpload(0)} />

								<br></br>
								<p>
									La <strong>metodología de base</strong> utilizada ha sido
									aplicada con éxito desde hace <strong>más de 20 años</strong>{" "}
									en procesos de M&amp;A, diseño de propuestas de valor,
									estrategia comercial, determinación de la estructura
									financiera óptima de un proyecto, valoración de negocios,
									dirección de recursos humanos y política de empresa.
								</p>

								<div className="row ptb-100 align-items-center special-ptb">
									<div className="col-lg-4 col-sm-6">
										<div className="about-text">
											<img
												src={gab_1}
												className="about-banner-image"
												onLoad={() => this.handleUpload(1)}
											/>
										</div>
									</div>

									<div className="col-lg-4 col-sm-6">
										<div className="about-text">
											<img
												src={gab_2}
												className="about-banner-image"
												onLoad={() => this.handleUpload(2)}
											/>
										</div>
									</div>

									<div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
										<div className="about-text">
											<img
												src={gab_3}
												className="about-banner-image"
												onLoad={() => this.handleUpload(3)}
											/>
										</div>
									</div>
								</div>

								<p>
									El servicio de consultoría en{" "}
									<strong>negociación y toma de decisiones</strong> se presenta
									en tres variantes:
								</p>
								<ul className="features-list">
									<li>
										<h6 style={{ fontSize: "16px" }}>
											<strong>a) </strong> Asesoramiento en procesos puntuales
											que persiguen un determinado fin.
										</h6>
									</li>
									<li>
										<h6 style={{ fontSize: "16px" }}>
											<strong>b) </strong>Como &quot;director externo&quot;
											integrando órganos de dirección y control para asegurar el
											cumplimiento eficiente de objetivos organizacionales.
										</h6>
									</li>
									<li>
										<h6 style={{ fontSize: "16px" }}>
											<strong>c) </strong>Coaching ejecutivo para acompañar a
											gerentes de área y mandos medios en el desarrollo de su
											carrera profesional dentro de la empresa.
										</h6>
									</li>
								</ul>
								<br></br>

								<p>
									Más allá de mi experiencia personal en cargos directivos y de
									la formación de base como contador y economista, la
									consultoría se brindan en el marco de modelos probados de
									negociación y decisiones estratégicas desarrollados en las
									mejores universidades especializadas en el tema.
								</p>

								<BannerWithText />

								<h3
									style={{
										fontSize: "30px",
										marginBottom: "20px",
										marginTop: "100px",
									}}
								>
									Consultoría focalizada en soluciones <i>(CFS):</i>
								</h3>

								<p>
									El modelo de consultoría de gestión centrado en la solución de
									problemas, apunta a resolver conflictos de manera eficiente,
									desarrollar relaciones interpersonales productivas y mejorar
									la calidad de las decisiones, con el objetivo de optimizar los
									resultados de cualquier proceso.
								</p>
								<ConsultoriaProducts />
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</React.Fragment>
		);
	}
}

export default Consultorias;
