import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { firebase } from "../../../firebase";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import backendUrl from "../../../constants/urls";
import { editCourse, resetCart } from "../../../store/actions/cartActions";
import { css } from "glamor";
import { encodeUser } from "../../../methods/api-calls";
import { convertToSlug } from "../../../utils/id";

const containerStyle = css({
	position: "relative",
});

class EditCourseModal extends Component {
	state = {
		id: "",
		modal: false,
		imageUrls: [],
		newImageUrls: [],
		notAvailable: "",
		newImageHoverUrl: "",
		discountReason: "",
		imageCount: 0,
		imageHoverUrl: "",
		progress: 0,
		header: "",
		title: "",
		description: "",
		shortDescription: "",
		currency: "",
		targetPublic: "",
		objectives: "",
		methodology: "",
		zoomLink: "",
		content: "",
		offer: false,
		isFree: false,
		totalDuration: 0,
		oldPrice: 0,
		oldPriceForThree: 0,
		oldPriceForTwo: 0,
		category: null,
		professor: null,
		newPrice: 0,
		newPriceForThree: 0,
		newPriceForTwo: 0,
		maxParticipants: 0,
		hasMaxParticipants: false,
		quantity: 0,
		discount: false,
		discountOff: 0,
		onSale: false,
		previewImages: [],
		allCategories: [],
		previewImageHover: null,
		priority: 0,
		certificateContent: "",
	};

	baseState = this.state;

	componentDidMount() {
		const { course } = this.props;
		if (course) {
			const oldImages = [];
			if (course.imageUrl) {
				oldImages.push(course.imageUrl);
			}
			if (course.imageUrlSecond) {
				oldImages.push(course.imageUrlSecond);
			}
			if (course.imageUrlThird) {
				oldImages.push(course.imageUrlThird);
			}
			if (course.imageUrlFourth) {
				oldImages.push(course.imageUrlFourth);
			}
			this.setState({
				...course,
				imageUrls: oldImages,
				previewImages: oldImages,
			});
			const db = firebase.firestore();
			let dbOrderRef = db.collection("categories");
			let categoriesArray = [];
			dbOrderRef
				.get()
				.then((res) => {
					// console.log(res)
					res.forEach((doc) => {
						let categoriesObj = doc.data();
						categoriesObj.id = doc.id;
						categoriesArray.push(categoriesObj);
					});
					this.setState({
						allCategories: categoriesArray,
					});
				})
				.catch((err) => {
					console.log("error", err);
				});

			dbOrderRef = db.collection("professors");
			let professorsArray = [];
			dbOrderRef
				.get()
				.then((res) => {
					// console.log(res)
					res.forEach((doc) => {
						let professorsObj = doc.data();
						professorsObj.id = doc.id;
						professorsArray.push(professorsObj);
					});
					this.setState({
						allProfessors: professorsArray,
					});
				})
				.catch((err) => {
					console.log("error", err);
				});
		} else {
			this.closeModal();
		}
	}

	handleSelect = (id, array, key) =>
		this.setState({
			[key]: array.find((e) => e.id == id)
				? array.find((e) => e.id == id)
				: null,
		});

	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	handleChange = (e) => {
		if (this.state.imageCount < 4) {
			if (e.target.files[0]) {
				let image = e.target.files[0];
				let reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = () => {
					this.setState({
						previewImages:
							this.state.imageCount === 0 //Sobreescribir
								? [reader.result]
								: [...this.state.previewImages, reader.result],
					});
				};
				this.handleUpload(image);
			}
		}
	};

	handleUpload = (image) => {
		const data = new FormData();
		if (image) {
			const authHeader = encodeUser();
			data.append("productImage", image, image.name);
			axios
				.post(`${backendUrl}api/upload`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
						...authHeader,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Tamaño máximo: 20MB");
							} else {
								// If not the given file type
								alert(response.data.error);
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								newImageUrls:
									this.state.imageCount == 0
										? [fileName.location]
										: [...this.state.newImageUrls, fileName.location],
								imageCount: this.state.imageCount + 1,
							});
							console.log("fileName", fileName.location);
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error, "red");
				});
		} else {
			// if file not selected throw error
			alert("Porfavor seleccione una foto");
		}
	};

	parseToBoolean = (val) => {
		if (typeof val === "boolean") {
			return Boolean(val);
		} else if (val == "true") {
			return Boolean(true);
		} else {
			return Boolean(false);
		}
	};

	editCourse = (e) => {
		e.preventDefault();
		let {
			imageHoverUrl,
			newImageUrls,
			newImageHoverUrl,
			header,
			title,
			description,
			content,
			shortDescription,
			objectives,
			methodology,
			oldPrice,
			oldPriceForTwo,
			oldPriceForThree,
			imageUrls,
			newPrice,
			newPriceForTwo,
			newPriceForThree,
			currency,
			discount,
			discountOff,
			targetPublic,
			totalDuration,
			category,
			zoomLink,
			notAvailable,
			professor,
			offer,
			discountReason,
			id,
			onSale,
			hasMaxParticipants,
			priority,
			isFree,
			maxParticipants,
			certificateContent,
			slug,
		} = this.state;

		const thereAreImages = newImageUrls && newImageUrls.length > 0;
		const thereAreOldImages = imageUrls && imageUrls.length > 0;

		const course = {
			notAvailable: notAvailable,
			imageUrl: thereAreImages
				? newImageUrls[0]
				: thereAreOldImages
				? imageUrls[0]
				: "",
			imageUrlSecond:
				thereAreImages && newImageUrls.length > 1 ? newImageUrls[1] : "",
			imageUrlThird:
				thereAreImages && newImageUrls.length > 2 ? newImageUrls[2] : "",
			imageUrlFourth:
				thereAreImages && newImageUrls.length > 3 ? newImageUrls[3] : "",
			imageHoverUrl: newImageHoverUrl ? newImageHoverUrl : imageHoverUrl,
			header: header,
			title: title,
			description: description,
			content: content,
			objectives: objectives,
			category: category ? category : null,
			shortDescription: shortDescription,
			methodology: methodology,
			professor: professor ? professor : null,
			totalDuration: totalDuration,
			currency: currency,
			targetPublic: targetPublic,
			zoomLink: zoomLink,
			oldPrice: Number(oldPrice),
			isFree: this.parseToBoolean(isFree),
			oldPriceForTwo: Number(oldPriceForTwo),
			oldPriceForThree: Number(oldPriceForThree),
			newPrice: Number(newPrice),
			newPriceForTwo: Number(newPriceForTwo),
			newPriceForThree: Number(newPriceForThree),
			discount: this.parseToBoolean(discount),
			discountOff: Number(discountOff),
			maxParticipants: this.parseToBoolean(hasMaxParticipants)
				? Number(maxParticipants)
				: 0,
			quantity: Number(maxParticipants), //Then it has to decrement
			hasMaxParticipants: this.parseToBoolean(hasMaxParticipants),
			onSale: this.parseToBoolean(onSale),
			offer: this.parseToBoolean(onSale),
			discountReason: discountReason,
			priority: Number(priority),
			certificateContent: certificateContent,
			slug: convertToSlug(slug) || convertToSlug(title),
		};

		if (
			title != "" &&
			professor &&
			description &&
			((!isFree && newPrice && currency) || isFree)
		) {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("courses");
			dbOrderRef
				.doc(id)
				.update(course)
				.then(() => {
					this.props.editCourse(course);
					toast.success("Curso fue editado exitosamente.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						window.location.reload();
					}, 3000);
					this.closeModal();
					this.resetForm();
					this.props.resetCart();
				});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		let {
			previewImageHover,
			previewImages,
			allCategories,
			allProfessors,
		} = this.state;
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer className={containerStyle} />

					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Editar Curso</h3>
							<p>Todos los campos marcados (*) son requeridos</p>

							<form onSubmit={this.editCourse}>
								*Nota: si se sube al menos una foto nueva, se sobreescriben las
								viejas, de otra forma quedan las que estaban.
								<h4 className="title title-underline">
									Agregar imágenes aquí (opcional)
								</h4>
								<div className="form-group">
									<label>Subir las imagenes aquí (Hasta 4 fotos)</label>
									<div className="upload-img">
										<span>
											<i className="bx bxs-image-add"></i>
											Seleccionar
										</span>

										<input
											type="file"
											disabled={this.state.imageCount > 3}
											className="form-control-file"
											name="productImage"
											accept="image/*"
											onChange={this.handleChange}
										/>
									</div>

									{previewImages && previewImages.length > 0 ? (
										<div className="uploaded-img">
											{previewImages.map((e) => (
												<img src={e} alt="Image" className="img-thumbnail" />
											))}
										</div>
									) : null}
								</div>
								<h4 className="title title-underline">
									Agrega información del curso aquí
								</h4>
								<div className="form-group">
									<label>Encabezado</label>
									<p>Ej: "Taller"</p>
									<input
										type="text"
										className="form-control"
										value={this.state.header}
										onChange={(e) => this.setState({ header: e.target.value })}
									/>
								</div>
								<div className="form-group">
									<label>Título *</label>
									<p>Ej: Negociación Avanzada</p>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>
								<div className="form-group">
									<label>Url del curso</label>
									<input
										type="text"
										className="form-control"
										value={this.state.slug}
										onChange={(e) => this.setState({ slug: e.target.value })}
									/>
									<p>Previsualización: {convertToSlug(this.state.slug)}</p>
								</div>
								<div className="form-group">
									<label>
										Breve descripción (resumen para mostrar en Home)
									</label>
									<p>Máx 210 caracteres.</p>
									<textarea
										className="form-control"
										value={this.state.shortDescription}
										maxLength={210}
										onChange={(e) =>
											this.setState({ shortDescription: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Descripción *</label>
									<textarea
										className="form-control"
										value={this.state.description}
										onChange={(e) =>
											this.setState({ description: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Categoría</label>
									<p>Recuerda agregar categorías en el panel.</p>
									<select
										className="form-control"
										onChange={(e) =>
											this.handleSelect(
												e.target.value,
												this.state.allCategories,
												"category"
											)
										}
										value={this.state.category ? this.state.category.id : null}
									>
										{allCategories && allCategories.length > 0 && (
											<option value={null}>Sin categoría</option>
										)}
										{allCategories && allCategories.length > 0 ? (
											allCategories.map((cat) => (
												<option value={cat.id}>{cat.title}</option>
											))
										) : (
											<option disabled selected>
												No hay categorías ingresadas
											</option>
										)}
									</select>
								</div>
								<div className="form-group">
									<label>Profesor a cargo</label>
									<p>Recuerda agregar profesores en el panel.</p>
									<select
										className="form-control"
										onChange={(e) =>
											this.handleSelect(
												e.target.value,
												this.state.allProfessors,
												"professor"
											)
										}
										value={
											this.state.professor ? this.state.professor.id : null
										}
									>
										{allProfessors && allProfessors.length > 0 ? (
											allProfessors.map((p) => (
												<option value={p.id}>{p.fullName}</option>
											))
										) : (
											<option disabled selected>
												No hay profesores ingresados
											</option>
										)}
									</select>
								</div>
								<div className="form-group">
									<label>Objetivos</label>
									<textarea
										className="form-control"
										value={this.state.objectives}
										onChange={(e) =>
											this.setState({ objectives: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Contenido</label>
									<textarea
										className="form-control"
										value={this.state.content}
										onChange={(e) => this.setState({ content: e.target.value })}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Metodología</label>
									<textarea
										className="form-control"
										value={this.state.methodology}
										onChange={(e) =>
											this.setState({ methodology: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Dirigido a</label>
									<textarea
										className="form-control"
										value={this.state.targetPublic}
										onChange={(e) =>
											this.setState({ targetPublic: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Mensaje customizado en caso de agotado</label>
									<p>
										*El mensaje por defecto es "NO HAY 30
										caracteres)
									</p>
									<input
										type="text"
										className="form-control"
										value={this.state.notAvailable}
										onChange={(e) =>
											this.setState({ notAvailable: e.target.value })
										}
										maxLength={30}
									/>
								</div>
								<div className="form-group">
									<label>
										Mensaje customizado para el cuerpo del certificado
									</label>
									<p style={{ fontSize: "12px" }}>
										Nota: Máx 250 caracteres. Por defecto, el cuerpo contendrá
										el texto que se muestra debajo con los datos
										correspondientes.
									</p>
									<textarea
										className="form-control"
										placeholder="Ej: Ha asistido y cumplido con los requisitos del curso Negociación Estratégica dictado los días 17, 18,
										19 de Noviembre de 2020, extiendo el presente certificado en Montevideo el 30 de Noviembre de
										2020."
										value={this.state.certificateContent}
										maxLength={250}
										onChange={(e) =>
											this.setState({ certificateContent: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Total duración en horas</label>
									<input
										className="form-control"
										value={this.state.totalDuration}
										onChange={(e) =>
											this.setState({ totalDuration: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>¿Tiene capacidad de personas?</label>
									<select
										className="form-control"
										onChange={(e) =>
											this.setState({ hasMaxParticipants: e.target.value })
										}
										value={this.state.hasMaxParticipants}
									>
										<option value="false">No</option>
										<option value="true">Si</option>
									</select>
								</div>
								{(this.state.hasMaxParticipants == "true" ||
									this.state.hasMaxParticipants == true) && (
									<div className="form-group">
										<label>Máx. cantidad de personas</label>
										<input
											type="number"
											min={0}
											className="form-control"
											value={this.state.maxParticipants}
											onChange={(e) =>
												this.setState({ maxParticipants: e.target.value })
											}
										/>
									</div>
								)}
								<div className="form-group">
									<label>Prioridad</label>
									<p>
										*Por defecto, todos cuentan con prioridad 0. La mayor
										prioridad aparecerá primero.
									</p>
									<input
										type="number"
										min={0}
										className="form-control"
										value={this.state.priority}
										onChange={(e) =>
											this.setState({ priority: e.target.value })
										}
									/>
								</div>
								<div className="form-group">
									<label>Link de la reunión por zoom</label>
									<input
										className="form-control"
										value={this.state.zoomLink}
										onChange={(e) =>
											this.setState({ zoomLink: e.target.value })
										}
										rows="3"
									/>
								</div>
								<h4 className="title title-underline">
									Agrega información de los precios aquí
								</h4>
								<div className="form-group">
									<label>¿Está en sale? (Aparecerá cartel "SALE")</label>
									<select
										className="form-control"
										onChange={(e) => this.setState({ onSale: e.target.value })}
										value={this.state.onSale}
									>
										<option value="false">No</option>
										<option value="true">Si</option>
									</select>
								</div>
								<div className="form-group">
									<label>¿Descuento? (Aparecerá cartel "XX % DESCUENTO")</label>
									<select
										className="form-control"
										onChange={(e) =>
											this.setState({ discount: e.target.value })
										}
										value={this.state.discount}
									>
										<option value="false">No</option>
										<option value="true">Si</option>
									</select>
								</div>
								{this.state.discount &&
									(this.state.discount == "true" ||
										this.state.discount == true) && (
										<>
											<div className="form-group">
												<label>
													Motivo descuento (Ej: Aparecerá cartel "PRECIO
													LANZAMIENTO")
												</label>
												<input
													type="text"
													className="form-control"
													value={this.state.discountReason}
													onChange={(e) =>
														this.setState({ discountReason: e.target.value })
													}
												/>
											</div>
											<div className="form-group">
												<label>Descuento en porcentaje (1 o 10 ...)</label>
												<input
													type="number"
													min={1}
													className="form-control"
													value={this.state.discountOff}
													onChange={(e) =>
														this.setState({ discountOff: e.target.value })
													}
												/>
											</div>
										</>
									)}
								<div className="form-group">
									<label>¿Es GRATUITO? </label>
									<select
										className="form-control"
										value={this.state.isFree}
										onChange={(e) => this.setState({ isFree: e.target.value })}
									>
										<option value="false">No</option>
										<option value="true">Si</option>
									</select>
								</div>
								<div className="form-group">
									<label>Moneda </label>
									<select
										className="form-control"
										onChange={(e) =>
											this.setState({ currency: e.target.value })
										}
										value={this.state.currency}
									>
										<option disabled value="">
											Seleccione...
										</option>
										<option value="USD">USD</option>
										<option value="UYU">UYU</option>
									</select>
								</div>
								<div className="form-group">
									<label>
										PRECIOS<strong> VIEJOS</strong>(Si no está en sale ni tiene
										% de descuento no completar)
									</label>
									<p>
										*Para <strong>1</strong> persona
									</p>
									<input
										type="number"
										className="form-control"
										value={this.state.oldPrice}
										onChange={(e) =>
											this.setState({ oldPrice: e.target.value })
										}
									/>
									<p>
										*Para <strong>2</strong> personas
									</p>
									<input
										type="number"
										className="form-control"
										value={this.state.oldPriceForTwo}
										onChange={(e) =>
											this.setState({ oldPriceForTwo: e.target.value })
										}
									/>
									<p>
										*Para <strong>3</strong> personas
									</p>
									<input
										type="number"
										className="form-control"
										value={this.state.oldPriceForThree}
										onChange={(e) =>
											this.setState({ oldPriceForThree: e.target.value })
										}
									/>
								</div>
								<div className="form-group">
									<label>
										PRECIOS<strong> ACTUALES</strong>
									</label>
									<p>
										*Para <strong>1</strong> persona (* obligatorio)
									</p>
									<input
										type="number"
										className="form-control"
										value={this.state.newPrice}
										onChange={(e) =>
											this.setState({ newPrice: e.target.value })
										}
									/>
									<p>
										*Para <strong>2</strong> personas
									</p>
									<input
										type="number"
										className="form-control"
										value={this.state.newPriceForTwo}
										onChange={(e) =>
											this.setState({ newPriceForTwo: e.target.value })
										}
									/>
									<p>
										*Para <strong>3</strong> personas
									</p>
									<input
										type="number"
										className="form-control"
										value={this.state.newPriceForThree}
										onChange={(e) =>
											this.setState({ newPriceForThree: e.target.value })
										}
									/>
								</div>
								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Editar Curso
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		editCourse: (course) => {
			dispatch(editCourse(course));
		},
		resetCart: () => {
			dispatch(resetCart());
		},
	};
};

export default connect(null, mapDispatchToProps)(withRouter(EditCourseModal));
