import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { firebase } from "../../../firebase";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import backendUrl from "../../../constants/urls";
import { addSingleProfessor } from "../../../store/actions/cartActions";
import { encodeUser } from '../../../methods/api-calls';
import { css } from "glamor";

const containerStyle = css({
	position: "relative",
});


class AddProfessorModal extends Component {
	state = {
		modal: false,
		profilePicture: "",
		progress: 0,
		fullName: "",
		personDescription: "",
		linkedinLink: "",
		previewProfilePicture: null,
	};

	baseState = this.state;

	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	handleChangeHover = (e) => {
		if (e.target.files[0]) {
			let imageHover = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(imageHover);
			reader.onload = () => {
				this.setState({ previewProfilePicture: reader.result });
			};
			this.handleUploadHover(imageHover);
		}
	};

	handleUploadHover = (imageHover) => {
		const data = new FormData();
		if (imageHover) {
			const authHeader = encodeUser();
			data.append("productImageHover", imageHover, imageHover.name);
			axios
				.post(`${backendUrl}api/upload-hover`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
						...authHeader
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Tamaño máximo: 20MB");
							} else {
								console.log(response.data);
								// If not the given file type
								// alert( response.data.error, 'red' );
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								profilePicture: fileName.location,
							});
							// console.log( 'fileName', fileName.location );
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error);
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	};

	parseToBoolean = (val) => {
		if (typeof val === "boolean") {
			return Boolean(val);
		} else if (val == "true") {
			return Boolean(true);
		} else {
			return Boolean(false);
		}
	};

	addProfessor = (e) => {
		e.preventDefault();
		let {
			profilePicture,
			fullName,
			personDescription,
			linkedinLink,
		} = this.state;

		const professor = {
			profilePicture: profilePicture,
			fullName: fullName,
			personDescription: personDescription,
			linkedinLink: linkedinLink,
		};

		if (fullName != "" && personDescription != "") {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("professors");
			dbOrderRef.add(professor).then((res) => {
				const { id } = res;
				professor.id = id;
				this.props.addSingleProfessor(professor);
				toast.success("Profesor fue registrado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
				this.closeModal();
				this.resetForm();
			});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		let { previewProfilePicture } = this.state;
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
				<ToastContainer className={containerStyle} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar Profesor</h3>

							<form onSubmit={this.addProfessor}>
								<div className="form-group">
									<label>Subir la imagen de perfil aquí</label>
									<div className="upload-img">
										<span>
											<i className="bx bxs-image-add"></i>
											Seleccionar
										</span>

										<input
											type="file"
											className="form-control-file"
											name="productImageHover"
											onChange={this.handleChangeHover}
										/>
									</div>

									{previewProfilePicture ? (
										<div className="uploaded-img">
											<img
												src={previewProfilePicture}
												alt="Image"
												className="img-thumbnail"
											/>
										</div>
									) : null}
								</div>

								<h4 className="title title-underline">
									Agrega información del profesor aquí
								</h4>

								<div className="form-group">
									<label>Nombre completo</label>
									<input
										type="text"
										className="form-control"
										value={this.state.fullName}
										onChange={(e) =>
											this.setState({ fullName: e.target.value })
										}
									/>
								</div>

								<div className="form-group">
									<label>
										Descripción completa de la persona (estudios, etc)
									</label>
									<textarea
										className="form-control"
										value={this.state.personDescription}
										onChange={(e) =>
											this.setState({ personDescription: e.target.value })
										}
										rows="3"
									/>
								</div>

								<div className="form-group">
									<label>
										Link al perfil de linkedin (ej:
										https://www.linkedin.com/in/juan-gomez-bb6874153/)
									</label>
									<input
										className="form-control"
										value={this.state.linkedinLink}
										onChange={(e) =>
											this.setState({ linkedinLink: e.target.value })
										}
										rows="3"
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Registrar profesor
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addSingleProfessor: (professor) => {
			dispatch(addSingleProfessor(professor));
		}
	};
};

export default connect(null, mapDispatchToProps)(withRouter(AddProfessorModal));
