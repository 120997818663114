import React, { Component } from 'react';
import { Link } from "react-router-dom";

class PageBanner extends Component {
    render() {
        let { pageTitle, homePageUrl, homePageText, activePageText } = this.props;
        return (
            <div className="page-title-area jarallax">
                <div className="container">
                    <div className="page-title-content">
                        <h1>{pageTitle}</h1>
                        <ul>
                            <li>
                                <Link to={homePageUrl}>
                                    <a>{homePageText}</a>
                                </Link>
                            </li>
                            <li>{activePageText}</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageBanner;