import React, { Component } from 'react';
import  OwlCarousel  from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left'></i>",
        "<i class='flaticon-right-arrow'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        576: {
            items: 2,
        },
        768: {
            items: 3,
        },
        1200: {
            items: 4,
        }
    }
}

class FacilitySlider extends Component {

    _isMounted = false;
    state = {
        display: false,
    }
    componentDidMount(){ 
        this._isMounted = true;
        this.setState({ display: true }) 
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="facility-area pt-100">
                <div className="container">
                    {this.state.display ? <OwlCarousel 
                        className="facility-slides owl-carousel owl-theme"
                        {...options}
                    >
                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-tracking'></i>
                            </div>
                            <h3>Laboratorios Roemmers</h3>
                        </div>

                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-return'></i>
                            </div>
                            <h3>Roche Uruguay</h3>
                        </div>

                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-shuffle'></i>
                            </div>
                            <h3>Laboratorio Galien</h3>
                        </div>

                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-sale'></i>
                            </div>
                            <h3>Mega Pharma</h3>
                        </div>

                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-credit-card'></i>
                            </div>
                            <h3>Botnia-UPM</h3>
                        </div>

                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-location'></i>
                            </div>
                            <h3>Bancho HSBC</h3>
                        </div>

                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-customer-service'></i>
                            </div>
                            <h3>Banco Surinvest</h3>
                        </div>
                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-customer-service'></i>
                            </div>
                            <h3>Pronto</h3>
                        </div>
                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-customer-service'></i>
                            </div>
                            <h3>Linde Uruguay</h3>
                        </div>
                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-customer-service'></i>
                            </div>
                            <h3>Cementos Artigas</h3>
                        </div>
                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-customer-service'></i>
                            </div>
                            <h3>Moviecenter</h3>
                        </div>
                        <div className="single-facility-box">
                            <div className="icon">
                                <i className='flaticon-customer-service'></i>
                            </div>
                            <h3>Hotel Nirvana</h3>
                        </div>
                    </OwlCarousel> : ''}
                </div>
            </div>
        );
    }
}

export default FacilitySlider;