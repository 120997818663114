export const generateUniqueId = () => {
	const random = Math.floor(Math.random() * 100);
	const date = Date.now();
	return random + date;
};

export const convertToSlug = (str) => {
	let finalString = "";
	if (str) {
		finalString = str.replace(/^\s+|\s+$/g, ""); // trim
    finalString = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
		var to = "aaaaeeeeiiiioooouuuunc------";
		for (var i = 0, l = from.length; i < l; i++) {
			finalString = finalString.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }


		finalString = finalString
			.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
			.replace(/\s+/g, "-") // collapse whitespace and replace by -
			.replace(/-+/g, "-"); // collapse dashes
	}

	return finalString;
};
