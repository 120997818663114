import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { firebase } from "../../../firebase";
import { withRouter } from "react-router-dom";
import { css } from "glamor";

const containerStyle = css({
	position: "relative",
});

class AddVideoModal extends Component {
	state = {
		modal: false,
		videoLink: "",
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	addVideo = (e) => {
		e.preventDefault();
		let { videoLink } = this.state;

		const institutionalVideo = {
			videoLink: videoLink,
		};

		if (videoLink != "") {
			const db = firebase.firestore();

			//First remove old institutional videos
			const dbOrderRef = db.collection("institutionalVideos");
			dbOrderRef.get().then((res) => {
				res.forEach((doc) => {
					dbOrderRef
						.doc(doc.id)
						.delete()
						.then(() => {});
				});
				//Now add
				const dbOrderRef2 = db.collection("institutionalVideos");
				dbOrderRef2.add(institutionalVideo).then((res) => {
					toast.success("El video fue creado exitosamente.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						window.location.reload();
					}, 3000);
					this.closeModal();
					this.resetForm();
				});
			});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer className={containerStyle} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar Video Institucional</h3>
							<label>
								*Si existía un video, se sobreescribirá la información. Los
								campos con <strong>*</strong> son obligatorios. En caso de no
								haber video no se mostrará la sección.
							</label>

							<form onSubmit={this.addVideo}>
								<div className="form-group">
									<label>
										Link a video <strong>*</strong> (link público completo a video en
										youtube. Ej:
										https://www.youtube.com/watch?v=1AyxdYP1SNc{" "}
									</label>
									<input
										type="text"
										className="form-control"
										value={this.state.videoLink}
										onChange={(e) =>
											this.setState({ videoLink: e.target.value?.trim() })
										}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Video
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(AddVideoModal);
