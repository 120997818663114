import React, { Component } from "react";
import {
	Page,
	Text,
	Image,
	View,
	Document,
	StyleSheet,
	Font,
} from "@react-pdf/renderer";
import certificado from "../images/certificado.png";
import Courgette from "../public/static/fonts/Courgette-Regular.ttf";
import EpilogueMedium from "../public/static/fonts/Epilogue-MediumItalic.ttf";
import EpilogueSemiBold from "../public/static/fonts/Epilogue-SemiBoldItalic.ttf";
import EpilogueExtraLight from "../public/static/fonts/Epilogue-ExtraLightItalic.ttf";
import MonotypeCorsiva from "../public/static/fonts/Monotype.ttf";

Font.register({
	family: "Courgette",
	src: Courgette,
});

Font.register({
	family: "Monotype Corsiva",
	fonts: [{ src: MonotypeCorsiva, fontStyle: "italic", fontWeight: 200 }],
});

Font.register({
	family: "Epilogue",
	fonts: [
		{ src: EpilogueMedium, fontStyle: "italic", fontWeight: 500 },
		{ src: EpilogueSemiBold, fontStyle: "italic", fontWeight: 600 },
	],
});

const styles = StyleSheet.create({
	header: {
		marginBottom: 20,
	},
	page: {
		backgroundColor: "#ffffff",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	firstSection: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 0,
		borderStyle: "solid",
		borderWidth: 1,
		borderColor: "#831F29",
		height: "88vh",
		width: "95vw",
		//marginBottom: 40,
		fontSize: 12,
	},
	secondSection: {
		borderRadius: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		borderStyle: "solid",
		borderWidth: 2,
		borderColor: "#831F29",
		//padding: 5,
		height: "86vh",
		width: "93vw",
		fontSize: 12,
	},
	thirdSection: {
		borderRadius: 0,
		borderStyle: "solid",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		borderWidth: 1,
		borderColor: "#831F29",
		//padding: 10,
		height: "84vh",
		width: "91vw",
		fontSize: 12,
		paddingLeft: "10vw",
		paddingRight: "10vw",
		paddingTop: "10vh",
	},
	section: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: 20,
		padding: 10,
		fontSize: 12,
	},
	image: {
		height: 100,
		width: 250,
	},
	logo: {
		marginTop: 20,
		height: "auto",
		width: "90vw",
	},
	footer: {
		marginTop: 20,
	},
	cursoOnline: {
		fontSize: 15,
		alignSelf: "center",
		paddingBottom: 10,
		fontFamily: "Epilogue",
		fontStyle: "italic",
		fontWeight: "500",
	},
	courseName: {
		fontSize: 20,
		textTransform: "uppercase",
		fontFamily: "Epilogue",
		fontStyle: "italic",
		alignSelf: "center",
	},
	primerParrafo: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	porCuanto: {
		marginTop: 50,
		textAlign: "left",
		fontFamily: "Monotype Corsiva",
		fontStyle: "italic",
		fontSize: 16,
		fontWeight: "200",
		width: "100%",
	},
	userName: {
		fontSize: 25,
		margin: 10,
		marginTop: 0,
		textTransform: "capitalize",
		fontFamily: "Courgette",
		alignSelf: "center",
	},
	texto: {
		fontFamily: "Monotype Corsiva",
		fontStyle: "italic",
		fontWeight: "300",
		fontSize: 17,
	},
	pie: {
		fontSize: 10,
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignSelf: "flex-start",
		marginTop: "1vh",
		fontStyle: "italic",
		paddingBottom: 20,
		marginBottom: 30,
		fontWeight: "200",
	},
});

const PDFTemplate = (props) => {
	const { headline, name, courseName, courseContent, email, id, footer } = props.data;
	return (
		<Document>
			<Page size="A4" orientation="landscape" style={styles.page}>
				<View style={styles.firstSection}>
					<View style={styles.secondSection}>
						<View style={styles.thirdSection}>
							<View style={styles.primerParrafo}>
								<Text style={styles.cursoOnline}>{`${headline} de`}</Text>
								<Text style={styles.courseName}>{`${courseName}`}</Text>
							</View>
							<Text style={styles.porCuanto}>{`Por cuanto:`}</Text>
							<Text style={styles.userName}>{`${name}`}</Text>
							<Text style={styles.texto}> {courseContent}</Text>
							<Image
								style={styles.logo}
								allowDangerousPaths={true}
								cache={false}
								src={certificado}
							/>
							<Text style={styles.pie}>
                {footer}
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export { PDFTemplate };
