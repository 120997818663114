import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { css } from "@emotion/core";
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import Footer from "../components/Layouts/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import { fetchOrderById } from "../methods/api-calls";
import { PDFTemplate } from "./PDFTemplate";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import PageBanner from "../components/Common/PageBanner";
import dateformat from "dateformat";
import { transformHeadline } from "../helpers/generic";

dateformat.i18n = {
	dayNames: [
		"Dom",
		"Lun",
		"Mar",
		"Mie",
		"Jue",
		"Vie",
		"Sab",
		"Domingo",
		"Lunes",
		"Martes",
		"Miércoles",
		"Jueves",
		"Viernes",
		"Sábado",
	],
	monthNames: [
		"Ene",
		"Feb",
		"Mar",
		"Abr",
		"May",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	],
	timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

class Certificates extends Component {
	state = {
		loading: false,
		order: null,
	};

	async componentDidMount() {
		this.setState({
			loading: true,
		});
		const id = this.props.match.params.id;
		var order = await fetchOrderById(id);
		this.setState({
			order: order,
			loading: false,
		});
	}

	render() {
		var isMobile = false;
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}
		//const { data } = this.state;
		let name;
		let courseName;
		let courseContent;
		let email;
		let shouldBePlural = false;
		let pdfDocument;
		let dates = [];
		let id;
		let footer = "";
		let headline = "Curso";

		if (this.state.order != null && this.state.order?.items?.length) {
			name =
				this.state.order.customerDetails.firstName +
				" " +
				this.state.order.customerDetails.lastName;
			courseName = this.state.order.items[0].title;
			this.state.order.items[0].modules.map((a, idx) => {
				const oldDate = a.date && a.date.length ? a.date.split("-") : null;

				if (oldDate && oldDate.length > 0) {
					const newDate = oldDate.slice(1, 3);
					newDate.push(oldDate[0]);

					dates.push(oldDate[2]);

					if (idx == this.state.order.items[0].modules.length - 1) {
						let newest = new Date();
						newest.setDate(newDate[1]);
						newest.setMonth(newDate[0] - 1);
						newest.setFullYear(newDate[2]);

						var formatted = dateformat(newest, "mmmm");
						var formatted2 = dateformat(newest, "yyyy");

						dates.push(formatted);
						dates.push(formatted2);
					}
				}
			});
			if(this.state.order) {
				headline = transformHeadline(this.state.order.items[0]?.category?.title);
			}
			shouldBePlural = this.state.order.items[0].modules?.length > 1;
			courseContent =
				this.state.order.items[0].certificateContent ||
				`ha asistido y cumplido con los requisitos del ${headline} de ${courseName} dictado ${
					shouldBePlural ? "los días" : "el día"
				} ${dates.slice(0, dates.length - 2).join(", ")} de ${
					dates[dates.length - 2]
				} de ${
					dates[dates.length - 1]
				}, extiendo el presente certificado en Montevideo el ${
					dates[dates.length - 3]
				} de ${dates[dates.length - 2]} de ${dates[dates.length - 1]}.`;
			email = this.state.order.customerDetails.email;
			id = this.state.order.id;
			footer = `Verificar certificación en: www.gabrielvallone.com.uy/certificados/${id}`;
			pdfDocument = (
				<PDFTemplate
					data={{
						name,
						courseName,
						headline,
						courseContent,
						email,
						id,
						footer,
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<ColoredNavbar />
				<PageBanner
					pageTitle="CERTIFICADOS"
					homePageUrl="/"
					homePageText="Home"
					activePageText="Certificados"
				/>
				{this.state.order == null &&
				this.state.loading == true &&
				email == null ? (
					<section className="certificates-area">
						<div className="container">
							<div className="loader-background">
								<BeatLoader
									css={override}
									size={isMobile ? 50 : 100}
									color={"#4d7c8a"}
									loading={true}
								/>
							</div>
						</div>
					</section>
				) : this.state.order == null && this.state.loading == false ? (
					<section className="certificates-area ">
						<div className="container certificates-content">
							<div className="col-md-6 col-lg-6 col-sm-6">
								<img src={require("../images/not-found/404.png")} alt="error" />
							</div>
							<div className="col-md-6 col-lg-6 col-sm-6">
								<h3>
									Parece que la persona que está buscando no ha completado el
									curso
								</h3>
							</div>{" "}
						</div>
					</section>
				) : (
					<section className="certificates-area ">
						<div className="container certificates-content">
							{/* <div className="col-md-6 col-lg-6 col-sm-6">
                                        <img src={require("../images/certificate.svg")} alt="error" />
                                    </div> */}
							<div className="col-md-12 col-lg-12 col-sm-12">
								<p>Aquí podrá descargar el certificado de: </p>
								<h3>
									{this.state.order.customerDetails.firstName +
										" " +
										this.state.order.customerDetails.lastName}
								</h3>
								<div className="email" style={{marginBottom: '12px'}}>
									{this.state.order.customerDetails.email}
								</div>
								<p style={{marginTop: '0px'}}>{`Correspondiente al ${headline}:`}</p>
								<h3>{this.state.order.items[0].title}</h3>
							</div>
						</div>

						<div className="row pt-50 justify-content-center">
							<PDFDownloadLink
								document={pdfDocument}
								fileName="certificado.pdf"
								style={{
									textDecoration: "none",
									borderRadius: "10px",
								}}
							>
								{({ blob, url, loading, error }) => (
									<p className="default-btn">
										{loading ? "Cargando" : "Descargar certificado"}
									</p>
								)}
							</PDFDownloadLink>
						</div>
					</section>
				)}

				<Footer />
			</React.Fragment>
		);
	}
}

const WrappedCertificates = withRouter(Certificates);

export { WrappedCertificates as Certificates };
