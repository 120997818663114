import { firebase } from "../firebase";

export const fetchCourses = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("courses");
	const coursesArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let coursesObj = doc.data();
		coursesObj.id = doc.id;
		coursesArray.push(coursesObj);
	});
	return coursesArray;
};

export const fetchCategories = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("categories");
	const categoriesArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let catObj = doc.data();
		catObj.id = doc.id;
		categoriesArray.push(catObj);
	});
	return categoriesArray;
};

export const fetchProductById = async (id) => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("products");
	const query = dbOrderRef.where("id", "==", id);
	const productsArray = [];
	const res = await query.get();
	let productsObj = res.doc.data();
	productsObj.id = res.doc.id;
	return productsObj;
};

export const fetchOrderById = async (id) => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("orders");
	const query = dbOrderRef.doc(id);
	const productsArray = [];
	const res = await query.get();
	let productsObj = res.data();
	if(productsObj){
		productsObj.id = res.id;
	}
	return productsObj;
};

export const fetchSubproducts = async (productId) => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("subproducts");
	const query = dbOrderRef.where("productId", "==", productId);
	const subproductsArray = [];
	const res = await query.get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		subproductsArray.push(productsObj);
	});
	return subproductsArray;
};

export const fetchAllSubproducts = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("subproducts");
	const subproductsArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		subproductsArray.push(productsObj);
	});
	return subproductsArray;
};

export const fetchAllBlogPosts = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("blogs");
	const blogsArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		blogsArray.push(productsObj);
	});
	return blogsArray;
};

export const fetchBlogPostById = async (slug) => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("blogs");
	const query = dbOrderRef.where("slug", "==", slug);
	console.log(query)
	const productsArray = [];
	const res = await query.get();
	console.log(res)
	// let productsObj = res.data();
	// productsObj.id = res.id;
	// return productsObj;

	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		productsArray.push(productsObj);
	});

	return productsArray[0]
};

export const fetchBanner = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("banners");
	const subproductsArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		subproductsArray.push(productsObj);
	});
	return subproductsArray;
};

export const fetchIgToken = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("igtokens");
	const subproductsArray = [];
	const res = await dbOrderRef.orderBy("createdAt").get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		subproductsArray.push(productsObj);
	});
	return subproductsArray;
};

export const encodeUser = () => {
	const userAndPassword = "gabriel:Gabriel2020!";
	const encrypted = btoa(userAndPassword);
	return {
    'Authorization': `${encrypted}` 
  }
}