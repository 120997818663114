import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { css } from "glamor";
import axios from "axios";

import CheckBox from "./Checkbox";
import backendUrl from "../../../constants/urls";
import { firebase } from "../../../firebase";
import { encodeUser } from "../../../methods/api-calls";

const COUPON_TYPES = {
	INFLUENCER: 1,
	GENERIC: 2,
	NO_COST: 3,
	INVITATION: 4,
};

const containerStyle = css({
	position: "relative",
});

class AddCouponModal extends Component {
	state = {
		modal: false,
		emailError: false,
		code: "", //If type is generic, it will autogenerate, if not, it will be the same for everyone.
		type: 1,
		maxUses: 0, //If type is generic, only one user can use each code, if not you have to set maxUses.
		directedTo: [],
		courses: [],
		title: "",
		description: "",
		percentage: 0,
		expirationDate: null,
		alreadyExistsError: false,
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	async componentDidMount() {
		const db = firebase.firestore();
		let dbOrderRef = db.collection("users");
		let usersArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let categoriesObj = doc.data();
					categoriesObj.id = doc.id;
					usersArray.push(categoriesObj);
				});
				this.setState({
					directedTo: usersArray.map((u) => ({
						id: u.email,
						value: u.email,
						isChecked: false,
					})),
				});
			})
			.catch((err) => {
				console.log("error", err);
			});

		const db2 = firebase.firestore();
		let dbOrderRef2 = db2.collection("courses");
		let coursesArray = [];
		dbOrderRef2
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let coursesObj = doc.data();
					coursesObj.id = doc.id;
					coursesArray.push(coursesObj);
				});
				this.setState({
					courses: coursesArray.map((c) => ({
						id: c.id,
						value: c.title,
						isChecked: false,
					})),
				});
			})
			.catch((err) => {
				console.log("error", err);
			});
	}

	closeModal = () => {
		this.resetForm();
		this.props.onClick(this.state.modal);
	};

	handleCheckChieldElement = (event) => {
		const { directedTo } = this.state;
		directedTo.forEach((user) => {
			if (user.value === event.target.value)
				user.isChecked = event.target.checked;
		});
		this.setState({ directedTo: directedTo });
	};

	handleCheckCourseElement = (event) => {
		const { courses } = this.state;
		courses.forEach((course) => {
			if (course.value === event.target.value)
				course.isChecked = event.target.checked;
		});
		this.setState({ courses: courses });
	};

	validateEmail = (email) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	addEmail = (e) => {
		const { currentEmail, directedTo } = this.state;
		if (currentEmail && this.validateEmail(currentEmail)) {
			if (!directedTo.find((d) => d.value === currentEmail)) {
				const user = {
					id: currentEmail,
					value: currentEmail,
					isChecked: true,
				};
				this.setState((prevState) => ({
					directedTo: [...prevState.directedTo, user],
					currentEmail: "",
					emailError: false,
					alreadyExistsError: false,
				}));
			} else {
				this.setState((prevState) => ({
					alreadyExistsError: true,
				}));
			}
		} else {
			this.setState((prevState) => ({
				emailError: true,
			}));
		}
		e.preventDefault();
	};

	addCoupon = async (e) => {
		e.preventDefault();
		let {
			code,
			type,
			maxUses,
			directedTo,
			percentage,
			title,
			description,
			expirationDate,
			courses,
		} = this.state;
		const atLeastOnePersonChecked =
			directedTo &&
			directedTo.length > 0 &&
			directedTo.find((d) => d.isChecked);
		const atLeastOneCourseChecked =
			courses && courses.length > 0 && courses.find((d) => d.isChecked);
		if (
			((type == COUPON_TYPES.INFLUENCER && code && maxUses > 0) ||
				type == COUPON_TYPES.GENERIC ||
				type == COUPON_TYPES.NO_COST ||
				type == COUPON_TYPES.INVITATION) &&
			title &&
			description &&
			(atLeastOnePersonChecked || type == COUPON_TYPES.INFLUENCER) &&
			atLeastOneCourseChecked &&
			((type !== COUPON_TYPES.NO_COST &&
				type !== COUPON_TYPES.INVITATION &&
				percentage &&
				Number(percentage) > 0) ||
				type == COUPON_TYPES.NO_COST ||
				type == COUPON_TYPES.INVITATION)
		) {
			const coupon = {
				code: code,
				type: type,
				percentage:
					type == COUPON_TYPES.NO_COST || type == COUPON_TYPES.INVITATION
						? 100
						: Number(percentage),
				maxUses: type != COUPON_TYPES.INFLUENCER ? 0 : Number(maxUses),
				directedTo:
					directedTo && directedTo.length > 0
						? directedTo.filter((d) => d.isChecked).map((d2) => d2.value)
						: [],
				title: title,
				courses:
					courses && courses.length > 0
						? courses
								.filter((d) => d.isChecked)
								.map((d2) => ({ id: d2.id, title: d2.value }))
						: [],
				description: description,
				expirationDate: expirationDate,
				createdAt: new Date(),
			};

			try {
				if (type == COUPON_TYPES.INFLUENCER) {
					const res = await axios.post(
						`${backendUrl}api/coupons/add-influencer-code`,
						coupon,
						{
							headers: encodeUser(),
						}
					);
				} else if (type == COUPON_TYPES.GENERIC) {
					const res = await axios.post(
						`${backendUrl}api/coupons/add-generic-code`,
						coupon,
						{
							headers: encodeUser(),
						}
					);
				} else if (type == COUPON_TYPES.NO_COST) {
					const res = await axios.post(
						`${backendUrl}api/coupons/add-no-cost-code`,
						coupon,
						{
							headers: encodeUser(),
						}
					);
				} else if (type == COUPON_TYPES.INVITATION) {
					const res = await axios.post(
						`${backendUrl}api/coupons/add-invitation-code`,
						coupon,
						{
							headers: encodeUser(),
						}
					);
				}

				toast.success("El cupon fue creado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				this.closeModal();
				this.resetForm();
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			} catch (e) {
				alert(e);
			}
		} else {
			if (
				type != COUPON_TYPES.NO_COST &&
				type != COUPON_TYPES.INVITATION &&
				Number(percentage) == 0
			) {
				toast.error("El % debe ser mayor a 0", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
			if (courses && courses.length == 0) {
				toast.error("No hay cursos ingresados", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			} else {
				toast.error("Complete los campos", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		}
	};

	render() {
		const { directedTo } = this.state;
		let currentUsers =
			directedTo && directedTo.length > 0
				? directedTo.filter((d) => d.isChecked)?.length
				: 0;
		return (
			<React.Fragment>

				<div className={`admin-product-modal ${this.props.active}`}>
				<ToastContainer className={containerStyle} />

					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar cupón</h3>

							<form onSubmit={this.addCoupon}>
								*Nota: Hay 4 tipos de cupones: "MASIVO", "PERSONAL", "SIN COSTO"
								e "INVITACIÓN".
								<p style={{ fontSize: "13px", marginBottom: "0px" }}>
									<strong>Masivo</strong>: mismo código (Ej. "NEGOCIACIÓN2020")
									y varios usos.
								</p>
								<p
									style={{
										fontSize: "13px",
										marginTop: "0px",
										marginBottom: "0px",
									}}
								>
									<strong>Personal</strong>: códigos únicos autogenerados (Ej.
									hjkuhy12bh) y un único uso por código.
								</p>
								<p
									style={{
										fontSize: "13px",
										marginTop: "0px",
										marginBottom: "0px",
									}}
								>
									<strong>Sin costo</strong>: códigos únicos autogenerados (Ej.
									hjkuhy12bh) y un único uso por código. Representa las personas
									que ya han pagado por fuera (transferencia) pero se desea
									inscribirlos en el sistema.
								</p>
								<p style={{ fontSize: "13px", marginTop: "0px" }}>
									<strong>Invitación</strong>: códigos únicos autogenerados (Ej.
									hjkuhy12bh) y un único uso por código. Representa las personas
									que no pagan el curso debido a que reciben una invitación.
								</p>
								<h4 className="title">Agrega información del cupón aquí</h4>
								<div className="form-group">
									<label>Tipo de cupón *</label>
									<select
										className="form-control"
										value={this.state.type}
										onChange={(e) => this.setState({ type: e.target.value })}
									>
										<option value={COUPON_TYPES.INFLUENCER}>MASIVO</option>
										<option value={COUPON_TYPES.GENERIC}>PERSONAL</option>
										<option value={COUPON_TYPES.NO_COST}>SIN COSTO</option>
										<option value={COUPON_TYPES.INVITATION}>INVITACIÓN</option>
									</select>
								</div>
								<div className="form-group">
									<label>Titulo *</label>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>
								<div className="form-group">
									<label>Descripción *</label>
									<textarea
										className="form-control"
										value={this.state.description}
										onChange={(e) =>
											this.setState({ description: e.target.value })
										}
										rows="5"
									/>
								</div>
								<div className="form-group">
									<label>Código </label>
									<p style={{ fontSize: "11px" }}>*Sólo aplica para MASIVO</p>
									<input
										disabled={this.state.type != COUPON_TYPES.INFLUENCER}
										type="text"
										className="form-control"
										value={this.state.code}
										onChange={(e) =>
											this.setState({ code: e.target.value.trim() })
										}
									/>
								</div>
								<div className="form-group">
									<label>Fecha expiración *</label>
									<input
										type="date"
										className="form-control"
										value={this.state.expirationDate}
										onChange={(e) =>
											this.setState({ expirationDate: e.target.value })
										}
									/>
								</div>
								<div className="form-group">
									<label>Porcentaje de descuento *</label>
									<input
										type="number"
										min={0}
										disabled={
											this.state.type == COUPON_TYPES.NO_COST ||
											this.state.type == COUPON_TYPES.INVITATION
										}
										className="form-control"
										value={
											this.state.type == COUPON_TYPES.NO_COST ||
											this.state.type == COUPON_TYPES.INVITATION
												? 100
												: this.state.percentage
										}
										onChange={(e) =>
											this.setState({ percentage: e.target.value.trim() })
										}
									/>
								</div>
								<div className="form-group">
									<label>Máxima cantidad de usos </label>
									<p style={{ fontSize: "11px" }}>*Sólo aplica para MASIVO</p>
									<input
										disabled={this.state.type != COUPON_TYPES.INFLUENCER}
										type="number"
										min={0}
										className="form-control"
										value={this.state.maxUses}
										onChange={(e) =>
											this.setState({ maxUses: e.target.value.trim() })
										}
									/>
								</div>
								<div className="form-group">
									<div>Cursos a los que aplica</div>
									<label>Cursos:</label>
									{this.state.courses && this.state.courses.length > 0 ? (
										<ul
											style={{
												height: "150px",
												overflow: "hidden",
												overflowY: "scroll",
											}}
										>
											{this.state.courses.map((course) => {
												return (
													<CheckBox
														handleCheckChieldElement={
															this.handleCheckCourseElement
														}
														{...course}
													/>
												);
											})}
										</ul>
									) : (
										"No hay cursos."
									)}
								</div>
								<div className="form-group">
									<div>
										*Aquí van las personas a las que se le enviará el cupón por
										correo.
									</div>
									<p style={{ fontSize: "13px" }}>
										<strong>Máx</strong>: 400 personas diarias.
									</p>
									<p>{`${currentUsers} de ${this.state.directedTo?.length} usuarios`}</p>
									<label>Usuarios: *</label>
									{this.state.directedTo && this.state.directedTo.length > 0 ? (
										<ul
											style={{
												height: "150px",
												overflow: "hidden",
												overflowY: "scroll",
											}}
										>
											{this.state.directedTo.map((user) => {
												return (
													<CheckBox
														handleCheckChieldElement={
															this.handleCheckChieldElement
														}
														{...user}
													/>
												);
											})}
										</ul>
									) : (
										"No hay usuarios."
									)}

									<label>
										¿No encuentras el usuario que estabas buscando? Ingresa el
										correo
									</label>
									<input
										type="email"
										className="form-control"
										value={this.state.currentEmail}
										onChange={(e) =>
											this.setState({ currentEmail: e.target.value })
										}
									/>
									<button onClick={this.addEmail}>+</button>
									{this.state.emailError && (
										<p style={{ fontSize: "11px" }}>
											Formato de correo inválido.
										</p>
									)}
									{this.state.alreadyExistsError && (
										<p style={{ fontSize: "11px" }}>Ya existe ese correo.</p>
									)}
								</div>
								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Cupón
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(AddCouponModal);
