import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import OwlCarousel from "react-owl-carousel3";
import video_1 from "../../videos/video1-min.mov";
import video_2 from "../../videos/videotop-min.mov";
import video_3 from "../../videos/video3-min.mov";
import thumb from "../../images/logos/Logo_GV.png";
import thumb2 from "../../images/logos/thumb2.png";
import { firebase } from "../../firebase";

import mobile_1 from "../../images/slider/mobile_1.jpg";
import mobile_2 from "../../images/slider/mobile_2.jpg";
import mobile_3 from "../../images/slider/mobile_3.jpg";

import { isMobile } from "../../helpers/generic";

const options = {
	loop: true,
	dots: false,
	autoplay: true,
	smartSpeed: 3000,
	animateIn: "fadeIn",
	autoplayTimeout: 6000,
	items: 1,
	navText: [
		"<i class='flaticon-left'></i>",
		"<i class='flaticon-right-arrow'></i>",
	],
};

const MainBanner = () => {
	const [firstVideoLoaded, setFirstVideoLoaded] = useState(false);
	const [secondVideoLoaded, setSecondVideoLoaded] = useState(false);
	const [thirdVideoLoaded, setThirdVideoLoaded] = useState(false);
	const [currentBanner, setCurrentBanner] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			const db = firebase.firestore();
			let dbOrderRef = db.collection("banners");
			let bannersArray = [];
			try {
				const res = await dbOrderRef.get();
				res.forEach((doc) => {
					let bannersObj = doc.data();
					bannersObj.id = doc.id;
					bannersArray.push(bannersObj);
				});
				setCurrentBanner(bannersArray?.length ? bannersArray[0] : null);
			} catch (err) {
				console.log("error", err);
			}
			setLoading(false);
		};
		fetchData();
	}, []);

	const showAllVideos =
		firstVideoLoaded && secondVideoLoaded && thirdVideoLoaded && !loading;

	const onLoadedData = (id) => {
		switch (id) {
			case 1:
				setFirstVideoLoaded(true);
				break;
			case 2:
				setSecondVideoLoaded(true);
				break;
			case 3:
				setThirdVideoLoaded(true);
				break;
		}
	};

	const mobile = isMobile();

	{
		return (
			<React.Fragment>
				<OwlCarousel
					className="home-slides owl-carousel owl-theme"
					{...options}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							height: mobile ? "100vh" : "auto",
							alignItems: "center",
							backgroundColor: !showAllVideos ? "#38616e" : "",
						}}
					>
						{mobile ? (
							<img
								src={mobile_1}
								className="video-thumb"
								style={{ height: "auto", top: "auto" }}
							/>
						) : (
							<>
								<img
									src={thumb2}
									className="video-thumb tiny"
									alt="thumb"
									style={{ opacity: showAllVideos ? 0 : 1 }}
								/>

								<video
									id="currentVid"
									className="video-banner "
									autoplay="autoplay"
									muted="true"
									loop="loop"
									id="myVideo"
									onLoadedData={() => onLoadedData(1)}
									playsInline
									style={{ opacity: firstVideoLoaded ? 1 : 0 }}
								>
									<source src={video_1} type="video/mp4" />
								</video>
							</>
						)}

						<div className="video-overlay"></div>

						<div className="main-banner banner-bg1">
							<div className="d-table ">
								<div className="d-table-cell">
									<div className="container">
										<VisibilitySensor>
											{({ isVisible }) => (
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													{!loading && (
														<div className="main-banner-content">
															<h2
																className={
																	isVisible
																		? "bubble-header-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																Aprendé a negociar
															</h2>
															<p
																className={
																	isVisible
																		? "bubble-subheader-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																Para alcanzar eficientemente los objetivos
																personales y colectivos.
															</p>
														</div>
													)}

													{currentBanner && !mobile && (
														<div className="main-banner-content-alt">
															<h2
																className={
																	isVisible
																		? "bubble-header-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																{currentBanner.title}
															</h2>
															<p
																className={
																	isVisible
																		? "bubble-subheader-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																{currentBanner.content}
															</p>
															{currentBanner.buttonText && (
																<a
																	href={
																		currentBanner.buttonLink ||
																		"https://gabrielvallone.com.uy/"
																	}
																	className="default-btn-yellow"
																>
																	{currentBanner.buttonText}
																</a>
															)}
														</div>
													)}
												</div>
											)}
										</VisibilitySensor>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "center",
							height: mobile ? "100vh" : "auto",
							alignItems: "center",
							backgroundColor: !showAllVideos ? "#38616e" : "",
						}}
					>
						{mobile ? (
							<img
								src={mobile_2}
								className="video-thumb"
								style={{ height: "auto", top: "auto" }}
							/>
						) : (
							<video
								id="currentVid"
								className="video-banner"
								autoplay="autoplay"
								muted="true"
								loop="loop"
								id="myVideo"
								onLoadedData={() => onLoadedData(2)}
								style={{ opacity: secondVideoLoaded ? 1 : 0 }}
								playsInline
							>
								<source src={video_2} type="video/mp4" />
								Your browser does not support HTML5 video.
							</video>
						)}

						<div className="video-overlay"></div>

						<div className="main-banner banner-bg2">
							<div className="d-table">
								<div className="d-table-cell">
									<div className="container">
										<VisibilitySensor>
											{({ isVisible }) => (
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													{!loading && (
														<div className="main-banner-content">
															<h2
																className={
																	isVisible
																		? "bubble-header animated fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																Comunicación efectiva
															</h2>
															<p
																className={
																	isVisible
																		? "bubble-subheader animated fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																Conocimientos y técnicas para afrontar
																exitosamente las conversaciones difíciles.
															</p>
														</div>
													)}
													{currentBanner && !mobile && (
														<div className="main-banner-content-alt">
															<h2
																className={
																	isVisible
																		? "bubble-header-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																{currentBanner.title}
															</h2>
															<p
																className={
																	isVisible
																		? "bubble-subheader-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																{currentBanner.content}
															</p>
															{currentBanner.buttonText && (
																<a
																	href={
																		currentBanner.buttonLink ||
																		"https://gabrielvallone.com.uy/"
																	}
																	className="default-btn-yellow"
																>
																	{currentBanner.buttonText}
																</a>
															)}
														</div>
													)}
												</div>
											)}
										</VisibilitySensor>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							height: mobile ? "100vh" : "auto",
							alignItems: "center",
							backgroundColor: !showAllVideos ? "#38616e" : "",
						}}
					>
						{mobile ? (
							<img
								src={mobile_3}
								className="video-thumb"
								style={{ height: "auto", top: "auto" }}
							/>
						) : (
							<video
								id="currentVid"
								className="video-banner "
								autoplay="autoplay"
								muted="true"
								loop="loop"
								id="myVideo"
								onLoadedData={() => onLoadedData(3)}
								playsInline
							>
								<source src={video_3} type="video/mp4" />
							</video>
						)}
						<div className="video-overlay"></div>

						<div className="main-banner banner-bg3">
							<div className="d-table">
								<div className="d-table-cell">
									<div className="container">
										<VisibilitySensor>
											{({ isVisible }) => (
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													{!loading && (
														<div className="main-banner-content">
															<h2
																className={
																	isVisible
																		? "bubble-header animated fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																Negociación Estratégica
															</h2>
															<p
																className={
																	isVisible
																		? "bubble-subheader animated fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																Para formar negociadores capaces de conducir
																eficazmente cualquier proceso de negociación.
															</p>
														</div>
													)}
													{currentBanner && !mobile &&  (
														<div className="main-banner-content-alt">
															<h2
																className={
																	isVisible
																		? "bubble-header-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																{currentBanner.title}
															</h2>
															<p
																className={
																	isVisible
																		? "bubble-subheader-alt  fadeInUp slow opacityOne"
																		: "opacityZero"
																}
															>
																{currentBanner.content}
															</p>
															{currentBanner.buttonText && (
																<a
																	href={
																		currentBanner.buttonLink ||
																		"https://gabrielvallone.com.uy/"
																	}
																	className="default-btn-yellow"
																>
																	{currentBanner.buttonText}
																</a>
															)}
														</div>
													)}
												</div>
											)}
										</VisibilitySensor>
									</div>
								</div>
							</div>
						</div>
					</div>
				</OwlCarousel>
			</React.Fragment>
		);
	}
};

export default MainBanner;
