import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import withAuth from "../../helpers/withAuth";
import noPhoto from "../../images/products/noImage.png";
import axios from "axios";
import backendUrl from "../../constants/urls";
import { encodeUser } from "../../methods/api-calls";
import EditBlogPostModal from "../../components/Admin/Modals/EditBlogPostModal";

class Blogs extends Component {
	state = {
		display: false,
		blogs: [],
		loading: true,
		modalBlog: null,
	};

	convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		let time = t.setSeconds(d.seconds);
		const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(time);
		const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
		const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
		return `${da}-${mo}-${ye}`;
	};

	dataModal = (blog) => {
		this.setState({
			modalProduct: blog,
		});
	};

	toggleEditBlogPostModal = (blog = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalProduct: blog,
		});
	};


	deleteBlog = (blog) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("blogs");
		dbOrderRef
			.doc(blog.id)
			.delete()
			.then(() => {
				let allKeys = "";
				if (blog.coverImageUrl) {
					const splitted = blog.coverImageUrl.split("/");
					if (splitted) allKeys += splitted[splitted.length - 1];
				}
				if (blog.imageUrlSecond) {
					const splitted = blog.imageUrlSecond.split("/");
					if (splitted) allKeys += `,${splitted[splitted.length - 1]}`;
				}
				if (blog.imageUrlThird) {
					const splitted = blog.imageUrlThird.split("/");
					if (splitted) allKeys += `,${splitted[splitted.length - 1]}`;
				}
				if (blog.imageUrlFourth) {
					const splitted = blog.imageUrlFourth.split("/");
					if (splitted) allKeys += `,${splitted[splitted.length - 1]}`;
				}

				if (allKeys) {
					const authHeader = encodeUser();

					axios
						.get(`${backendUrl}api/upload/erase`, {
							params: {
								fileKeys: allKeys,
							},
							headers: {
								accept: "application/json",
								"Accept-Language": "en-US,en;q=0.8",
								// "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
								...authHeader,
							},
						})
						.then((response) => {})
						.catch((error) => {
							// If another error
							console.log(error, "red");
						});
				}
				// Delete completed!
				toast.success("Blog post fue eliminado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("blogs");
		let blogsArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				// console.log(res)
				res.forEach((doc) => {
					let productsObj = doc.data();
					productsObj.id = doc.id;
					blogsArray.push(productsObj);
				});
				this.setState({
					blogs: blogsArray,
					loading: false,
				});
				this.loading = false;
			})
			.catch((err) => {
				this.setState({
					loading: false,
				});
				console.log("error", err);
			});
	}

	render() {
		let { blogs, loading } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Blog posts</h2>
					</div>

					<div className="admin-products">
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : blogs.length ? (
								blogs.map((blog, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
											<div className="products-image">
												<Link to="#viewproduct">
													{/* <a
															onClick={(e) => {
																e.preventDefault();
																this.toggleViewProductModal();
																this.dataModal(product);
															}}
														> */}{" "}
													<img
														src={
															blog.coverImageUrl ? blog.coverImageUrl : noPhoto
														}
														className="main-image"
														alt="image"
													/>
													{/* </a> */}
												</Link>

												<div className="products-button">
													<ul>
														{/* <li>
																<div className="wishlist-btn">
																	<Link to="#">
																		<a
																			onClick={(e) => {
																				e.preventDefault();
																				this.toggleViewProductModal();
																				this.dataModal(product);
																			}}
																		>
																			<i className="bx bx-search-alt"></i>
																			<span className="tooltip-label">
																				Ver
																			</span>
																		</a>
																	</Link>
																</div>
															</li> */}
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditBlogPostModal(blog);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() => this.deleteBlog(blog)}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>

											<div className="products-content">
												<h3>{blog.mainTitle}</h3>
												<div className="price">
													<span className="new-price">
														{blog.createdAt
															? this.convertDateTime(blog.createdAt)
															: ""}
													</span>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay blog posts.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditBlogPostModal
						onClick={this.toggleEditBlogPostModal}
						active={this.state.showEditModal ? "active" : ""}
						blog={this.state.modalProduct}
					/>
				)}
				{/*<ViewProductModal
					onClick={this.toggleViewProductModal}
					active={this.state.ViewProductModal ? "active" : ""}
					product={this.state.modalProduct}
				/> */}
			</React.Fragment>
		);
	}
}

export default withAuth(Blogs);
