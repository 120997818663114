import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditCourseModal from "../../components/Admin/Modals/EditCourseModal";
import ViewCourseModal from "../../components/Admin/Modals/ViewCourseModal";
import withAuth from "../../helpers/withAuth";
import noPhoto from "../../images/products/noImage.png";
import geometric from "../../images/courses/geometric-new.png";
import geometricAlt from "../../images/courses/geometric-alt-new.png";
import axios from "axios";
import { removeCourse, resetCart } from "../../store/actions/cartActions";
import { connect } from "react-redux";
import backendUrl from "../../constants/urls";
import { encodeUser } from "../../methods/api-calls";

class Courses extends Component {
	// Edit Course Modal
	state = {
		display: false,
		courses: [],
		loading: true,
		showEditModal: false,
		modalCourse: null,
	};

	toggleEditCourseModal = (course = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalCourse: course,
		});
	};

	// View Course Modal
	toggleViewCourseModal = () => {
		this.setState({
			ViewCourseModal: !this.state.ViewCourseModal,
		});
	};
	dataModal = (course) => {
		this.setState({
			modalCourse: course,
		});
	};

	deleteCourse = (course) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("courses");
		dbOrderRef
			.doc(course.id)
			.delete()
			.then(() => {
				this.props.removeCourse(course.id);

				// NOW REMOVE SUBPRODUCTS!
				let allKeys = "";
				if (course.imageUrl) {
					const splitted = course.imageUrl.split("/");
					if (splitted) allKeys += splitted[splitted.length - 1];
				}
				if (course.imageHoverUrl) {
					const splitted = course.imageHoverUrl.split("/");
					if (splitted) allKeys += `,${splitted[splitted.length - 1]}`;
				}
				if (course.imageUrlSecond) {
					const splitted = course.imageUrlSecond.split("/");
					if (splitted) allKeys += `,${splitted[splitted.length - 1]}`;
				}
				if (course.imageUrlThird) {
					const splitted = course.imageUrlThird.split("/");
					if (splitted) allKeys += `,${splitted[splitted.length - 1]}`;
				}
				if (course.imageUrlFourth) {
					const splitted = course.imageUrlFourth.split("/");
					if (splitted) allKeys += `,${splitted[splitted.length - 1]}`;
				}

				if (allKeys) {
					const authHeader = encodeUser();
					axios
						.get(`${backendUrl}api/upload/erase`, {
							params: {
								fileKeys: allKeys,
							},
							headers: {
								accept: "application/json",
								"Accept-Language": "en-US,en;q=0.8",
								// "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
								...authHeader,
							},
						})
						.then((response) => {})
						.catch((error) => {
							// If another error
							console.log(error, "red");
						});
				}
				// Delete completed!
				toast.success("Curso fue eliminado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
				this.props.resetCart();
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		const db = firebase.firestore();
		this.setState({
			loading: true,
		});
		const dbOrderRef = db.collection("courses");
		let coursesArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				// console.log(res)
				res.forEach((doc) => {
					let coursesObj = doc.data();
					coursesObj.id = doc.id;
					coursesArray.push(coursesObj);
				});
				coursesArray = coursesArray.sort((p1, p2) => {
					if (p1.priority && !p2.priority) {
						return -1;
					} else if (!p1.priority && p2.priority) {
						return 1;
					} else {
						return p2.priority - p1.priority;
					}
				});
				this.setState({
					courses: coursesArray,
					loading: false,
				});
				// this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { courses, loading } = this.state;
		return (
			<React.Fragment>
				<ToastContainer />

				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Cursos</h2>
					</div>

					<div className="admin-products">
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : courses.length ? (
								courses.map((course, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
											<div className="products-image">
												<Link to="#viewproduct">
													<a
														onClick={(e) => {
															e.preventDefault();
															this.toggleViewCourseModal();
															this.dataModal(course);
														}}
													>
														{" "}
														<img
															src={geometric}
															className="main-image"
															alt="image"
														/>
														<img
															src={geometricAlt}
															className="hover-image"
															alt="image"
														/>
													</a>
												</Link>

												{course.onSale ? (
													<div className="sale-tag">Sale!</div>
												) : null}

												{course.discount && course.discountReason ? (
													<div className="new-tag">{course.discountReason}</div>
												) : null}

												<div className="products-button">
													<ul>
														<li>
															<div className="wishlist-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleViewCourseModal();
																			this.dataModal(course);
																		}}
																	>
																		<i className="bx bx-search-alt"></i>
																		<span className="tooltip-label">Ver</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditCourseModal(course);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() => this.deleteCourse(course)}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>

											<div className="products-content">
												<h2>{course.header}</h2>
												<h3>{course.title}</h3>
												<h5>Prioridad: {course.priority || "sin prioridad"}</h5>
												<div className="price">
													{course.isFree ? (
														<span className="new-price">
															ACTIVIDAD SIN COSTO
														</span>
													) : course.offer ? (
														<React.Fragment>
															{course.currency + " "}
															<span className="old-price">
																{course.oldPrice}
															</span>
															<span className="new-price">
																{course.newPrice}
															</span>
														</React.Fragment>
													) : (
														<span className="new-price">
															{course.currency + " "} {course.newPrice}
														</span>
													)}
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay cursos.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditCourseModal
						onClick={this.toggleEditCourseModal}
						active={this.state.showEditModal ? "active" : ""}
						course={this.state.modalCourse}
					/>
				)}
				<ViewCourseModal
					onClick={this.toggleViewCourseModal}
					active={this.state.ViewCourseModal ? "active" : ""}
					course={this.state.modalCourse}
				/>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		removeCourse: (id) => {
			dispatch(removeCourse(id));
		},
		resetCart: () => {
			dispatch(resetCart());
		},
	};
};

export default connect(null, mapDispatchToProps)(withAuth(Courses));
