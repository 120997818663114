import React, { Component } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";

const options = {
	loop: true,
	nav: false,
	dots: false,
	autoplayHoverPause: false,
	autoplayTimeout: 2000,
	autoplay: true,
	animateOut: "fadeOut",
	mouseDrag: false,
	items: 1,
	navText: [
		"<i class='flaticon-left'></i>",
		"<i class='flaticon-right-arrow'></i>",
	],
};

class ConsultoriaProducts extends Component {
	_isMounted = false;
	state = {
		display: false,
	};
	componentDidMount() {
		this._isMounted = true;
		this.setState({ display: true });
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	render() {
		return (
			<section className="offer-products-area ptb-100 special-pb">
				<div className="container">
					{this.state.display ? (
						<OwlCarousel
							className="offer-products-slides owl-carousel owl-theme"
							{...options}
						>
							<div className="single-offer-products">
								<div className="content">
									<h3>Consultoría</h3>
									<p>La dinámica que aportará valor a tu empresa.</p>
									<div style={{ display: "flex", justifyContent: "center" }}>
										<a
											href="mailto:gabriel@gabrielvallone.com.uy?Subject=Consulta%20In%20Company%20"
											className="default-btn"
										>
											Consultar
										</a>
									</div>
								</div>

								<div className="consultoria-image">
									<a>
										<img
											src={require("../../images/consultoria/consultoria1.jpg")}
											alt="image"
										/>
									</a>
								</div>
							</div>

							<div className="single-offer-products">
								<div className="content">
									<h3>
										<a>Consultoría</a>
									</h3>
									<p>La dinámica que aportará valor a tu empresa.</p>
									<div style={{ display: "flex", justifyContent: "center" }}>
										<a
											href="mailto:gabriel@gabrielvallone.com.uy?Subject=Consulta%20In%20Company%20"
											className="default-btn"
										>
											Consultar
										</a>
									</div>
								</div>

								<div className="consultoria-image">
									<a>
										<img
											src={require("../../images/consultoria/consultoria2.jpg")}
											alt="image"
										/>
									</a>
								</div>
							</div>

							<div className="single-offer-products">
								<div className="content">
									<h3>
										<a>Consultoría</a>
									</h3>
									<p>La dinámica que aportará valor a tu empresa.</p>
									<div style={{ display: "flex", justifyContent: "center" }}>
										<a
											href="mailto:gabriel@gabrielvallone.com.uy?Subject=Consulta%20In%20Company%20"
											className="default-btn"
										>
											Consultar
										</a>
									</div>
								</div>

								<div className="consultoria-image">
									<a>
										<img
											src={require("../../images/consultoria/consultoria3.jpg")}
											alt="image"
										/>
									</a>
								</div>
							</div>
						</OwlCarousel>
					) : (
						""
					)}
				</div>
			</section>
		);
	}
}

export default ConsultoriaProducts;
