import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackgroundImageOnLoad from "background-image-on-load";

class BannerWithTextAlt extends Component {
	render() {
		return (
			<section
				className="offer-area covid-19-offer bg-image4 pb-100 pt-100 jarallax"
				style={{ marginTop: "10vh", marginBottom: "10vh" }}
			>
				<BackgroundImageOnLoad
					src={"https://unsplash.it/1200/310?random"}
					onLoadBg={this.props.onImageLoad}
				/>
				<div className="container-fluid">
					<div
						className="row"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<div className="col-lg-5 col-md-6">
							<div className="offer-content-box">
								<h5 style={{ marginBottom: "20px", color: "white" }}>
									Los trabajos de consultoría se concentran especialmente en dos
									aspectos clave de la dirección de negocios:
								</h5>
								<ul className="features-list">
									<li>
										<h6 style={{ fontSize: "17px", color: "white" }}>
											<strong>Negociación y resolución de conflictos</strong>
										</h6>
									</li>
									<li>
										<h6 style={{ fontSize: "17px", color: "white" }}>
											<strong>Toma de decisiones estratégicas</strong>
										</h6>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default BannerWithTextAlt;
