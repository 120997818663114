import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import backendUrl from "../../../constants/urls";
import { firebase } from "../../../firebase";
import axios from "axios";
import { encodeUser } from "../../../methods/api-calls";
import { css } from "glamor";
import { convertToSlug } from "../../../utils/id";

const containerStyle = css({
	position: "relative",
});

class AddBlogPostModal extends Component {
	state = {
		modal: false,
		imageUrls: [],
		imageCount: 0,
		coverImageUrl: "",
		progress: 0,
		mainTitle: "",
		slug: "",
		videoLink: "",
		content: [],
		currentTitle: "",
		currentParagraph: "",
		previewImages: [],
		previewImage: null,
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	addSection = (e) => {
		//CHANGE

		const { currentParagraph, currentTitle } = this.state;
		const newSection = {
			title: currentTitle,
			text: currentParagraph,
		};
		if (currentParagraph) {
			this.setState((prevState) => ({
				content: [...prevState.content, newSection],
				currentTitle: "",
				currentParagraph: "",
			}));
		}
		e.preventDefault();
	};

	removeSections = (e) => {
		//CHANGE
		e.preventDefault();
		this.setState({
			content: [],
		});
	};

	handleChange = (e) => {
		if (this.state.imageCount < 3) {
			if (e.target.files[0]) {
				let image = e.target.files[0];
				let reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = () => {
					this.setState({
						previewImages: [...this.state.previewImages, reader.result],
					});
				};
				this.handleUpload(image);
			}
		}
	};

	handleUpload = (image) => {
		const data = new FormData();
		if (image) {
			const authHeader = encodeUser();

			data.append("productImage", image, image.name);
			axios
				.post(`${backendUrl}api/upload`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
						...authHeader,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Max size: 2MB");
							} else {
								// If not the given file type
								alert(response.data.error);
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								imageUrls: [...this.state.imageUrls, fileName.location],
								imageCount: this.state.imageCount + 1,
							});
							console.log("fileName", fileName.location);
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error, "red");
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	};

	changeText = (e) => {
		this.setState((prevState) => (e) => ({
			objeto: {
				...prevState.objeto,
				parrafo: e.target.value,
			},
		}));
	};

	changeParragraph = (e) => {
		this.setState((prevState) => ({
			objeto: {
				...prevState.objeto,
				parrafo: e.target.value,
			},
		}));
	};

	handleChangeCover = (e) => {
		if (e.target.files[0]) {
			let imageHover = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(imageHover);
			reader.onload = () => {
				// console.log(reader.result);
				this.setState({ previewImageHover: reader.result });
			};
			this.handleUploadCover(imageHover);
		}
	};

	handleUploadCover = (imageHover) => {
		const data = new FormData();
		if (imageHover) {
			const authHeader = encodeUser();
			data.append("productImageHover", imageHover, imageHover.name);
			axios
				.post(`${backendUrl}api/upload-hover`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
						...authHeader,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Max size: 2MB");
							} else {
								console.log(response.data);
								// If not the given file type
								// alert( response.data.error, 'red' );
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								coverImageUrl: fileName.location,
							});
							// console.log( 'fileName', fileName.location );
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error);
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	};

	parseToBoolean = (val) => {
		if (typeof val === "boolean") {
			return Boolean(val);
		} else if (val == "true") {
			return Boolean(true);
		} else {
			return Boolean(false);
		}
	};

	addBlogPost = (e) => {
		e.preventDefault();
		let {
			coverImageUrl,
			mainTitle,
			imageUrls,
			content,
			videoLink,
			slug,
		} = this.state;
		const thereAreImages = imageUrls && imageUrls.length > 0;
		const blogPost = {
			imageUrlSecond: thereAreImages ? imageUrls[0] : "",
			imageUrlThird: thereAreImages && imageUrls.length > 1 ? imageUrls[1] : "",
			imageUrlFourth:
				thereAreImages && imageUrls.length > 2 ? imageUrls[2] : "",
			coverImageUrl: coverImageUrl,
			mainTitle: mainTitle,
			videoLink: videoLink,
			content: content,
			createdAt: new Date(),
			slug: convertToSlug(slug) || convertToSlug(mainTitle),
		};
		if (
			mainTitle != "" ||
			(imageUrls && imageUrls.length > 0) ||
			coverImageUrl != ""
		) {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("blogs");
			dbOrderRef.add(blogPost).then((res) => {
				toast.success("Blog post fue creado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
				this.closeModal();
				this.resetForm();
			});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		let { previewImageHover, previewImages } = this.state;
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer className={containerStyle} />

					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar blog post</h3>

							<form onSubmit={this.addBlogPost}>
								*Nota: Es preferible que nombres de las fotos NO tengan tener ni
								"(" ni "/" caracteres.
								<div className="form-group">
									<label>
										Subir la imagen de cover aquí (Solamente 1 imagen)
									</label>
									<div className="upload-img">
										<span>
											<i className="bx bxs-image-add"></i>
											Seleccionar
										</span>

										<input
											type="file"
											className="form-control-file"
											name="productImageHover"
											onChange={this.handleChangeCover}
										/>
									</div>

									{previewImageHover ? (
										<div className="uploaded-img">
											<img
												src={previewImageHover}
												alt="Image"
												className="img-thumbnail"
											/>
										</div>
									) : null}
								</div>
								<div className="form-group">
									<label>
										Subir las imagenes para el contenido aquí (Hasta 3 imagen)
									</label>
									<div className="upload-img">
										<span>
											<i className="bx bxs-image-add"></i>
											Seleccionar
										</span>

										<input
											type="file"
											disabled={this.state.imageCount > 2}
											className="form-control-file"
											name="productImage"
											accept="image/*"
											onChange={this.handleChange}
										/>
									</div>

									{previewImages && previewImages.length > 0 ? (
										<div className="uploaded-img">
											{previewImages.map((e) => (
												<img src={e} alt="Image" className="img-thumbnail" />
											))}
										</div>
									) : null}
								</div>
								<h4 className="title">Agrega información del blog post aquí</h4>
								<div className="form-group">
									<label>Titulo principal</label>
									<input
										type="text"
										className="form-control"
										value={this.state.mainTitle}
										onChange={(e) =>
											this.setState({ mainTitle: e.target.value })
										}
									/>
								</div>
								<div className="form-group">
									<label>Url del post</label>
									<input
										type="text"
										className="form-control"
										value={this.state.slug}
										onChange={(e) =>
											this.setState({ slug: e.target.value })
										}
									/>
									<p>Previsualización: {convertToSlug(this.state.slug)}</p>
								</div>
								<div className="form-group">
									<label>
										Link a video (link completo a video en youtube. Ej:
										https://www.youtube.com/embed/bk7McNUjWgw{" "}
									</label>
									<input
										type="text"
										className="form-control"
										value={this.state.videoLink}
										onChange={(e) =>
											this.setState({ videoLink: e.target.value })
										}
									/>
								</div>
								<div className="form-group">
									<div>
										*Aca abajo va el contenido. El titulo es un subtitulo en
										negrita y el parrafo es lo que va a ir debajo. Agregá enters
										para separarlos.
									</div>
									<label>Titulo</label>
									<input
										type="text"
										className="form-control"
										value={this.state.currentTitle}
										onChange={(e) =>
											this.setState({ currentTitle: e.target.value })
										}
									/>

									<label>Párrafo</label>
									<textarea
										className="form-control"
										value={this.state.currentParagraph}
										onChange={(e) =>
											this.setState({ currentParagraph: e.target.value })
										}
										rows="5"
									/>

									<button onClick={this.addSection}>+</button>
									<button onClick={this.removeSections}>Borrar todo</button>
									<ul>
										{this.state.content && this.state.content.length > 0
											? this.state.content.map((i) => (
													<li>
														<strong>{i.title}</strong>
														<div className="content-paragraph">{i.text}</div>
													</li>
											  ))
											: "No agregaste contenido."}
									</ul>
								</div>
								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Blog Post
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(AddBlogPostModal);
