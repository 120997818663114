import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { firebase } from "../../../firebase";

class ViewModuleModal extends Component {
	state = {
		modal: false,
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	componentDidMount() {
		const db = firebase.firestore();
		this.setState({
			loading: true,
		});
		const dbOrderRef = db.collection("courses");
		let coursesArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				// console.log(res)
				res.forEach((doc) => {
					let coursesObj = doc.data();
					coursesObj.id = doc.id;
					coursesArray.push(coursesObj);
				});
				this.setState({
					courses: coursesArray,
					loading: false,
				});
				// this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}


	findCourse = (module) => {
		let found = null;
		const { courses } = this.state;
		if(courses && courses.length > 0 && module) {
			courses.forEach(c => {
				if(c.modules && c.modules && c.modules.length > 0)
				{
					c.modules.forEach(m => {
						if(m.moduleId == module.moduleId) {
							found = c;
						}
					})
				}
			})
		}
		return found;
	}

	render() {
		let { module } = this.props;
		return (
			<React.Fragment>
				{module && (
					<div
						className={`admin-product-modal view-product ${this.props.active}`}
					>
						<div className="modal-innter-content">
							<button type="button" className="close" onClick={this.closeModal}>
								<span aria-hidden="true">
									<i className="bx bx-x"></i>
								</span>
							</button>

							<div className="modal-body">
								<h3>Ver Módulo</h3>

								<div className="view-product-content">
									<div className="row align-items-center">
										<div className="col-lg-6">
											<div className="products-details-desc">
												<h3>{module.title}</h3>
												<p>
													<strong>{`Curso al que pertenece: ${
														this.findCourse(module)
															? this.findCourse(module).title
															: "Ninguno"
													}`}</strong>
												</p>
												<p>{`Descripción general: ${!module.mainDescription ? 'sin desripción.' : '' }`}</p>
												{module.mainDescription && (
													<textarea
														className="non-resizable-text"
														value={module.mainDescription}
														disabled
													/>
												)}

												<ul className="products-info">
													<li>
														<span>Fecha:</span>
														<Link to="#">
															<a onClick={(e) => e.preventDefault()}>
																{module.date}
															</a>
														</Link>
													</li>
													<li>
														<span>Hora inicio:</span>
														<Link to="#">
															<a onClick={(e) => e.preventDefault()}>
																{module.startTime}
															</a>
														</Link>
													</li>
													<li>
														<span>Hora fin:</span>
														<Link to="#">
															<a onClick={(e) => e.preventDefault()}>
																{module.endTime}
															</a>
														</Link>
													</li>
												</ul>
											</div>
										</div>

										<div className="col-lg-6">
											<div className="products-details-desc">
												<ul className="products-info">
													<li>
														<span>Secciones:</span>
														<ul>
															{module.content && module.content.length > 0
																? module.content.map((i) => (
																		<li>
																			<strong>{i.title}: </strong>
																			{i.text ? i.text : 'No hay descripción'}
																		</li>
																  ))
																: "No agregaste secciones."}
														</ul>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		courses: state.courses,
	};
};

export default connect(mapStateToProps, null)(ViewModuleModal);
