import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { firebase } from "../../../firebase";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addCategory } from "../../../store/actions/cartActions";
import { css } from "glamor";

const containerStyle = css({
	position: "relative",
});

class AddCategoryModal extends Component {
	state = {
		modal: false,
		title: "",
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	addCategory = (e) => {
		e.preventDefault();
		let { title } = this.state;

		const category = {
			title: title,
		};

		if (title != "") {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("categories");
			dbOrderRef.add(category).then((res) => {
				const { id } = res;
				category.id = id;
				this.props.addCategory(category);
				toast.success("La categoría fue creada exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
				this.closeModal();
				this.resetForm();
			});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
				<ToastContainer className={containerStyle} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar Categoría</h3>
              <label>*Esta categoría será para agrupar tus cursos.</label>

							<form onSubmit={this.addCategory}>
								<div className="form-group">
									<label>Nombre</label>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Categoría
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addCategory: (category) => {
			dispatch(addCategory(category));
		},
	};
};

export default connect(null, mapDispatchToProps)(withRouter(AddCategoryModal));
