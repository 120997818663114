import React, { useState, useEffect } from "react";
import axios from "axios";
import { css } from "glamor";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import useForm from "./userForm";
import OrderSummaryAlt from "./OrderSummaryAlt";
import { ToastContainer, toast } from "react-toastify";
import { ALL_COUNTRIES } from "../../constants/countries";
import { DOCUMENT_TYPES } from "../../constants/document_types";
import { addCoupon, removeCoupon } from "../../store/actions/cartActions";
import backendUrl from "../../constants/urls";
import { encodeUser, fetchCourses } from "../../methods/api-calls";

const containerStyle = css({
	position: "relative",
});

const QUOTA_AMOUNTS = [1, 2, 3, 4, 5, 6];

function CheckoutContent({
	course_id,
	amountOfPeople,
	addCoupon,
	removeCoupon,
	coupon,
	user,
}) {
	const [code, setCode] = useState("");
	const [course, setCourse] = useState(null);
	const [couponError, setCouponError] = useState("");
	const [loadingCoupon, setLoadingCoupon] = useState(false);

	useEffect(() => {
		return () => {
			removeCoupon();
		};
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const id = course_id;
			let courses = await fetchCourses();
			let data =
				courses && courses.length > 0
					? courses.filter((courses) => courses.id == id)
					: null;
			const c = data ? data[0] : null;
			setCourse(c);
		};
		fetchData();
	}, []);

	let stateSchema = {
		firstName: { value: "", error: "" },
		lastName: { value: "", error: "" },
		email: { value: "", error: "" },
		emailConfirmation: { value: "", error: "" },
		phone: { value: "", error: "" },
		country: { value: "Uruguay", error: "" },
		city: { value: "", error: "" },
		location: { value: "", error: "" },
		address: { value: "", error: "" },
		document: { value: "", error: "" },
		documentType: { value: "2", error: "" },
		firstName2: { value: "", error: "" },
		lastName2: { value: "", error: "" },
		email2: { value: "", error: "" },
		emailConfirmation2: { value: "", error: "" },
		phone2: { value: "", error: "" },
		country2: { value: "Uruguay", error: "" },
		city2: { value: "", error: "" },
		location2: { value: "", error: "" },
		address2: { value: "", error: "" },
		document2: { value: "", error: "" },
		documentType2: { value: "2", error: "" },
		firstName3: { value: "", error: "" },
		lastName3: { value: "", error: "" },
		email3: { value: "", error: "" },
		emailConfirmation3: { value: "", error: "" },
		phone3: { value: "", error: "" },
		country3: { value: "Uruguay", error: "" },
		city3: { value: "", error: "" },
		location3: { value: "", error: "" },
		address3: { value: "", error: "" },
		document3: { value: "", error: "" },
		documentType3: { value: "2", error: "" },
		payedBy: { value: "", error: "" },
	};

	if (user) {
		stateSchema = {
			firstName: { value: user.firstName || "", error: "" },
			lastName: { value: user.lastName || "", error: "" },
			email: { value: user.email || "", error: "" },
			phone: { value: user.phone || "", error: "" },
			country: { value: user.country || "Uruguay", error: "" },
			city: { value: user.city || "", error: "" },
			location: { value: user.location || "", error: "" },
			address: { value: user.address || "", error: "" },
			document: { value: user.document || "", error: "" },
			documentType: { value: user.documentType || "2", error: "" },

			firstName2: { value: "", error: "" },
			lastName2: { value: "", error: "" },
			email2: { value: "", error: "" },
			phone2: { value: "", error: "" },
			country2: { value: "Uruguay", error: "" },
			city2: { value: "", error: "" },
			location2: { value: "", error: "" },
			address2: { value: "", error: "" },
			document2: { value: "", error: "" },
			documentType2: { value: "2", error: "" },

			firstName3: { value: "", error: "" },
			lastName3: { value: "", error: "" },
			email3: { value: "", error: "" },
			phone3: { value: "", error: "" },
			country3: { value: "Uruguay", error: "" },
			city3: { value: "", error: "" },
			location3: { value: "", error: "" },
			address3: { value: "", error: "" },
			document3: { value: "", error: "" },
			documentType3: { value: "2", error: "" },

			payedBy: { value: "", error: "" },
		};
	}

	const validationStateSchema = {
		firstName: {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese un nombre válido.",
			},
		},

		lastName: {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese un apellido válido.",
			},
		},

		email: {
			required: true,
			validator: {
				regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				error: "Ingrese un email válido.",
			},
		},

		emailConfirmation: {
			required: true,
			validator: {
				regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				error: "Ingrese un email válido.",
			},
		},

		phone: {
			required: true,
			validator: {
				regEx: /^[+]{0,1}[0-9]*$/,
				error: "Ingrese un teléfono válido (ej: 099846151).",
			},
		},

		country: {
			required: true,
			validator: {
				regEx: /^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i,
				error: "Seleccione un país.",
			},
		},

		city: {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese una ciudad válida.",
			},
		},

		location: {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese un estado válido.",
			},
		},

		address: {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC0-9 _]+[,.]?[ ]?|[a-z\u00E0-\u00FC0-9 _]+['-]?)+$/i,
				error: "Ingrese un domicilio válido.",
			},
		},

		document: {
			required: true,
			validator: {
				regEx: /^[a-zA-Z0-9]+$/,
				error: "Ingrese un documento válido sin puntos ni guiones",
			},
		},

		documentType: {
			required: true,
			validator: {
				regEx: /^[0-9]+$/,
				error: "Ingrese un tipo de documento válido",
			},
		},
	};

	if (amountOfPeople > 1) {
		validationStateSchema.firstName2 = {
			required: true,
			validator: {
				regEx: /^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i,
				error: "Ingrese un nombre válido.",
			},
		};

		validationStateSchema.lastName2 = {
			required: true,
			validator: {
				regEx: /^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i,
				error: "Ingrese un apellido válido.",
			},
		};

		validationStateSchema.email2 = {
			required: true,
			validator: {
				regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				error: "Ingrese un email válido.",
			},
		};

		validationStateSchema.emailConfirmation2 = {
			required: true,
			validator: {
				regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				error: "Ingrese un email válido.",
			},
		};

		validationStateSchema.phone2 = {
			required: true,
			validator: {
				regEx: /^[+]{0,1}[0-9]*$/,
				error: "Ingrese un teléfono válido (ej: 099846151).",
			},
		};

		validationStateSchema.city2 = {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese una ciudad válida.",
			},
		};
		validationStateSchema.location2 = {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese un estado válido.",
			},
		};
		validationStateSchema.address2 = {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC0-9 _]+[,.]?[ ]?|[a-z\u00E0-\u00FC0-9 _]+['-]?)+$/i,
				error: "Ingrese un domicilio válido.",
			},
		};
		validationStateSchema.document2 = {
			required: true,
			validator: {
				regEx: /^[a-zA-Z0-9]*$/,
				error: "Ingrese un documento válido sin puntos ni guiones",
			},
		};
		validationStateSchema.documentType2 = {
			required: true,
			validator: {
				regEx: /^[0-9]+$/,
				error: "Ingrese un tipo de documento válido",
			},
		};
	}

	if (amountOfPeople > 2) {
		validationStateSchema.firstName3 = {
			required: true,
			validator: {
				regEx: /^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i,
				error: "Ingrese un nombre válido.",
			},
		};

		validationStateSchema.lastName3 = {
			required: true,
			validator: {
				regEx: /^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i,
				error: "Ingrese un apellido válido.",
			},
		};

		validationStateSchema.email3 = {
			required: true,
			validator: {
				regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				error: "Ingrese un email válido.",
			},
		};

		validationStateSchema.emailConfirmation3 = {
			required: true,
			validator: {
				regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				error: "Ingrese un email válido.",
			},
		};

		validationStateSchema.phone3 = {
			required: true,
			validator: {
				regEx: /^[+]{0,1}[0-9]*$/,
				error: "Ingrese un teléfono válido (ej: 099846151).",
			},
		};

		validationStateSchema.city3 = {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese una ciudad válida.",
			},
		};
		validationStateSchema.location3 = {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC]+[,.]?[ ]?|[a-z\u00E0-\u00FC]+['-]?)+$/i,
				error: "Ingrese un estado válido.",
			},
		};
		validationStateSchema.address3 = {
			required: true,
			validator: {
				regEx: /^([a-z\u00E0-\u00FC0-9 _]+[,.]?[ ]?|[a-z\u00E0-\u00FC0-9 _]+['-]?)+$/i,
				error: "Ingrese un domicilio válido.",
			},
		};
		validationStateSchema.document3 = {
			required: true,
			validator: {
				regEx: /^[a-zA-Z0-9]*$/,
				error: "Ingrese un documento válido sin puntos ni guiones",
			},
		};
		validationStateSchema.documentType3 = {
			required: true,
			validator: {
				regEx: /^[0-9]+$/,
				error: "Ingrese un tipo de documento válido",
			},
		};
	}

	const {
		state,
		handleOnChange,
		handleOnSubmit,
		disable,
		clearAll,
		setDisableAll,
		disableAll,
	} = useForm(stateSchema, validationStateSchema, handleSubmit, amountOfPeople);

	const errorStyle = {
		color: "red",
		fontSize: "13px",
	};

	function handleSubmit() {
		setDisableAll(true);
	}

	const clearCoupon = () => {
		setCode("");
		setCouponError("");
		removeCoupon();
	};

	const checkCoupon = async () => {
		setLoadingCoupon(true);
		if (code && course_id) {
			try {
				const res = await axios.post(
					`${backendUrl}api/coupons/check-valid-coupon`,
					{
						code: code,
						courseId: course_id,
					},
					{
						headers: encodeUser(),
					}
				);
				const { data } = res;
				if (data) {
					if (data.id && data.coupon) {
						toast.success("El cupón ingresado es válido. Confirme su inscripción.", {
							position: "bottom-left",
							autoClose: 1000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						const currentCoupon = data.coupon;
						currentCoupon.id = data.id;
						setCouponError("");
						addCoupon(currentCoupon);
						window.scrollTo({
							top: 0,
							left: 100,
							behavior: 'smooth'
						})
					} else {
						setCouponError("El cupón ingresado es inválido.");
					}
				}
			} catch (e) {
				setCouponError("No se puede verificar el cupón.");
			}
		} else {
			setCouponError("No se puede verificar el cupón.");
		}
		setLoadingCoupon(false);
	};

	return (
		<section className="checkout-area ptb-100">
			<div className="container">
				<ToastContainer className={containerStyle} />
				<form onSubmit={handleOnSubmit}>
					<div className="row">
						<div className="col-lg-6 col-md-12">
							<div className="billing-details">
								<h3 className="title">
									{amountOfPeople == 1
										? "COMPLETE SUS DATOS"
										: "DATOS DE LOS INSCRIPTOS"}
								</h3>
								<p style={{fontSize: '14px'}}>
									<i>
									<strong>Observación: </strong>
										Los campos <span style={{ color: "red" }}>*</span> son
										requeridos.{" "}
									</i>
								</p>
								{amountOfPeople > 1 && (
									<h5 className="subtitle">Integrante #1</h5>
								)}
								<div className="row billing-row">
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Nombre <span className="required">*</span>
											</label>
											<input
												disabled={disableAll}
												type="text"
												name="firstName"
												className="form-control"
												onChange={handleOnChange}
												value={state.firstName.value}
											/>
											{state.firstName.error && (
												<p style={errorStyle}>{state.firstName.error}</p>
											)}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Apellido <span className="required">*</span>
											</label>
											<input
												type="text"
												disabled={disableAll}
												name="lastName"
												className="form-control"
												onChange={handleOnChange}
												value={state.lastName.value}
											/>
											{state.lastName.error && (
												<p style={errorStyle}>{state.lastName.error}</p>
											)}
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<div className="form-group">
											<label>
												Email <span className="required">*</span>
											</label>
											<input
												type="email"
												disabled={disableAll}
												name="email"
												className="form-control"
												onChange={handleOnChange}
												value={state.email.value}
											/>
											{state.email.error && (
												<p style={errorStyle}>{state.email.error}</p>
											)}
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<div className="form-group">
											<label>
												Confirmación de email{" "}
												<span className="required">*</span>
											</label>
											<input
												type="email"
												disabled={disableAll}
												name="emailConfirmation"
												className="form-control"
												onChange={handleOnChange}
												value={state?.emailConfirmation?.value}
											/>
											{state?.emailConfirmation?.error && (
												<p style={errorStyle}>
													{state?.emailConfirmation?.error}
												</p>
											)}
											{state.email?.value &&
												state.emailConfirmation?.value &&
												state.email?.value !==
													state?.emailConfirmation?.value && (
													<p style={errorStyle}>Los correos no coinciden</p>
												)}
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<div className="form-group">
											<label>
												Celular <span className="required">*</span>
											</label>
											<input
												disabled={disableAll}
												type="text"
												name="phone"
												className="form-control"
												onChange={handleOnChange}
												value={state.phone?.value}
											/>
											{state.phone?.error && (
												<p style={errorStyle}>{state.phone?.error}</p>
											)}
										</div>
									</div>

									<div className="col-lg-12 col-md-6">
										<div className="form-group">
											<label>
												País <span className="required">*</span>
											</label>
											<div className="select-box">
												<select
													disabled={disableAll}
													className="form-control"
													onChange={handleOnChange}
													name="country"
													value={state.country?.value}
												>
													{ALL_COUNTRIES.map((z, i) => (
														<option key={i} value={z.name}>
															{z.name}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Ciudad <span className="required">*</span>
											</label>
											<input
												disabled={disableAll}
												type="text"
												name="city"
												className="form-control"
												onChange={handleOnChange}
												value={state.city?.value}
											/>
											{state.city?.error && (
												<p style={errorStyle}>{state.city.error}</p>
											)}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Estado / Localidad <span className="required">*</span>
											</label>
											<input
												disabled={disableAll}
												type="text"
												name="location"
												className="form-control"
												onChange={handleOnChange}
												value={state.location?.value}
											/>
											{state.location?.error && (
												<p style={errorStyle}>{state.location.error}</p>
											)}
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<div className="form-group">
											<label>
												Dirección <span className="required">*</span>
											</label>
											<input
												disabled={disableAll}
												type="text"
												name="address"
												className="form-control"
												onChange={handleOnChange}
												value={state.address?.value}
											/>
											{state.address?.error && (
												<p style={errorStyle}>{state.address.error}</p>
											)}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Tipo de documento <span className="required">*</span>
											</label>
											<div className="select-box">
												<select
													disabled={disableAll}
													className="form-control"
													onChange={handleOnChange}
													name="documentType"
													value={state.documentType?.value}
												>
													{DOCUMENT_TYPES.map((z, i) => (
														<option key={i} value={z.code}>
															{z.name}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Documento <span className="required">* </span>
											</label>
											<input
												disabled={disableAll}
												type="text"
												name="document"
												className="form-control"
												onChange={handleOnChange}
												value={state.document?.value}
											/>
											{state.document?.error && (
												<p style={errorStyle}>{state.document.error}</p>
											)}
										</div>
									</div>

									{amountOfPeople > 1 && (
										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<div className="pick-up-radio">
													<input
														type="radio"
														disabled={disableAll}
														id="pick-up-option-1"
														name="payedBy"
														onChange={handleOnChange}
														value={state.email.value}
														checked={
															state.payedBy.value &&
															state.payedBy.value == state.email.value
														}
													/>
													<label for="pick-up-option-1">
														Persona a cargo del pago
													</label>
												</div>
											</div>
										</div>
									)}
								</div>

								{amountOfPeople > 1 && (
									<div>
										<h5 className="subtitle">Integrante #2</h5>

										<div className="row billing-row">
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Nombre <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="firstName2"
														className="form-control"
														onChange={handleOnChange}
														value={state.firstName2.value}
													/>
													{state.firstName2.error && (
														<p style={errorStyle}>{state.firstName2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Apellido <span className="required">*</span>
													</label>
													<input
														type="text"
														disabled={disableAll}
														name="lastName2"
														className="form-control"
														onChange={handleOnChange}
														value={state.lastName2.value}
													/>
													{state.lastName2.error && (
														<p style={errorStyle}>{state.lastName2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>
														Email <span className="required">*</span>
													</label>
													<input
														type="email"
														disabled={disableAll}
														name="email2"
														className="form-control"
														onChange={handleOnChange}
														value={state.email2.value}
													/>
													{state.email2.error && (
														<p style={errorStyle}>{state.email2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>
														Confirmación de email{" "}
														<span className="required">*</span>
													</label>
													<input
														type="email"
														disabled={disableAll}
														name="emailConfirmation2"
														className="form-control"
														onChange={handleOnChange}
														value={state.emailConfirmation2?.value}
													/>
													{state.emailConfirmation2?.error && (
														<p style={errorStyle}>
															{state.emailConfirmation2.error}
														</p>
													)}
													{state.email2.value &&
														state.emailConfirmation2?.value &&
														state.email2.value !==
															state.emailConfirmation2?.value && (
															<p style={errorStyle}>Los correos no coinciden</p>
														)}
												</div>
											</div>

											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>
														Celular <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="phone2"
														className="form-control"
														onChange={handleOnChange}
														value={state.phone2?.value}
													/>
													{state.phone2?.error && (
														<p style={errorStyle}>{state.phone2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-6">
												<div className="form-group">
													<label>
														País <span className="required">*</span>
													</label>
													<div className="select-box">
														<select
															disabled={disableAll}
															className="form-control"
															onChange={handleOnChange}
															defaultValue="Uruguay"
															name="country2"
															value={state.country2?.value}
														>
															{ALL_COUNTRIES.map((z, i) => (
																<option key={i} value={z.name}>
																	{z.name}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Ciudad <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="city2"
														className="form-control"
														onChange={handleOnChange}
														value={state.city2?.value}
													/>
													{state.city2?.error && (
														<p style={errorStyle}>{state.city2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Estado / Localidad{" "}
														<span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="location2"
														className="form-control"
														onChange={handleOnChange}
														value={state.location2?.value}
													/>
													{state.location2?.error && (
														<p style={errorStyle}>{state.location2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-6">
												<div className="form-group">
													<label>
														Dirección <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="address2"
														className="form-control"
														onChange={handleOnChange}
														value={state.address2?.value}
													/>
													{state.address2?.error && (
														<p style={errorStyle}>{state.address2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Tipo de documento{" "}
														<span className="required">*</span>
													</label>
													<div className="select-box">
														<select
															disabled={disableAll}
															className="form-control"
															onChange={handleOnChange}
															name="documentType2"
															value={state.documentType2?.value}
														>
															{DOCUMENT_TYPES.map((z, i) => (
																<option key={i} value={z.code}>
																	{z.name}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Documento <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="document2"
														className="form-control"
														onChange={handleOnChange}
														value={state.document2?.value}
													/>
													{state.document2?.error && (
														<p style={errorStyle}>{state.document2.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<div className="pick-up-radio">
														<input
															disabled={disableAll}
															type="radio"
															id="pick-up-option-1"
															name="payedBy"
															onChange={handleOnChange}
															value={state.email2.value}
															checked={
																state.payedBy.value &&
																state.payedBy.value == state.email2.value
															}
														/>
														<label for="pick-up-option-1">
															Persona a cargo del pago
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}

								{amountOfPeople > 2 && (
									<div>
										<h5 className="subtitle">Integrante #3</h5>

										<div className="row billing-row">
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Nombre <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="firstName3"
														className="form-control"
														onChange={handleOnChange}
														value={state.firstName3.value}
													/>
													{state.firstName3.error && (
														<p style={errorStyle}>{state.firstName3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Apellido <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="lastName3"
														className="form-control"
														onChange={handleOnChange}
														value={state.lastName3.value}
													/>
													{state.lastName3.error && (
														<p style={errorStyle}>{state.lastName3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>
														Email <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="email"
														name="email3"
														className="form-control"
														onChange={handleOnChange}
														value={state.email3.value}
													/>
													{state.email3.error && (
														<p style={errorStyle}>{state.email3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>
														Confirmación de email{" "}
														<span className="required">*</span>
													</label>
													<input
														type="email"
														disabled={disableAll}
														name="emailConfirmation3"
														className="form-control"
														onChange={handleOnChange}
														value={state.emailConfirmation3?.value}
													/>
													{state.emailConfirmation3?.error && (
														<p style={errorStyle}>
															{state.emailConfirmation3.error}
														</p>
													)}
													{state.email3.value &&
														state.emailConfirmation3?.value &&
														state.email3.value !==
															state.emailConfirmation3?.value && (
															<p style={errorStyle}>Los correos no coinciden</p>
														)}
												</div>
											</div>

											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>
														Celular <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="phone3"
														className="form-control"
														onChange={handleOnChange}
														value={state.phone3?.value}
													/>
													{state.phone3?.error && (
														<p style={errorStyle}>{state.phone3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-6">
												<div className="form-group">
													<label>
														País <span className="required">*</span>
													</label>
													<div className="select-box">
														<select
															disabled={disableAll}
															className="form-control"
															onChange={handleOnChange}
															name="country3"
															defaultValue="Uruguay"
															value={state.country3?.value}
														>
															{ALL_COUNTRIES.map((z, i) => (
																<option key={i} value={z.name}>
																	{z.name}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Ciudad <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="city3"
														className="form-control"
														onChange={handleOnChange}
														value={state.city3?.value}
													/>
													{state.city3?.error && (
														<p style={errorStyle}>{state.city3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Estado / Localidad{" "}
														<span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="location3"
														className="form-control"
														onChange={handleOnChange}
														value={state.location3?.value}
													/>
													{state.location3?.error && (
														<p style={errorStyle}>{state.location3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>
														Dirección <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="address3"
														className="form-control"
														onChange={handleOnChange}
														value={state.address3?.value}
													/>
													{state.address3?.error && (
														<p style={errorStyle}>{state.address3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Tipo de documento{" "}
														<span className="required">*</span>
													</label>
													<div className="select-box">
														<select
															disabled={disableAll}
															className="form-control"
															onChange={handleOnChange}
															name="documentType3"
															value={state.documentType3?.value}
														>
															{DOCUMENT_TYPES.map((z, i) => (
																<option key={i} value={z.code}>
																	{z.name}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>
														Documento <span className="required">*</span>
													</label>
													<input
														disabled={disableAll}
														type="text"
														name="document3"
														className="form-control"
														onChange={handleOnChange}
														value={state.document3?.value}
													/>
													{state.document3?.error && (
														<p style={errorStyle}>{state.document3.error}</p>
													)}
												</div>
											</div>

											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<div className="pick-up-radio">
														<input
															disabled={disableAll}
															type="radio"
															id="pick-up-option-1"
															name="payedBy"
															onChange={handleOnChange}
															value={state.email3?.value}
															checked={
																state.payedBy?.value &&
																state.payedBy.value == state.email3?.value
															}
														/>
														<label for="pick-up-option-1">
															Persona a cargo del pago
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}

								<div className="row billing-row">
									{coupon ? (
										<div className="col-lg-12 col-md-6">
											<p>Cupón aplicado:</p>
											<div className="row">
												<input
													disabled={true}
													value={coupon.code}
													className="coupon"
												/>

												<button
													type="button"
													disabled={disableAll}
													className="default-btn"
													onClick={clearCoupon}
												>
													BORRAR
												</button>
											</div>
											<div className="row">
												{couponError && <p style={errorStyle}>{couponError}</p>}
											</div>
										</div>
									) : (
										<div className="col-lg-12 col-md-6">
											<p>
												Tienes un cupón de descuento? <strong>Ingrésalo</strong>
											</p>
											<div className="row">
												<input
													disabled={disableAll}
													className="coupon"
													value={code}
													onChange={(e) => setCode(e.target.value)}
												/>

												<button
													disabled={disableAll}
													type="button"
													className="default-btn"
													onClick={checkCoupon}
												>
													Aplicar
												</button>
											</div>
											<div className="row">
												{couponError && <p style={errorStyle}>{couponError}</p>}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<OrderSummaryAlt
							disabled={disable}
							stateData={state}
							course={course}
							course_id={course_id}
							amountOfPeople={amountOfPeople}
							onFinish={clearAll}
							disableFields={handleSubmit}
						/>
					</div>
				</form>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => {
	return {
		coupon: state.coupon,
		user: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addCoupon: (coupon) => {
			dispatch(addCoupon(coupon));
		},
		removeCoupon: () => {
			dispatch(removeCoupon());
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CheckoutContent)
);
