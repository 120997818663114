import React from "react";
import "./styles.scss";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { Router } from "./routes";
import GoTop from "./components/Shared/GoTop";

import ReactPixel from "react-facebook-pixel";

const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};
ReactPixel.init("688806225080480", {}, options);
ReactPixel.pageView(); // For tracking page view

const App = () => (
	<React.Fragment>
		<PayPalScriptProvider options={{ "client-id": "AVgeP89B-1HZCv5akZ7_vsBhyQyawrCGeEfD4WPqPnrP79uP2-DOkj0gvz3lVzOgPqaWMB9KKG8ScI48" }}>
			<Router />
			<GoTop scrollStepInPx="100" delayInMs="10.50" />
		</PayPalScriptProvider>
	</React.Fragment>
);

export { App };
