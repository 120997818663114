import React, { Component } from 'react';
import ColoredNavbar from '../components/Layouts/ColoredNavbar';
import PageBanner from '../components/Common/PageBanner';
import ContactFormMain from '../components/Contact/ContactFormMain';
import FacilitySlider from '../components/Common/FacilitySlider';
import InstagramFeed from '../components/Common/InstagramFeed';
import Footer from '../components/Layouts/Footer';

class ContactPage extends Component {
    render() {
        return (
            <div className="contact-page">
            <React.Fragment>
                <ColoredNavbar />
                <ContactFormMain />
                <Footer />
            </React.Fragment>
            </div>
        );
    }
}

export {ContactPage};