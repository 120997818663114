export const PAYMENT_TYPES = {
	//Siemprepago
	CreditCard: "CreditCard",
	DebitCard: "DebitCard",
	PhysicalNetwork: "PhysicalNetwork",
	PrePaid: "PrePaid",

	//Mercadopago
	CREDIT_CARD: "CREDIT_CARD",
	DEBIT_CARD: "DEBIT_CARD",
	COBRANZA: "TICKET",

	credit_card: "credit_card",
	debit_card: "debit_card",
	cobranza: "ticket",

};

export const PAYMENT_TRANSLATIONS = [
	{
		id: PAYMENT_TYPES.credit_card,
		label: "Tarj. crédito",
	},
	{
		id: PAYMENT_TYPES.debit_card,
		label: "Tarj. débito",
	},
	{
		id: PAYMENT_TYPES.cobranza,
		label: "Red de cobranza",
	},
	{
		id: PAYMENT_TYPES.CREDIT_CARD,
		label: "Tarj. crédito",
	},
	{
		id: PAYMENT_TYPES.CreditCard,
		label: "Tarj. crédito",
	},
	{
		id: PAYMENT_TYPES.DEBIT_CARD,
		label: "Tarj. débito",
	},
	{
		id: PAYMENT_TYPES.DebitCard,
		label: "Tarj. débito",
	},
	{
		id: PAYMENT_TYPES.COBRANZA,
		label: "Red de cobranza",
	},
	{
		id: PAYMENT_TYPES.PhysicalNetwork,
		label: "Red de cobranza",
	},
	{
		id: PAYMENT_TYPES.PrePaid,
		label: "Tarj. prepaga",
	},
];

export const setOrderStatus = (type, inProcess) => {
	if (!type) {
		return "approved"
	}
	return PAYMENT_TYPES[type] == PAYMENT_TYPES.COBRANZA || inProcess ? "pending" : "approved"
}


const extractStatusFromUrl = (url) => {
	const firstPart = url.split('collection_status=');
	if (firstPart && firstPart.length > 0) {
		const secondPart = firstPart[1].split('&');
		if (secondPart && secondPart.length > 0) {
			return secondPart[0];
		}
	}
	return null;
}


export const extractPaymentIdFromUrl = (url) => {
	const firstPart = url.split('payment_id=');
	const altFirstPart = url.split('payment_type=');
	let paymentId = null;
	let paymentType = null;
	if (firstPart && firstPart.length > 0) {
		const secondPart = firstPart[1].split('&');
		if (secondPart && secondPart.length > 0) {
			paymentId = secondPart[0];
		}
	}
	if (altFirstPart && altFirstPart.length > 0) {
		const secondPart = altFirstPart[1].split('&');
		if (secondPart && secondPart.length > 0) {
			paymentType = secondPart[0];
		}
	}
	return { paymentId: paymentId, paymentType: paymentType };
}

export const selectPaymentType = (value) => {
	const extracted = value;
	if (extracted) {
		return Object.keys(PAYMENT_TYPES).find(key => PAYMENT_TYPES[key] === extracted);
	} else {
		return null;
	}
};


export const selectPaymentStatus = (value) => {
	const extracted = extractStatusFromUrl(value);
	if (extracted) {
		return extracted;
	} else {
		return null;
	}
};