import React, { Component } from "react";
import { Link } from "react-router-dom";

class Offer extends Component {
	render() {
		return (
			<section
				className="offer-area bg-image2 ptb-100 jarallax"
				data-jarallax='{"speed": 0.3}'
			>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-7 col-md-6">
							<div className="offer-content">
								<span className="sub-title">
									TALLERES PRESENCIALES A DISTANCIA
								</span>
								<h4 style={{fontWeight: 'bold'}}>¿Cómo son?</h4>
								<p style={{color: '#2b2b2b'}}>
									Los talleres en formato “Presencial a Distancia” a través de
									Zoom conservan el carácter práctico y toda la riqueza de los
									talleres presenciales.
								</p>
								<Link to="/contact">
									<a className="default-btn">Consultar</a>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Offer;
