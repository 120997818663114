import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditTestimonyModal from "../../components/Admin/Modals/EditTestimonyModal";
import withAuth from "../../helpers/withAuth";

class Testimonies extends Component {
	state = {
		display: false,
		testimonies: [],
		loading: true,
		showEditModal: false,
		modalTestimony: null,
	};

	toggleEditTestimonyModal = (banner = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalTestimony: banner,
		});
	};

	dataModal = (banner) => {
		this.setState({
			modalTestimony: banner,
		});
	};

	deleteTestimony = (testimony) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("testimonies");
		dbOrderRef
			.doc(testimony.id)
			.delete()
			.then(() => {
				// Delete completed!
				toast.success("El testimonio fue eliminado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("testimonies");
		let testimoniesArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let testimoniesObj = doc.data();
					testimoniesObj.id = doc.id;
					testimoniesArray.push(testimoniesObj);
				});
				testimoniesArray = testimoniesArray.sort((p1, p2) => {
					if (p1.priority && !p2.priority) {
						return -1;
					} else if (!p1.priority && p2.priority) {
						return 1;
					} else {
						return p2.priority - p1.priority;
					}
				});
				this.setState({
					testimonies: testimoniesArray,
					loading: false,
				});
				// this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { testimonies, loading } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Testimonios</h2>
					</div>

					<p style={{ marginBottom: "0px" }}>
						{" "}
						Nota: Los testimonios con mayor prioridad aparecerán primero.
					</p>

					<div className="admin-products">
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : testimonies.length ? (
								testimonies.map((testimony, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
											<div className="products-image no-centered-short  products-content">
												<Link to="#viewproduct">
													<a
														onClick={(e) => {
															e.preventDefault();
															this.dataModal(testimony);
														}}
													></a>
												</Link>

												<h3>{`${testimony.name} ${
													testimony.year ? `, ${testimony.year}` : ""
												}`}</h3>
												<p>
													<i>{`"${testimony.content}"`}</i>
												</p>
												<h5>
													Prioridad: {testimony.priority || "sin prioridad"}
												</h5>
												<div className="products-button special-btn">
													<ul className="horizontal-menu">
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditTestimonyModal(testimony);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() =>
																		this.deleteTestimony(testimony)
																	}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay testimonios.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditTestimonyModal
						onClick={this.toggleEditTestimonyModal}
						active={this.state.showEditModal ? "active" : ""}
						testimony={this.state.modalTestimony}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withAuth(Testimonies);
