import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { firebase } from "../../../firebase";
import axios from "axios";
import { css } from "glamor";

import backendUrl from "../../../constants/urls";
import { encodeUser } from "../../../methods/api-calls";


const containerStyle = css({
	position: "relative",
});

class OrderUpdateModal extends Component {
	state = {
		modal: false,
		selected: "",
		courseSelected: "",
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		let time = t.setSeconds(d.seconds);
		const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(time);
		const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
		const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
		return `${da}-${mo}-${ye}`;
	};

	componentDidMount() {
		const { order } = this.props;
		this.setState({
			selected: order.status,
			courseSelected: order.courseStatus,
		});
	}

	handleUpdate = (e) => {
		e.preventDefault();
		const { order } = this.props;

		if (this.state.selected === "") {
			return false;
		} else if (order) {
			//SEND EMAIL!
			const data = {
				order,
			};
			const db = firebase.firestore();
			const dbOrderRef = db.collection("orders");
			dbOrderRef
				.doc(this.props.order.id)
				.update({
					status: this.state.selected,
					courseStatus: this.state.courseSelected,
				})
				.then(async (r) => {
					if (
						this.state.selected === "approved" &&
						order.status != "approved"
					) {
						try {
							const response = await axios.post(
								`${backendUrl}api/mailer/confirm`,
								data, {
									headers: encodeUser()
								}
							);

							if (200 === response.status) {
								// If file size is larger than expected.
								if (response.data.error) {
									// If not the given file type
									alert(response.data.error);
								} else {
									// Success
									console.log("email sent");
								}
							}
						} catch (error) {
							// If another error
							alert(error, "red");
						}
					}
					if (
						this.state.courseSelected === "approved" &&
						order.courseStatus != "approved"
					) {
						try {
							const data2 = {
								to: order.customerDetails?.email,
								orderId: order.id,
								courseName: order.items?.length > 0 ? order.items[0].title : '', 
							};
							const response = await axios.post(
								`${backendUrl}api/mailer/send-certificate`,
								data2, {
									headers: encodeUser()
								}
							);


							if (200 === response.status) {
								// If file size is larger than expected.
								if (response.data.error) {
									// If not the given file type
									alert(response.data.error);
								} else {
									// Success
									console.log("email sent");
									toast.success("Estado cambiado a aprobado", {
										position: "bottom-left",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									});
								}
							}
						} catch (error) {
							// If another error
							alert(error, "red");
						}
					} else {
						toast.success("Estado cambiado correctamente", {
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						setTimeout(() => {
							this.closeModal();
							window.location.reload();
						}, 1000);
					}


					setTimeout(() => {
						this.closeModal();
						window.location.reload();
					}, 1000);
				});
		}
	};

	render() {
		const { order } = this.props;
		const products = order && order.items ? Object.values(order.items) : null;
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
				<ToastContainer className={containerStyle} />

					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Ver/Cambiar estado</h3>

							<div>
								<label>
									<strong>Curso</strong>
								</label>
								{order &&
									products &&
									products.length > 0 &&
									products.map((i) => (
										<div className="my-product-box">
											<div className="my-row">
												<div className="my-value">{i.title}</div>
											</div>
										</div>
									))}
								{/* <label>
									<strong>Comprobante:</strong>
								</label>
								<div>
									<img
										src={order.voucherUrl ? order.voucherUrl : noPhoto}
										className="voucher-image"
										alt="image"
									/>
								</div> */}
							</div>

							<form onSubmit={this.handleUpdate}>
								<div className="form-group">
									<label>Estado del pago</label>
									<select
										className="form-control"
										id="product-type"
										value={this.state.selected || this.props.order.status}
										onChange={(e) =>
											this.setState({ selected: e.target.value })
										}
									>
										<option>Seleccione</option>
										<option value="approved">Aprobado</option>
										<option value="pending">Pendiente</option>
										<option value="cancel">Rechazado</option>
									</select>
								</div>

								<div className="form-group">
									<label>Aprobación del curso</label>
									<select
										className="form-control"
										id="product-type"
										value={
											this.state.courseSelected || this.props.order.courseStatus
										}
										onChange={(e) =>
											this.setState({ courseSelected: e.target.value })
										}
									>
										<option>Seleccione</option>
										<option value="approved">Aprobado</option>
										<option value="pending">Pendiente</option>
										<option value="cancel">Perdido</option>
									</select>
								</div>

								<div className="form-group">
									<label>Link de aprobación de curso: </label>

									<p>
										{this.state.courseSelected === "approved" ? (
											<a
												target="_blank"
												href={`https://gabrielvallone.com.uy/certificados/${order.id}`}
											>{`https://gabrielvallone.com.uy/certificados/${order.id}`}</a>
										) : (
											"Debes aprobar el curso para ver el link al certificado"
										)}
									</p>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Cambiar estado
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default OrderUpdateModal;
