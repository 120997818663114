import React, { Component } from "react";
import { Link } from "react-router-dom";
import video_1 from "../../videos/course_2-alt.mp4";
import video_2 from "../../videos/course_1-alt.mp4";
import photo_1 from "../../videos/course_2-alt-photo.png";
import photo_2 from "../../videos/course_1-alt-photo.png";
import { fetchCourses } from "../../methods/api-calls";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import dateformat from "dateformat";
import ReactPlayer from 'react-player/lazy'

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

const regularStyle = {
	margin: "0px",
	color: "white",
	paddingRight: "5px",
	paddingLeft: "5px",
	color: "#184D5E",
};

const mobileStyle = {
	margin: "15px 5px 0px 0px",
	color: "#184D5E",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

class Categories extends Component {
	constructor(props) {
		super(props);
		this.state = {
			courses: null,
			loading: true,
		};
	}

	async componentDidMount() {
		this.setState({
			loading: true,
		});
		let courses = await fetchCourses();

		if (courses) {
			//OLD SORTING:
			// courses = courses.sort((p1, p2) => {
			// 	if (p1.modules?.length > 0 && p2.modules?.length > 0) {
			// 		if (p1.modules[0]?.date && !p2.modules[0]?.date) {
			// 			return -1;
			// 		} else if (!p1.modules[0]?.date && p2.modules[0]?.date) {
			// 			return 1;
			// 		} else {
			// 			return (
			// 				new Date(p1.modules[0]?.date) - new Date(p2.modules[0]?.date)
			// 			);
			// 		}
			// 	} else {
			// 		if (p1.modules?.length > 0 && !p2.modules?.length > 0) {
			// 			return -1;
			// 		} else if (!p1.modules?.length > 0 && !p2.modules?.length > 0) {
			// 			return 1;
			// 		}
			// 	}
			// });

			//NEW SORTING:
			courses = courses.sort((p1, p2) => {
				if (p1.priority && !p2.priority) {
					return -1;
				} else if (!p1.priority && p2.priority) {
					return 1;
				} else {
					return p2.priority - p1.priority;
				}
			});
		}

		var toRender = [];
		if (courses && courses.length > 1) {
			toRender = [courses[0], courses[1]];
		} else {
			toRender = courses;
		}
		this.setState({
			courses: toRender,
			loading: false,
		});
	}

	comingSoon = (course) => {
		let allHaveDates = true;
		let noDatesSet = false;

		if (course?.modules?.length > 0) {
			course.modules.forEach((m) => {
				if (!m.date) {
					allHaveDates = false;
				}
			});
		}

		noDatesSet =
			!course ||
			(course && !course.modules) ||
			!course?.modules?.length > 0 ||
			!allHaveDates;

		return noDatesSet;
	};

	getCourseDates = (course) => {
		let dates = [];
		course.modules &&
			course.modules.length > 0 &&
			course.modules.map((a) => {
				const oldDate = a.date && a.date.length ? a.date.split("-") : null;

				if (oldDate && oldDate.length > 0) {
					const newDate = oldDate.slice(1, 3);
					newDate.push(oldDate[0]);
					let newest = new Date();
					newest.setDate(newDate[1]);
					newest.setMonth(newDate[0] - 1);
					newest.setFullYear(newDate[2]);
					// let date = new Date(newDate);
					// console.log(newDate, date, newest)

					let formatted = dateformat(newest, "d/mm");
					dates.push(formatted);
				}
			});
		return dates.join(", ");
	};

	render() {
		var isMobile = false;
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}
		return (
			<section className="categories-banner-area pt-100 pb-70">
				<div className="container">
					<div className="section-title">
						<h2 className="text-white">CURSOS</h2>
					</div>
					<div className="row row-justify-center">
						{this.state.courses && this.state.courses.length > 0 ? (
							this.state.courses.map((b, index) => (
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-categories-box">
										{isMobile ? (
											<img
												className="course-presentation"
												src={index % 2 == 0 ? photo_1 : photo_2}
											/>
										) : (index % 2 == 0 ? (

											<ReactPlayer
												playing={true}
												className="course-presentation"
												muted={true}
												loop={true}
												playsinline={true}
												controls={false}
												volume={0}
												url={video_1} />
										) : (
											<ReactPlayer
												playing={true}
												className="course-presentation"
												muted={true}
												loop={true}
												playsinline={true}
												controls={false}
												volume={0}
												url={video_2} />
										))}
										<div className="content text-blue">
											{b.header && (
												<span style={{ marginBottom: '22px' }}>
													<strong style={{ color: "#E6B966" }}>
														{b.header?.toUpperCase()}
													</strong>
												</span>
											)}
											<span>
												{this.comingSoon(b) ? (
													<strong>PRÓXIMAMENTE</strong>
												) : b.modules?.length > 1 ? (
													`${b.modules?.length} módulos / ${b.totalDuration == 1
														? `${b.totalDuration} hora`
														: `${b.totalDuration} horas`
													}`
												) : b.modules?.length == 1 ? (
													`${b.modules?.length} módulo / ${b.totalDuration == 1
														? `${b.totalDuration} hora`
														: `${b.totalDuration} horas`
													}`
												) : (
													`${b.totalDuration == 1
														? `${b.totalDuration} hora`
														: `${b.totalDuration} horas`
													}`
												)}
											</span>
											<h3>{b.title}</h3>
											<div className="description">{b.shortDescription}</div>
											<div
												className="row"
												style={{
													display: "flex",
													justifyContent:
														!isMobile && !this.comingSoon(b)
															? "space-between"
															: "flex-end",
												}}
											>
												{!this.comingSoon(b) && (
													<p style={isMobile ? mobileStyle : regularStyle}>
														<strong>
															{b && b.modules?.length > 1
																? "Fechas:"
																: "Fecha:"}
														</strong>{" "}
														{this.getCourseDates(b)}
													</p>
												)}

												{(!isMobile || this.comingSoon(b)) && (
													<Link to={"/cursos/" + b.slug}>
														<a className="default-btn">Leer más</a>
													</Link>
												)}
											</div>
										</div>
										<Link to={"/cursos/" + b.slug}>
											<a
												className={isMobile ? "mobile-link-btn" : "link-btn"}
											></a>
										</Link>
									</div>
								</div>
							))
						) : this.state.loading ? (
							<div className="loader-background">
								<BeatLoader
									css={override}
									size={isMobile ? 50 : 100}
									color={"#4d7c8a"}
									loading={true}
								/>
							</div>
						) : (
							<div className="no-blogs">No hay cursos. </div>
						)}
					</div>
					<div className="row row-justify-center">
						<Link to={"/cursos"}>
							<button className="default-btn text-white see-all-courses">
								Ver todos
							</button>
						</Link>
					</div>
				</div>
			</section>
		);
	}
}

export default Categories;
