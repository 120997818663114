import React, { useState } from "react";
import { Link } from "react-router-dom";

const CategoriesBanner = () => {
	const [over1, setOver1] = useState(false);
	const [over2, setOver2] = useState(false);
	const [over3, setOver3] = useState(false);
	const [over4, setOver4] = useState(false);

	return (
		<section className="categories-banner-area-2 pt-100 pb-70">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6 col-md-12">
						<div className="categories-box"
							onMouseOver={() => setOver3(true)}
							onMouseOut={() => setOver3(false)}>
							<img
								src={over3 ? require("../../images/values/gabriel-color.jpg") : require("../../images/values/gabriel.jpg")}
								alt="image"
								className="bordered"
							/>

							<div className="content">
								<h3>CONFIANZA</h3>
							</div>
							<a className="link-btn"></a>
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6">
								<div className="categories-box"
									onMouseOver={() => setOver4(true)}
									onMouseOut={() => setOver4(false)}>
									<img
										src={over4 ? require("../../images/values/pins2-color.jpg") : require("../../images/values/pins2.jpg")}
										alt="image"
										className="bordered"
									/>

									<div className="content">
										<h3>OBJETIVOS</h3>
									</div>

									<a className="link-btn"></a>
								</div>
							</div>

							<div className="col-lg-6 col-md-6 col-sm-6">
								<div className="categories-box"
									onMouseOver={() => setOver2(true)}
									onMouseOut={() => setOver2(false)}>
									<img
										src={over2 ? require("../../images/values/graduation-color.jpg") : require("../../images/values/graduation.jpg")}
										alt="image"
										className="bordered"
									/>

									<div className="content">
										<h3>PALABRA</h3>
									</div>

									<a className="link-btn"></a>
								</div>
							</div>

							<div className="col-lg-12 col-md-12">
								<div className="categories-box"
									onMouseOver={() => setOver1(true)}
									onMouseOut={() => setOver1(false)}>
									<img
										src={over1 ? require("../../images/values/domino2-color.jpg") : require("../../images/values/domino2-copy.jpg")}
										alt="image"
										className="bordered"
									/>

									<div className="content">
										<h3>RESOLUCIÓN</h3>
									</div>

									<a className="link-btn"></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default CategoriesBanner;
