import {
	ADD_TO_CART,
	REMOVE_ITEM,
	SUB_QUANTITY,
	ADD_QUANTITY,
	ADD_QUANTITY_WITH_NUMBER,
	RESET_CART,
	ADD_COURSES,
	ADD_SINGLE_COURSE,
	ADD_SINGLE_PROFESSOR,
	EDIT_COURSE,
	ADD_SINGLE_ORDER,
	EDIT_CATEGORY,
	EDIT_PROFESSOR,
	ADD_CATEGORY,
	REMOVE_COURSE,
	REMOVE_PROFESSOR,
	REMOVE_CATEGORY,
	ADD_FEED,
	ADD_COURSE_WITH_NUMBER_OF_PEOPLE,
	ADD_MODULE,
	LOGIN_USER,
	ADD_COUPON,
	REMOVE_COUPON,
	ADD_MERCADOPAGO_ITEMS,
	REMOVE_MERCADOPAGO_ITEMS,
	UPDATE_USER,
	LOGOUT_USER,
} from "./action-types/cart-actions";

export const loginUser = (user) => {
	return {
		type: LOGIN_USER,
		user,
	};
};

export const updateUser = (user) => {
	return {
		type: UPDATE_USER,
		user,
	};
};

export const logoutUser = () => {
	return {
		type: LOGOUT_USER,
		user: null,
	};
};

//add feed
export const addFeed = (feed) => {
	return {
		type: ADD_FEED,
		feed,
	};
};

//add mpago items
export const addMercadoPagoItems = (items) => {
	return {
		type: ADD_MERCADOPAGO_ITEMS,
		items,
	};
};

//remove mpago items
export const removeMercadoPagoItems = () => {
	return {
		type: REMOVE_MERCADOPAGO_ITEMS,
	};
};

//add coupons
export const addCoupon = (coupon) => {
	return {
		type: ADD_COUPON,
		coupon,
	};
};

//add coupons
export const removeCoupon = () => {
	return {
		type: REMOVE_COUPON,
	};
};

//add products
export const addCourses = (courses) => {
	return {
		type: ADD_COURSES,
		courses,
	};
};

//add module to course
export const addModuleToCourse = (module) => {
	return {
		type: ADD_MODULE,
		module,
	};
};

//add category for course
export const addCategory = (category) => {
	return {
		type: ADD_CATEGORY,
		category,
	};
};

//add single product
export const addSingleCourse = (course) => {
	return {
		type: ADD_SINGLE_COURSE,
		course,
	};
};

//add single order
export const addSingleOrder = (order) => {
	return {
		type: ADD_SINGLE_ORDER,
		order,
	};
};

//edit single course
export const editCourse = (course) => {
	return {
		type: EDIT_COURSE,
		course,
	};
};

//edit category
export const editCategory = (category) => {
	return {
		type: EDIT_CATEGORY,
		category,
	};
};

export const editProfessor = (professor) => {
	return {
		type: EDIT_PROFESSOR,
		professor,
	};
};

//add single professor
export const addSingleProfessor = (professor) => {
	return {
		type: ADD_SINGLE_PROFESSOR,
		professor,
	};
};

//remove single product
export const removeCourse = (id) => {
	return {
		type: REMOVE_COURSE,
		id,
	};
};

//remove category
export const removeCategory = (id) => {
	return {
		type: REMOVE_CATEGORY,
		id,
	};
};

//remove professor
export const removeProfessor = (id) => {
	return {
		type: REMOVE_PROFESSOR,
		id,
	};
};

//add cart action
// DO NOT USE THIS FUNCTION
export const addToCart = (id) => {
	return {
		type: ADD_TO_CART,
		id,
	};
};
//remove item action
export const removeItem = (course) => {
	return {
		type: REMOVE_ITEM,
		course,
	};
};
//subtract qt action
export const subtractQuantity = (course) => {
	return {
		type: SUB_QUANTITY,
		course,
	};
};
//add qt action
export const addQuantity = (course) => {
	return {
		type: ADD_QUANTITY,
		course,
	};
};

//add qt action with quantity number
export const addQuantityWithNumber = (id, qty, customProd = null) => {
	return {
		type: ADD_QUANTITY_WITH_NUMBER,
		id,
		qty,
		customProd,
	};
};

//add qt action with quantity number
export const addCourseWithNumberOfPeople = (id, comboQuantity = 1) => {
	return {
		type: ADD_COURSE_WITH_NUMBER_OF_PEOPLE,
		id,
		comboQuantity,
	};
};

// Reset cart after form submit
export const resetCart = () => {
	return {
		type: RESET_CART,
	};
};
