import React, { Component } from 'react';
import { toast } from "react-toastify";
import ColoredNavbar from '../components/Layouts/ColoredNavbar';
import Footer from '../components/Layouts/Footer';
import { withRouter } from "react-router-dom";
import {
	selectPaymentType,
	selectPaymentStatus,
} from "../constants/payment_types";

class CheckMpagoMobile extends Component {
  constructor(props) {
    super(props);
  }

	componentDidMount() {
			const paymentData = this.props.location.search;
			if (paymentData) {
				const paymentType =
					paymentData
						? selectPaymentType(paymentData)
						: "No hay info";

        const paymentStatus =
        paymentData
          ? selectPaymentStatus(paymentData)
          : "No hay info";
        if(paymentStatus) {
          switch (paymentStatus) {
						case "approved":
							// props.paymentCallback(paymentType);

							setTimeout(function () {
								toast.success("Tu pago se ha completado con éxito.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
						case "in_process":
							let inProcess = true;
							// props.paymentCallback(paymentType, inProcess);

							setTimeout(function () {
								toast.warning(
									"Cuando recibamos tu pago aprobaremos tu compra.",
									{
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									}
								);
							}, 1500);
							break;
						case "pending":
							const isInProcess = true;
							// props.paymentCallback(paymentType, isInProcess);

							setTimeout(function () {
								toast.warning(
									"Cuando recibamos tu pago aprobaremos tu compra.",
									{
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									}
								);
							}, 1500);
							break;
						case "rejected":
							setTimeout(function () {
								toast.error("Ha ocurrido un error con tu pago.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
						default:
							setTimeout(function () {
								toast.error("Ha ocurrido un error con tu pago.", {
									position: "bottom-left",
									autoClose: 1000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
					}
        }
			
			}

			return;
  }
  
    render() {
        return (
            <div className="contact-page">
            <React.Fragment>
                <ColoredNavbar />
                <div>
                  Mercadopago
                </div>
      
                <Footer />
            </React.Fragment>
            </div>
        );
    }
}

export default withRouter(CheckMpagoMobile);
