import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { firebase } from "../../../firebase";
import { withRouter } from "react-router-dom";
import { css } from "glamor";

const containerStyle = css({
	position: "relative",
});

class AddTestimonyModal extends Component {
	state = {
		modal: false,
		content: "",
		name: "",
		year: "",
		priority: "",
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	addTestimony = (e) => {
		e.preventDefault();
		let { name, content, year, priority } = this.state;

		const testimony = {
			name,
			content,
			year,
			priority,
		};

		if (name != "" && content != "") {
			const db = firebase.firestore();

			const dbOrderRef2 = db.collection("testimonies");
			dbOrderRef2.add(testimony).then((res) => {
				toast.success("El testimonio fue creado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
				this.closeModal();
				this.resetForm();
			});
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer className={containerStyle} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar Testimonio </h3>
							<label>
								Los campos marcados con <strong>*</strong> son obligatorios.
							</label>

							<form onSubmit={this.addTestimony}>
								<div className="form-group">
									<label>
										Nombre de la empresa/testimonio <strong>*</strong>
									</label>
									<input
										type="text"
										className="form-control"
										value={this.state.name}
										onChange={(e) =>
											this.setState({ name: e.target.value })
										}
									/>
								</div>

								<div className="form-group">
									<label>
										Contenido <strong>*</strong>
									</label>
									<textarea
										type="text"
										max={200}
										className="form-control"
										value={this.state.content}
										onChange={(e) =>
											this.setState({ content: e.target.value })
										}
									/>
								</div>

								<div className="form-group">
									<label>Año del testimonio</label>
									<input
										type="number"
										min={1980}
										className="form-control"
										value={this.state.year}
										onChange={(e) =>
											this.setState({ year: e.target.value })
										}
									/>
								</div>

								<div className="form-group">
									<label>Prioridad del testimonio</label>
									<p style={{ fontSize: "12px" }}>
										*Nota: Por defecto la prioridad es 0. Los testimonios con
										mayor prioridad aparecerán primero.
									</p>
									<input
										type="number"
										min={0}
										className="form-control"
										value={this.state.priority}
										onChange={(e) =>
											this.setState({ priority: e.target.value })
										}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Testimonio
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(AddTestimonyModal);
