import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import SearchModal from "../Modals/SearchModal";
import ShoppingCartModal from "../Modals/ShoppingCartModal";
import SidebarModal from "../Modals/SidebarModal";
import { fetchCourses, fetchCategories } from "../../methods/api-calls";
import cart from "../../images/icons/openbook.svg";
import { logoutUser, resetCart } from "../../store/actions/cartActions";
import whiteLogo from "../../images/logos/whiteLogo-alt.png";
import { isMobile } from "../../helpers/generic";

const mobile = isMobile();

class ColoredNavbar extends Component {
	// Navbar
	_isMounted = false;
	state = {
		display: false,
		collapsed: true,
		courses: null,
	};
	toggleNavbar = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
	async componentDidMount() {
		let elementId = document.getElementById("navbar");
		document.addEventListener("scroll", () => {
			if (window.scrollY > 170) {
				elementId.classList.add("is-sticky");
			} else {
				elementId.classList.remove("is-sticky");
			}
		});
		window.scrollTo(0, 0);
		let { courses } = this.props;
		courses = await fetchCourses();
		const categories = await fetchCategories();
		this.setState({
			courses: courses,
			categories: categories,
		});
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	// Search Modal
	toggleModalSearch = () => {
		this.setState({
			SearchModal: !this.state.SearchModal,
		});
	};

	// Shopping Cart Modal
	toggleModalCart = () => {
		this.setState({
			ShoppingCartModal: !this.state.ShoppingCartModal,
		});
	};

	// Sidebar Modal
	toggleModalSidebar = () => {
		this.setState({
			SidebarModal: !this.state.SidebarModal,
		});
	};

	logout = () => {
		this.props.logoutUser();
		this.props.resetCart();
		return <Redirect to="/home" />;
	};

	render() {
		const { products } = this.props;
		const { collapsed, categories, courses } = this.state;
		const classNameOne = collapsed
			? "collapse navbar-collapse"
			: "collapse navbar-collapse show";
		const classNameTwo = collapsed
			? "navbar-toggler navbar-toggler-right collapsed"
			: "navbar-toggler navbar-toggler-right";

		const dropdownMenu = [];
		if (categories && categories.length) {
			categories.forEach((c) => {
				const categoryCourses =
					courses && courses.length
						? courses.filter(
								(course) => course.category && course.category.id === c.id
						  ).sort((p1, p2) => {
								if (p1.priority && !p2.priority) {
									return -1;
								} else if (!p1.priority && p2.priority) {
									return 1;
								} else {
									return p2.priority - p1.priority;
								}
							})
						: null;
				if (categoryCourses && categoryCourses.length) {
					dropdownMenu.push({
						category: c,
						courses: categoryCourses,
					});
				}
			});
		}

		return (
			<React.Fragment>
				<div id="navbar" className="colored-navbar-area">
					<div className="main-colored-nav">
						<div className="container">
							<nav className="navbar navbar-expand-md navbar-light">
								<Link to="/">
									<a className="navbar-brand">
										<img className="navbar-logo" src={whiteLogo} alt="logo" />
									</a>
								</Link>

								<button
									onClick={this.toggleNavbar}
									className={classNameTwo}
									type="button"
									data-toggle="collapse"
									data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded="false"
									aria-label="Toggle navigation"
								>
									<span className="icon-bar top-bar"></span>
									<span className="icon-bar middle-bar"></span>
									<span className="icon-bar bottom-bar"></span>
								</button>

								<div className={classNameOne} id="navbarSupportedContent">
									<ul className="navbar-nav">
										<li className="nav-item">
											<Link to="/home">
												<a className="nav-link">Home</a>
											</Link>
										</li>

										<li className="nav-item">
											<Link to="/about">
												<a className="nav-link">About</a>
											</Link>
										</li>
										<li className="nav-item">
											<Link to="/consultorias">
												<a className="nav-link">Consultoría</a>
											</Link>
										</li>

										<li className="nav-item">
											<Link to="/blog">
												<a className="nav-link">Blog</a>
											</Link>
										</li>

										<li className="nav-item">
											<Link to="/cursos">
												<a className="nav-link">
													Cursos
													{!mobile && <i className="bx bx-chevron-down"></i>}
												</a>
											</Link>

											{!mobile && (
												<ul className="dropdown-menu">
													{dropdownMenu &&
														dropdownMenu.length > 0 &&
														dropdownMenu.map((c, index) => (
															<li className="nav-item">
																<Link activeClassName="active">
																	<a className="nav-link lowercase">
																		{c.category?.title}
																	</a>
																</Link>
																<ul className="dropdown-menu">
																	{c.courses &&
																		c.courses.length > 0 &&
																		c.courses.map((b, index) => (
																			<li className="nav-item">
																				<Link
																					to={"/cursos/" + b.slug}
																					activeClassName="active"
																				>
																					<a className="nav-link lowercase">
																						{b.title}
																					</a>
																				</Link>
																			</li>
																		))}
																</ul>
															</li>
														))}

													<li className="nav-item">
														<Link to={"/in-company"} activeClassName="active">
															<a className="nav-link lowercase">In Company</a>
														</Link>
													</li>

													<li className="nav-item">
														<Link to={"/cursos"} activeClassName="active">
															<a className="nav-link lowercase">
																Todos los cursos
															</a>
														</Link>
													</li>
												</ul>
											)}
										</li>

										{mobile && (
											<li className="nav-item">
												<Link to="/in-company">
													<a className="nav-link">In Company</a>
												</Link>
											</li>
										)}

										<li className="nav-item">
											<Link to="/contact">
												<a className="nav-link">Contacto</a>
											</Link>
										</li>
									</ul>

									<div className="others-option">
										<div className="option-item">
											<div className="cart-btn">
												<Link to="#toggleModalCart">
													<a
														onClick={(e) => {
															e.preventDefault();
															this.toggleModalCart();
														}}
													>
														<i className="bx">
															<i className="user-btn bx bx-book"></i>
														</i>
														<span>{products.length}</span>
													</a>
												</Link>
											</div>
										</div>
										{this.props.user == undefined ? (
											<div className="option-item">
												{" "}
												<Link className="nav-link-login" to="/login">
													Iniciar sesión
												</Link>
											</div>
										) : (
											<div className="option-item">
												<ul className="navbar-nav">
													<li className="nav-item">
														<a className="nav-link">
															<i className="user-btn bx bx-user-circle"></i>{" "}
															<i className="bx bx-chevron-down"></i>
														</a>

														<ul className="dropdown-menu alternative">
															<li className="nav-item">
																<Link to={"/cambiar-contrasena"}>
																	<a className="nav-link lowercase">
																		Cambiar contraseña
																	</a>
																</Link>
															</li>
															<li className="nav-item">
																<Link to={"/settings"}>
																	<a className="nav-link lowercase">
																		Editar perfil
																	</a>
																</Link>
															</li>
															<li className="nav-item">
																<Link to={"/home"} activeClassName="active">
																	<a
																		className="nav-link lowercase"
																		onClick={(e) => {
																			e.preventDefault();
																			this.logout();
																		}}
																	>
																		Cerrar sesión
																	</a>
																</Link>
															</li>
														</ul>
													</li>
												</ul>
											</div>
										)}
									</div>
								</div>
							</nav>
						</div>
					</div>
				</div>

				{/* Shopping Cart Modal */}
				<ShoppingCartModal
					onClick={this.toggleModalCart}
					active={this.state.ShoppingCartModal ? "active" : ""}
				/>

				{/* Sidebar Modal
                <SidebarModal 
                    onClick={this.toggleModalSidebar} 
                    active={this.state.SidebarModal ? 'active' : ''} 
                /> */}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		products: state.addedItems,
		user: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logoutUser: () => {
			dispatch(logoutUser());
		},
		resetCart: () => {
			dispatch(resetCart());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ColoredNavbar);
