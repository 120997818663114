import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditCategoryModal from "../../components/Admin/Modals/EditCategoryModal";
import withAuth from "../../helpers/withAuth";
import { removeCategory, editCourse } from "../../store/actions/cartActions";
import { connect } from "react-redux";

class Categories extends Component {
	// Edit Course Modal
	state = {
		display: false,
		categories: [],
		loading: true,
		showEditModal: false,
		modalCategory: null,
	};

	toggleEditCategoryModal = (course = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalCategory: course,
		});
	};

	dataModal = (course) => {
		this.setState({
			modalCategory: course,
		});
	};

	deleteCategory = (category) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("categories");
		dbOrderRef
			.doc(category.id)
			.delete()
			.then(() => {
				this.props.removeCategory(category.id);

				const { courses } = this.props;

				let coursesToUpdate = [];

				courses.forEach((c) => {
					if (c.category.id == category.id) {
						coursesToUpdate.push(c);
					}
				});

				if (coursesToUpdate && coursesToUpdate.length > 0) {
					coursesToUpdate.forEach((course) => {
						const newCourse = { ...course };
						newCourse.category = null;

						const courseId = course.id;
						const db = firebase.firestore();
						const dbOrderRef = db.collection("courses");
						dbOrderRef
							.doc(courseId)
							.update(newCourse)
							.then(() => {
								this.props.editCourse(newCourse);
							});
					});
				}
				// Delete completed!
				toast.success("La categoría fue eliminada exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("categories");
		let categoriesArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				// console.log(res)
				res.forEach((doc) => {
					let categoriesObj = doc.data();
					categoriesObj.id = doc.id;
					categoriesArray.push(categoriesObj);
				});
				this.setState({
					categories: categoriesArray,
					loading: false,
				});
				this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { categories, loading } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Categorías</h2>
					</div>

					<div className="admin-products">
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : categories.length ? (
								categories.map((category, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
											<div className="products-image no-centered-short  products-content">
												<Link to="#viewproduct">
													<a
														onClick={(e) => {
															e.preventDefault();
															this.dataModal(category);
														}}
													></a>
												</Link>

												<h3>{category.title}</h3>

												<div className="products-button special-btn">
													<ul className="horizontal-menu">
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditCategoryModal(category);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() => this.deleteCategory(category)}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay categorías.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditCategoryModal
						onClick={this.toggleEditCategoryModal}
						active={this.state.showEditModal ? "active" : ""}
						category={this.state.modalCategory}
					/>
				)}
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		courses: state.courses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeCategory: (id) => {
			dispatch(removeCategory(id));
		},
		editCourse: (module) => {
			dispatch(editCourse(module));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(Categories));
