import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TopNavbar from './TopNavbar';

class LeftSidebar extends Component {
    state = {
        layoutCls: false,
    };

    handleLayoutCls = (e) => {
        this.setState({
            layoutCls: e
        });
    }

    render() {
        let {layoutCls} = this.state;
        return (
            <React.Fragment>
                <TopNavbar onChangeClass={this.handleLayoutCls} />
                <nav className={`admin-sidebar ${layoutCls ? 'active' : null}`}>
                    <ul>
                        <li className="nav-item">
                            <Link to="/admin/dashboard" activeClassName="active">
                                <a className="nav-link">
                                    <i className='bx bx-home-alt'></i>
                                    Dashboard
                                </a>
                            </Link> 
                        </li>
                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/courses" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-file'></i>
                                    Cursos
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/modules" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-file-blank'></i>
                                    Módulos
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/coupons" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-gift'></i>
                                    Cupones
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/professors" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-user'></i>
                                    Profesores
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/categories" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-file-blank'></i>
                                    Categorías
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/orders" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-cart'></i>
                                    Ordenes
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/blogs" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-file'></i>
                                    Blogs
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/banners" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-file-blank'></i>
                                    Banners
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/institutional-video" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-video'></i>
                                    Video institucional
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item" title="Coming Soon">
                            <Link to="/admin/testimonies" activeClassName="active">
                                <a className="nav-link">
                                <i className='bx bx-message'></i>
                                    Testimonios
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/" activeClassName="active">
                                <a target="_blank" className="nav-link">
                                    <i className='bx bx-link'></i>
                                    Página clientes
                                </a>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </React.Fragment>
        );
    }
}

export default LeftSidebar;