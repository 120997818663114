import React, { Component } from "react";
import OrderUpdateModal from "../Modals/OrderUpdateModal";
import { firebase } from "../../../firebase";
import CouponTR from "../coupons/CouponTR";
import CouponTRSpecial from "../coupons/CouponTRSpecial";
import axios from "axios";
import backendUrl from "../../../constants/urls";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import { isMobile } from "../../../helpers/generic";
import { encodeUser } from "../../../methods/api-calls";

const COUPON_TYPES = {
	INFLUENCER: 1,
	GENERIC: 2,
	NO_COST: 3,
	INVITATION: 4
};

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

class CouponsTable extends Component {
	state = {
		couponsData: [],
		loading: true,
		couponModal: false,
		order: "",
	};

	async componentDidMount() {
		try {
			const res = await axios.get(`${backendUrl}api/coupons/for-orders`, {
				headers: encodeUser(),
			});

			if (res.data) {
				const { coupons } = res.data;
				this.setState({
					couponsData: coupons,
				});
			}
		} catch (e) {
			alert(e);
		}
		this.setState({
			loading: false,
		});
	}

	toggleCouponModal = (e) => {
		this.setState({
			couponModal: e,
		});
	};

	closeCouponModal = () => {
		this.setState({
			couponModal: false,
		});
	};

	getId = (e) => {
		this.setState({
			order: e,
		});
	};
	render() {
		const { couponsData } = this.state;
		const influencerCoupons =
			couponsData && couponsData.length > 0
				? couponsData.filter((c) => c.type == COUPON_TYPES.INFLUENCER)
				: [];
		const genericCoupons =
			couponsData && couponsData.length > 0
				? couponsData.filter((c) => c.type == COUPON_TYPES.GENERIC)
				: [];
		const noCostCoupons =
			couponsData && couponsData.length > 0
				? couponsData.filter((c) => c.type == COUPON_TYPES.NO_COST)
				: [];

				const invitationCoupons =
				couponsData && couponsData.length > 0
					? couponsData.filter((c) => c.type == COUPON_TYPES.INVITATION)
					: [];
		return (
			<div className="admin-main-content d-flex flex-column">
				<div className="page-header">
					<h2>Cupones</h2>
				</div>

				<p>*Nota: los cupones expirados figuran en rojo.</p>

				{this.state.loading ? (
					<BeatLoader
						css={override}
						size={isMobile() ? 50 : 100}
						color={"#4d7c8a"}
						loading={true}
					/>
				) : (
					<>
						<p style={{ marginBottom: "0px" }}> MASIVOS</p>

						<div className="admin-table admin-orders-table height-555">
							<div className="table-responsive">
								<table className="table table-hover mb-0">
									<thead>
										<tr>
											<th>Título</th>
											<th>Fecha expiración</th>
											<th>Cantidad máx</th>
											<th>Usados</th>
											<th>Dirigido a</th>
											<th>Código</th>
											<th>Cursos</th>
										</tr>
									</thead>
									<tbody>
										{influencerCoupons?.length ? (
											<CouponTRSpecial
												onPassId={this.getId}
												coupons={influencerCoupons}
											/>
										) : (
											<p style={{ padding: "20px" }}>No hay cupones.</p>
										)}
									</tbody>
								</table>
							</div>
						</div>
						<p style={{ marginBottom: "0px" }}> PERSONALES</p>

						<div className="admin-table admin-orders-table height-555">
							<div className="table-responsive">
								<table className="table table-hover mb-0">
									<thead>
										<tr>
											<th>Título</th>
											<th>Fecha expiración</th>
											<th>Dirigido a</th>
											<th>Código</th>
											<th>Usado</th>
											<th>Cursos</th>
										</tr>
									</thead>
									<tbody>
										{genericCoupons?.length ? (
											<CouponTR
												onPassId={this.getId}
												coupons={genericCoupons}
											/>
										) : (
											<p style={{ padding: "20px" }}>No hay cupones.</p>
										)}
									</tbody>
								</table>
							</div>
						</div>

						<p style={{ marginBottom: "0px" }}> SIN COSTO</p>

						<div className="admin-table admin-orders-table height-555">
							<div className="table-responsive">
								<table className="table table-hover mb-0">
									<thead>
										<tr>
											<th>Título</th>
											<th>Fecha expiración</th>
											<th>Dirigido a</th>
											<th>Código</th>
											<th>Usado</th>
											<th>Cursos</th>
										</tr>
									</thead>
									<tbody>
										{noCostCoupons?.length ? (
											<CouponTR onPassId={this.getId} coupons={noCostCoupons} />
										) : (
											<p style={{ padding: "20px" }}>No hay cupones.</p>
										)}
									</tbody>
								</table>
							</div>
						</div>


						<p style={{ marginBottom: "0px" }}> INVITACIÓN</p>

						<div className="admin-table admin-orders-table height-555">
							<div className="table-responsive">
								<table className="table table-hover mb-0">
									<thead>
										<tr>
											<th>Título</th>
											<th>Fecha expiración</th>
											<th>Dirigido a</th>
											<th>Código</th>
											<th>Usado</th>
											<th>Cursos</th>
										</tr>
									</thead>
									<tbody>
										{invitationCoupons?.length ? (
											<CouponTR onPassId={this.getId} coupons={invitationCoupons} />
										) : (
											<p style={{ padding: "20px" }}>No hay cupones.</p>
										)}
									</tbody>
								</table>
							</div>
						</div>

						{this.state.couponModal && (
							<OrderUpdateModal
								onClick={this.closeCouponModal}
								coupon={this.state.coupon}
								active={this.state.couponModal ? "active" : ""}
							/>
						)}

						{/* Footer */}
						<div className="flex-grow-1"></div>
					</>
				)}
			</div>
		);
	}
}

export default CouponsTable;
