import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { removeItem } from "../../store/actions/cartActions";
import geometric from "../../images/courses/geometric.png";
import geometricAlt from "../../images/courses/geometric-alt.png";

class ShoppingCartModal extends Component {
	state = {
		modal: false,
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	render() {
		let cartItems = this.props.products.length ? (
			this.props.products.map((product, idx) => {
				return (
					<div className="products-cart-content" key={idx}>
						<div className="products-cart">
							<div className="products-image">
								<Link to="#">
									<a>
										<img
											src={idx % 2 === 0 ? geometric : geometricAlt}
											alt="image"
										/>
									</a>
								</Link>
							</div>

							<div className="products-content">
								<h3>
									<Link to="#">
										<a>
											{product.items && product.items.length > 0
												? product.items[0].title
												: "-"}
										</a>
									</Link>
								</h3>

								<div>
									Inscripto:{" "}
									{product.customerDetails
										? `${product.customerDetails.firstName} ${product.customerDetails.lastName}`
										: "No hay información"}
								</div>

								<div>
									Fecha:{" "}
									{product.items &&
									product.items.length > 0 &&
									product.items[0].modules &&
									product.items[0].modules.length > 0
										? `${product.items[0].modules[0].date
												.split("-")
												.reverse()
												.join("/")} - ${product.items[0].modules[0].startTime} `
										: "Consultar"}
								</div>

								<div className="products-price">
									<span>ID:</span>
									<span
										style={{
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										<a
											href={
												product.items &&
												product.items.length > 0 &&
												product.items[0].zoomLink
													? product.items[0].zoomLink
													: "mailto:gv.seminarios@gmail.com"
											}
										>
											{product.items &&
											product.items.length > 0 &&
											product.items[0].zoomLink
												? product.items[0].zoomLink
												: "Consultar meeting id"}
										</a>
									</span>
								</div>

								{product.items && product.items.length > 0 && product.items[0].zoomPw && (
									<div className="products-price">
										<span>Clave:</span>
										<span
											style={{
												width: "100%",
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											<a>{product.items[0].zoomPw || "-"}</a>
										</span>
									</div>
								)}

								<div className="products-price">
									<span>Clave: 028433 </span>
									{/* <span
										style={{
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
									<a>
											{product.items &&
											product.items.length > 0 &&
											product.items[0].zoomPw ? product.items[0].zoomPw : '-'}
										</a> 
									</span> */}
								</div>

								<div className="products-price">
									<span>1 inscripción</span>
									{product?.amount > 0 && <span>x</span>}
									<span className="price">
										{product?.amount > 0 ? `$${product.amount}` : "(Sin costo)"}
									</span>
								</div>
							</div>
						</div>
					</div>
				);
			})
		) : (
			<div className="products-cart-content">
				<p>No tienes inscripciones.</p>
			</div>
		);
		return (
			<React.Fragment>
				<div className={`shoppingCartModal right ${this.props.active}`}>
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Mis inscripciones ({this.props.products.length})</h3>

							{cartItems}

							<div className="products-cart-subtotal">
								<span>Total</span>
								<span className="subtotal">${this.props.total}</span>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		products: state.addedItems,
		total: state.total,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeItem: (id) => {
			dispatch(removeItem(id));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartModal);
