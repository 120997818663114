import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditBannerModal from "../../components/Admin/Modals/EditBannerModal";
import withAuth from "../../helpers/withAuth";

class Banners extends Component {
	state = {
		display: false,
		banners: [],
		loading: true,
		showEditModal: false,
		modalBanner: null,
	};

	toggleEditBannerModal = (banner = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalBanner: banner,
		});
	};

	dataModal = (banner) => {
		this.setState({
			modalBanner: banner,
		});
	};

	deleteBanner = (banner) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("banners");
		dbOrderRef
			.doc(banner.id)
			.delete()
			.then(() => {
				// Delete completed!
				toast.success("El banner fue eliminado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("banners");
		let bannersArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let bannersObj = doc.data();
					bannersObj.id = doc.id;
					bannersArray.push(bannersObj);
				});
				this.setState({
					banners: bannersArray,
					loading: false,
				});
				this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { banners, loading } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Banners</h2>
					</div>

					<div className="admin-products">
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : banners.length ? (
								banners.map((banner, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
											<div className="products-image no-centered-short  products-content">
												<Link to="#viewproduct">
													<a
														onClick={(e) => {
															e.preventDefault();
															this.dataModal(banner);
														}}
													></a>
												</Link>

												<h3>{banner.title}</h3>
												<p>{banner.content}</p>
												{banner.buttonText && (
													<a
														disabled={!banner.buttonLink}
														href={banner.buttonLink}
													>
														{`Link: ${banner.buttonText}`}
													</a>
												)}
												<div className="products-button special-btn">
													<ul className="horizontal-menu">
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditBannerModal(banner);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() => this.deleteBanner(banner)}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay banners.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditBannerModal
						onClick={this.toggleEditBannerModal}
						active={this.state.showEditModal ? "active" : ""}
						banner={this.state.modalBanner}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withAuth(Banners);
