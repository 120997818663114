import React, { Component } from "react";
import { css } from "@emotion/core";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CourseSidebar from "./CourseSidebar";
import CourseDetailsTab from "./CourseDetailsTab";
import CoursePaymentModes from "./CoursePaymentModes";
import geometric from "../../images/courses/pattern1.png";
import geometricAlt from "../../images/courses/pattern3.png";
import ModulesContainer from "./Modules/ModulesContainer";

import { fetchCourses, fetchAllBlogPosts } from "../../methods/api-calls";

import BeatLoader from "react-spinners/BeatLoader";
import { ModuleModal } from "./Modules/ModuleModal";
import OwlCarousel from "react-owl-carousel3";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

class CourseDetailsStyleOne extends Component {
	state = {
		qty: 1,
		max: 10,
		min: 1,
		data: null,
		blogs: null,
		courses: null,
		ModuleModal: false,
		isLoading: true,
		modalModule: {},
		currentId: null,
	};

	convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		if (t) {
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		}
	};

	toggleModal = () => {
		this.setState({
			ModuleModal: !this.state.ModuleModal,
		});
	};

	passDataToModal = (module) => {
		this.setState({
			modalModule: module,
		});
	};

	async componentDidMount() {
		const slug = this.props.match.params.slug;
		let { courses } = this.props;
		courses = await fetchCourses();
		let data =
			courses && courses.length > 0
				? courses.filter((courses) => courses.slug == slug)
				: [];
		this.setState({
			data: data && data.length ? data[0] : null,
			currentId: data && data.length ? data[0]?.id : null,
			currentSlug: data && data.length ? data[0]?.slug : null,
		});

		const blogs = await fetchAllBlogPosts();
		var toRender = [];
		if (blogs?.length > 2) {
			toRender = [blogs[0], blogs[1]];
		} else {
			toRender = blogs;
		}
		this.setState({
			blogs: toRender,
			isLoading: false,
		});
	}

	async componentDidUpdate() {
		const slug = this.props.match.params.slug;
		if (this.state.currentSlug != slug) {
			window.location.reload();
		}
	}

	handleAddToCart = () => {
		let { qty, currentId } = this.state;
		const { data } = this.state;
		const customProd = JSON.parse(JSON.stringify(data));

		this.props.addQuantityWithNumber(currentId, qty, customProd);

		toast.success("Agregado al carrito", {
			position: "bottom-left",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	};

	incrementItem = () => {
		this.setState((prevState) => {
			if (prevState.qty < 10) {
				return {
					qty: prevState.qty + 1,
				};
			} else {
				return null;
			}
		});
	};

	decreaseItem = () => {
		this.setState((prevState) => {
			if (prevState.qty > 1) {
				return {
					qty: prevState.qty - 1,
				};
			} else {
				return null;
			}
		});
	};

	render() {
		var isMobile = false;
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			isMobile = true;
		}
		const { data } = this.state;
		let numberOfPics = 0;
		if (data) {
			if (data.imageUrl) {
				numberOfPics++;
			}
			if (data.imageUrlSecond) {
				numberOfPics++;
			}
			if (data.imageUrlThird) {
				numberOfPics++;
			}
			if (data.imageUrlFourth) {
				numberOfPics++;
			}
		}

		const options = {
			loop: true,
			nav: false,
			dots: false,
			autoplayHoverPause: true,
			autoplay: true,
			navText: [
				"<i class='flaticon-left'></i>",
				"<i class='flaticon-right-arrow'></i>",
			],
			responsive: {
				0: {
					items: 2,
				},
				576: {
					items: 3,
				},
				768: {
					items: numberOfPics - 2,
				},
				1200: {
					items: numberOfPics,
				},
			},
		};
		if (!this.state.isLoading && this.state.data == null) {
			return <Redirect to="/" />;
		}

		return (
			<section className="blog-details-area ptb-100">
				<div className="container">
					{this.state.isLoading ? (
						<div className="loader-background">
							<BeatLoader
								css={override}
								size={isMobile ? 50 : 100}
								color={"#4d7c8a"}
								loading={true}
							/>
						</div>
					) : (
						<div className="row">
							<div className="col-lg-9 col-md-9">
								<div className="blog-details-desc special-desc">
									<div className="course-title-container">
										<div className="course-heading">
											{data.header || "Curso"}
										</div>
										<div className="course-title">{data.title}</div>
										<div className="course-subtitle">
											{data.shortDescription}
										</div>
									</div>
									{isMobile && <CourseSidebar course={data} />}

									{data &&
										(data.description ||
											data.objetives ||
											data.methodology ||
											data.content ||
											data.targetPublic) && (
											<div className="article-content margin-60">
												<CourseDetailsTab course={data}></CourseDetailsTab>
											</div>
										)}

									{this.state.data.imageUrl != "" && (
										<OwlCarousel
											className="instagram-slides owl-carousel owl-theme"
											{...options}
										>
											{this.state.data.imageUrl != "" && (
												<div className="single-instagram-post">
													<img src={this.state.data.imageUrl} alt="image" />
												</div>
											)}
											{this.state.data.imageUrlSecond != "" && (
												<div className="single-instagram-post">
													<img
														src={this.state.data.imageUrlSecond}
														alt="image"
													/>
												</div>
											)}
											{this.state.data.imageUrlThird != "" && (
												<div className="single-instagram-post">
													<img
														src={this.state.data.imageUrlThird}
														alt="image"
													/>
												</div>
											)}
											{this.state.data.imageUrlFourth != "" && (
												<div className="single-instagram-post">
													<img
														src={this.state.data.imageUrlFourth}
														alt="image"
													/>
												</div>
											)}
										</OwlCarousel>
									)}

									{this.state.data?.modules?.length ? (
										<div className="article-content margin-60">
											<ModulesContainer
												course={data}
												onhandleModalModule={this.passDataToModal}
												ontoggleModal={this.toggleModal}
											/>
										</div>
									) : null}

									<CoursePaymentModes course={data} />

									{data?.professor && (
										<div className="article-author">
											<div className="author-profile-header"></div>
											<div className="author-profile">
												<div className="author-profile-title">
													{data.professor && (
														<img
															src={data.professor?.profilePicture}
															className="shadow-sm"
															alt="image"
														/>
													)}

													<div className="author-profile-title-details d-flex justify-content-between">
														<div className="author-profile-details">
															<h3>Docente:</h3>
															<h4>
																{data.professor ? data.professor.fullName : ""}
															</h4>
															<span className="d-block content-paragraph">
																{data.professor
																	? data.professor.personDescription
																	: ""}
															</span>
														</div>

														{data?.professor && data.professor.linkedinLink && (
															<div className="author-profile-raque-profile">
																<a
																	href={
																		data.professor
																			? data.professor.linkedinLink
																			: ""
																	}
																	target="_blank"
																	className="d-inline-block"
																>
																	Ver en Linkedin
																</a>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									)}

									{this.state.blogs != null && (
										<div className="row pt-70">
											<div className="section-title-alt">
												<h2>QUIZÁS TE PUEDA INTERESAR...</h2>
											</div>
										</div>
									)}

									<div className="xton-post-navigation">
										{this.state.blogs != null &&
											this.state.blogs.length > 0 &&
											this.state.blogs.map((blog) => (
												<div className="prev-link-wrapper">
													<div className="info-prev-link-wrapper">
														<Link to={"/blog-post/" + blog.slug}>
															<a>
																<span className="image-prev">
																	<img src={blog.coverImageUrl} alt="image" />
																	<span className="post-nav-title">Ver</span>
																</span>

																<span className="prev-link-info-wrapper">
																	<span className="prev-title">
																		{blog.mainTitle}
																	</span>
																	<span className="meta-wrapper">
																		<span className="date-post">
																			{this.convertDateTime(blog.createdAt)}
																		</span>
																	</span>
																</span>
															</a>
														</Link>
													</div>
												</div>
											))}
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-md-3 sidebar-col">
								{/* Blog Sidebar */}
								{!isMobile && <CourseSidebar course={data} />}
							</div>
							<ModuleModal
								onClick={this.toggleModal}
								active={this.state.ModuleModal ? "active" : ""}
								module={this.state.modalModule}
							/>
						</div>
					)}
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		courses: state.courses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

const WrappedCourseDetailsStyleOne = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CourseDetailsStyleOne));

export default WrappedCourseDetailsStyleOne;
