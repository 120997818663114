import React, { Component } from "react";
import NotificationModal from "../Modal";

class CouponTR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			courses: [],
		};
	}
	convertDateTime = (d) => {
		if (d) {
			let t = new Date(1970, 0, 1);
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		} else {
			return "";
		}
	};

	showCourses = (courses) => {
		this.setState({
			courses: courses,
			showModal: true,
		});
	};

	dateExpired = (coupon) => {
		let disabled = false;
		const oldDate =
			coupon.expirationDate && coupon.expirationDate.length
				? coupon.expirationDate.split("-")
				: null;
		if (oldDate) {
			const newDate = oldDate.slice(1, 3);
			newDate.push(oldDate[0]);

			let newest = new Date();
			newest.setDate(newDate[1]);
			newest.setMonth(newDate[0]-1)
			newest.setFullYear(newDate[2])
			
			if (newest < Date.now()) {
				disabled = true;
			}
		}

		return disabled;
	};


	render() {
		let { coupons } = this.props;

		let filteredCoupons = coupons;
		if (coupons?.length) {
			filteredCoupons = coupons.sort((a, b) => {
				if (a?.createdAt && b?.createdAt) {
					return (
						new Date(this.convertDateTime(b.createdAt)) -
						new Date(this.convertDateTime(a.createdAt))
					);
				} else if (a.createdAt && !b.createdAt) {
					return -1;
				} else if (!a.createdAt && b.createdAt) {
					return 1;
				} else {
					return 0;
				}
			});
		}

		return (
			<React.Fragment>
				<NotificationModal
					show={this.state.showModal}
					title={"Cursos"}
					subtitle={""}
					content={
						this.state.courses && this.state.courses.length > 0
							? `Cursos: ${this.state.courses.join(", ")}`
							: "No hay cursos."
					}
					onHide={() =>
						this.setState({
							showModal: false,
							courses: null,
						})
					}
				/>
				{filteredCoupons?.length ? (
					filteredCoupons.map((coupon, i) => {
						return (
							<tr
								key={i}
								style={{
									backgroundColor: this.dateExpired(coupon) ? "#ffadbc" : "",
								}}
							>
								<td>{coupon.title}</td>
								<td>{coupon.expirationDate ? coupon.expirationDate : "-"}</td>
								<td>{coupon.directedTo || "-"}</td>
								<td><strong>{coupon.code || "-"}</strong></td>
								<td>{coupon.currentUses > 0 ? 'Si' : "No"}</td>
								<td>
									<button onClick={() => this.showCourses(coupon.courses)}>
										Ver cursos
									</button>
								</td>
							</tr>
						);
					})
				) : (
					<p>No hay cupones.</p>
				)}
			</React.Fragment>
		);
	}
}

export default CouponTR;
