import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { compare } from "../../helpers/generic";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditModuleModal from "../../components/Admin/Modals/EditModuleModal";
import withAuth from "../../helpers/withAuth";
import { removeCategory, addCourses, editCourse } from "../../store/actions/cartActions";
import { connect } from "react-redux";
import ViewModuleModal from '../../components/Admin/Modals/ViewModuleModal';

class Modules extends Component {
	constructor(props){
		super(props);
		this.deleteModule = this.deleteModule.bind(this);
	}
	// Edit Course Modal
	state = {
		display: false,
		modules: [],
		courses: [],
		loading: true,
		showEditModal: false,
		modalModule: null,
	};

	// View Module Modal
	toggleViewModuleModal = () => {
		this.setState({
			ViewModuleModal: !this.state.ViewModuleModal,
		});
	};

	toggleEditModuleModal = (module = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalModule: module,
		});
	};

	dataModal = (module) => {
		this.setState({
			modalModule: module,
		});
	};

	findCourse = (module) => {
		let found = null;
		const { courses } = this.state;
		if(courses && courses.length > 0 && module) {
			courses.forEach(c => {
				if(c.modules && c.modules && c.modules.length > 0)
				{
					c.modules.forEach(m => {
						if(m.moduleId == module.moduleId) {
							found = c;
						}
					})
				}
			})
		}
		return found;
	}

	 deleteModule(module) {
		const courseId = module.courseId;
		let courseToUpdate = this.state.courses.find(c => c.id == courseId);
		let without = [];
		if(courseToUpdate && courseToUpdate.modules && courseToUpdate.modules.length > 0){
			without = courseToUpdate.modules.filter(r => r.moduleId !== module.moduleId);
		}
		courseToUpdate.modules = without;
		// const newCourse = {...courseToUpdate, modules: courseToUpdate.modules ? courseToUpdate.modules.push(module)};
		// const courseWithoutModule = {...courseToUpdate, modules: courseToUpdate.modules ? [...courseToUpdate.modules.filter(m => m.moduleId =! module.moduleId)] : [] }
		if(courseToUpdate) {
			const db = firebase.firestore();
			const dbOrderRef = db.collection("courses");
			dbOrderRef
				.doc(courseId)
				.update(courseToUpdate)
				.then(() => {
					this.props.editCourse(courseToUpdate)
	
					toast.success("Modulo fue eliminado exitosamente.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				});
		}
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("courses");
		let modulesArray = [];
		let coursesArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let modulesObj = doc.data();
					modulesObj.id = doc.id;
					if(modulesObj.modules && modulesObj.modules.length > 0 ){
						modulesObj.modules.forEach(m => (
							modulesArray.push(m)
						))
					}
					coursesArray.push(modulesObj);
				});
				this.props.addCourses(coursesArray);
				this.setState({
					modules:
						modulesArray,
					courses: coursesArray,
					loading: false,
				});
				this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { modules, loading, courses } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Módulos</h2>
					</div>

					<div className="admin-products">
						<div className="row">
							{loading ? (
								<p className="no-element-msg">Cargando...</p>
							) : modules.length ? (
								modules.map((module, idx) => (
									<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
										<div className="single-products-box">
									

											<div className="products-image no-centered products-content">
												<h4 className="header">{`Curso: ${this.findCourse(module) ? this.findCourse(module).title : "No hay información"
												}`}</h4>
												<h3 className="header">{`Módulo: ${module.title}`}</h3>

												<div className="products-button special-btn">
													<ul className="horizontal-menu">
														<li>
															<div className="wishlist-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleViewModuleModal();
																			this.dataModal(module);
																		}}
																	>
																		<i className="bx bx-search-alt"></i>
																		<span className="tooltip-label">Ver</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="compare-btn">
																<Link to="#">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																			this.toggleEditModuleModal(module);
																		}}
																	>
																		<i className="bx bx-edit"></i>
																		<span className="tooltip-label">
																			Editar
																		</span>
																	</a>
																</Link>
															</div>
														</li>
														<li>
															<div className="quick-view-btn">
																<button
																	onClick={() => this.deleteModule(module)}
																	className="invisible-btn"
																>
																	<a>
																		<i className="bx bx-x"></i>
																		<span className="tooltip-label">
																			Eliminar
																		</span>
																	</a>
																</button>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<p className="no-element-msg">No hay módulos.</p>
							)}
						</div>
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditModuleModal
						onClick={this.toggleEditModuleModal}
						active={this.state.showEditModal ? "active" : ""}
						module={this.state.modalModule}
					/>
				)}
				<ViewModuleModal
					onClick={this.toggleViewModuleModal}
					active={this.state.ViewModuleModal ? "active" : ""}
					module={this.state.modalModule}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
			courses: state.courses,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		removeCategory: (id) => {
			dispatch(removeCategory(id));
		},
		addCourses: (courses) => {
			dispatch(addCourses(courses));
		},
		editCourse: (module) => {
			dispatch(editCourse(module));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(Modules));
