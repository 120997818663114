import React, { Component } from "react";
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import Footer from "../components/Layouts/Footer";
import { CoursesGridFour } from "../components/Courses/CoursesGridFour";
import PageBanner from "../components/Common/PageBanner";
import { fetchCourses } from "../methods/api-calls";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import { isMobile } from "../helpers/generic";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	height: 300px;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	align-items: center;
`;

class Courses extends Component {
	constructor(props) {
		super(props);
		this.state = {
			courses: [],
			loading: false,
		};
	}
	async componentDidMount() {
		this.setState({
			loading: true,
		});
		const courses = await fetchCourses();
		this.setState({
			courses: courses,
			loading: false,
		});
	}
	render() {
		return (
			<div className="contact-page">
				<React.Fragment>
					<ColoredNavbar />
					<PageBanner
						pageTitle="CURSOS"
						homePageUrl="/"
						homePageText="Home"
						activePageText="Cursos"
					/>
					<div className="all-courses-description">
						<div className="description">
							Los talleres en formato <strong>“Presencial a Distancia”</strong>{" "}
							a través de Zoom han sido diseñados para mantener la{" "}
							<strong>misma dinámica</strong>. Los participantes negocian en
							pequeños grupos dentro salas virtuales con la supervisión del
							profesor, para luego realizar el análisis y la discusión por el
							método del caso en el aula principal con todos los participantes.
						</div>
					</div>
					{this.state.loading ? (
						<div className="loader-background">
							<BeatLoader
								css={override}
								size={isMobile() ? 50 : 100}
								color={"#4d7c8a"}
								loading={true}
							/>
						</div>
					) : (
						<CoursesGridFour
							courses={this.state.courses}
							loading={this.state.loading}
						/>
					)}

					<Footer />
				</React.Fragment>
			</div>
		);
	}
}

export { Courses };