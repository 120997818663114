import React, { Component } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import ColoredNavbar from "../components/Layouts/ColoredNavbar";
import Footer from "../components/Layouts/Footer";
import { loginUser, addSingleOrder } from "../store/actions/cartActions";
import { firebase } from "../firebase";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
	display: flex;
	justify-content: center;
	margin: auto;
	margin-bottom: 50px;
	margin-top: 50px;
`;

class UserLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			password: null,
			error: "",
			loading: false,
			loggedIn: false,
		};
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
	}

	handleEmailChange(event) {
		this.setState({ error: "", email: event.target.value });
	}

	handlePasswordChange(event) {
		this.setState({ error: "", password: event.target.value });
	}

	handleLogin = (e) => {
		this.setState({ loading: true });
		e.preventDefault();
		const db = firebase.firestore();
		const docRef = db.collection("users").doc(this.state.email);
		docRef.get().then((doc) => {
			if (doc.exists) {
				if (doc.data().password == this.state.password) {
					this.props.loginUser(doc.data());
					const ordersRef = db.collection("orders");
					ordersRef.get().then((docs) => {
						docs.forEach((doc) => {
							if (doc.data().customerDetails.email == this.state.email) {
								this.props.addSingleOrder(doc.data());
							}
						});
					});
					this.setState({ loading: false, loggedIn: true });
				} else {
					this.setState({ loading: false, loggedIn: false });
					this.setState({ error: "Usuario/Contraseña incorrecta" });
				}
			} else {
				this.setState({ loading: false, loggedIn: false });
				this.setState({ error: "Usuario/Contraseña incorrecta" });
			}
		});
	};

	render() {
		const { user } = this.props;
		if (user != undefined) {
			return <Redirect to="/home" />;
		} else {
			var isMobile = false;
			// device detection
			if (
				/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
					navigator.userAgent
				) ||
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
					navigator.userAgent.substr(0, 4)
				)
			) {
				isMobile = true;
			}
			return (
				<React.Fragment>
					<ColoredNavbar />
					<section className="login-area ptb-100">
						<div className="container">
							<div className="row-justify-center">
								<div className="col-lg-6 col-md-6">
									<div className="login-content">
										<h2>Iniciar sesión</h2>

										<form onSubmit={this.handleLogin} className="login-form">
											<div className="form-group">
												<input
													type="email"
													onChange={this.handleEmailChange}
													className="form-control"
													value={this.state.email}
													placeholder="Email"
												/>
											</div>

											<div className="form-group">
												<input
													type="password"
													onChange={this.handlePasswordChange}
													className="form-control"
													value={this.state.password}
													placeholder="Contraseña"
												/>
											</div>

											<p className="login-error">{this.state.error}</p>
											{this.state.loading && (
												<BeatLoader
													css={override}
													size={isMobile ? 50 : 50}
													color={"#4d7c8a"}
													loading={true}
												/>
											)}

											{this.state.loading === false && (
												<button type="submit" className="default-btn">
													Login
												</button>
											)}
											<div className="text-center">
												<a className="return-store">
													No tienes una cuenta?{" "}
													<Link to="sign-up">Registrarme</Link>
												</a>
											</div>
											<div className="text-center">
												<a className="return-store">
													Olvidaste tu contraseña?{" "}
													<Link to="recuperar-contrasena">Recupérala</Link>
												</a>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</React.Fragment>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginUser: (user) => {
			dispatch(loginUser(user));
		},
		addSingleOrder: (order) => {
			dispatch(addSingleOrder(order));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
