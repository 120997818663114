import { Home } from "../pages/Home";
import { ContactPage } from "../pages/contact";
import { Blog1 } from "../pages/blog-1";
import { Login } from "../pages/admin/Login";
import UserLogin from "../pages/login";
import UserSignUp from "../pages/signup";
import UserSettings from "../pages/Settings";
import Dashboard from "../pages/admin/dashboard";
import { Error404 } from "../pages/Error404";
import PaymentSuccess from '../pages/PaymentSuccess';
import { Success } from "../pages/Success";
import Pending from "../pages/Pending";
import Failure from "../pages/Failure";

import { About } from "../pages/about";
import Consultorias from "../pages/Consultorias";
import { Courses } from "../pages/Courses";
import Checkout from "../pages/checkout";
import { CoursesTypeOne } from "../pages/CoursesTypeOne";
import AllCourses from "../pages/admin/Courses";
import Modules from "../pages/admin/Modules";
import Categories from "../pages/admin/Categories";
import Blogs from "../pages/admin/Blogs";
import Banners from "../pages/admin/Banners";
import Testimonies from "../pages/admin/Testimonies";
import InstitutionalVideos from "../pages/admin/InstitutionalVideos";
import { AllBlogs } from "../pages/Blogs";
import Professors from "../pages/admin/Professors";
import { Certificates } from "../pages/Certificates";
import ResetPassword from "../pages/reset-password";
import ForgotPassword from "../pages/forgot-password";
import Orders from "../pages/admin/Orders";
import Coupons from "../pages/admin/Coupons";
import CustomerService from "../pages/CustomerService";
import CheckMpagoMobile from "../pages/CheckMpagoMobile";

const routes = [
	{
		path: "/",
		exact: true,
		component: Home,
	},
	{
		path: "/home",
		exact: true,
		component: Home,
	},
	{
		path: "/pending/:id",
		component: Pending,
	}, {
		path: '/failure',
		component: Failure,
	},
	{
		path: "/login",
		exact: true,
		component: UserLogin,
	},
	{
		path: "/sign-up",
		exact: true,
		component: UserSignUp,
	},
	{
		path: "/settings",
		exact: true,
		component: UserSettings,
	},
	{
		path: "/cambiar-contrasena",
		exact: true,
		component: ResetPassword,
	},
	{
		path: "/recuperar-contrasena",
		exact: true,
		component: ForgotPassword,
	},
	{
		path: "/in-company",
		exact: true,
		component: CustomerService,
	},
	{
		path: "/cursos",
		exact: true,
		component: Courses,
	},
	{
		path: "/blog",
		exact: true,
		component: AllBlogs,
	},
	{
		path: "/blog-post/:slug",
		exact: true,
		component: Blog1,
	},
	{
		path: "/cursos/:slug",
		exact: true,
		component: CoursesTypeOne,
	},
	{
		path: "/certificados/:id",
		exact: true,
		component: Certificates,
	},
	{
		path: "/checkout/:id/:amount_of_people",
		exact: true,
		component: Checkout,
	},
	{
		path: "/success/:id",
		exact: true,
		component: PaymentSuccess,
	},
	{
		path: "/contact",
		exact: true,
		component: ContactPage,
	},
	{
		path: "/check-mpago",
		exact: true,
		component: CheckMpagoMobile,
	},
	{
		path: "/about",
		exact: true,
		component: About,
	},
	{
		path: "/consultorias",
		exact: true,
		component: Consultorias,
	},
	{
		path: "/admin/blogs",
		component: Blogs,
	},
	{
		path: "/admin/banners",
		component: Banners,
	},
	{
		path: "/admin/testimonies",
		component: Testimonies,
	},
	{
		path: "/admin/institutional-video",
		component: InstitutionalVideos,
	},
	{
		path: "/admin/login",
		component: Login,
	},
	{
		path: "/admin/dashboard",
		component: Dashboard,
	},
	{
		path: "/admin/professors",
		component: Professors,
	},
	{
		path: "/admin/courses",
		component: AllCourses,
	},
	{
		path: "/admin/modules",
		component: Modules,
	},
	{
		path: "/admin/categories",
		component: Categories,
	},
	{
		path: "/admin/Orders",
		component: Orders,
	},
	{
		path: "/admin/Coupons",
		component: Coupons,
	},
	{
		path: "*",
		component: Error404,
	},
];

export { routes };
