import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../firebase";
import { Redirect } from "react-router-dom";
import AddCourseModal from "./Modals/AddCourseModal";
import AddModuleModal from "./Modals/AddModuleModal";
import AddBlogPostModal from "./Modals/AddBlogPostModal";
import AddProfessorModal from "./Modals/AddProfessorModal";
import AddCouponModal from "./Modals/AddCouponModal";
import adminIcon from "../../images/admin/user.svg";
import AddCategoryModal from "./Modals/AddCategoryModal";
import AddBannerModal from "./Modals/AddBannerModal";
import AddVideoModal from "./Modals/AddVideoModal";
import AddTestimonyModal from "./Modals/AddTestimonyModal";

class TopNavbar extends Component {
	_isMounted = false;

	state = {
		display: false,
		layoutCls: true,
		collapsed: true,
		loggedOut: false,
	};

	handleLogout = () => {
		auth
			.signOut()
			.then(() => {
				this.setState({
					loggedOut: true,
				});
				setTimeout(function () {
					toast.success("Has cerrado sesión.", {
						position: "bottom-left",
						autoClose: 1000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
				}, 1500);
			})
			.catch(function (error) {
				alert("Ha ocurrido un error.");
				// console.log(error);
			});
	};

	// Navbar
	toggleNavbar = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	// Add Product Modal
	toggleAddCourseModal = () => {
		this.setState({
			AddCourseModal: !this.state.AddCourseModal,
		});
	};

	// Add Subproduct Modal
	toggleAddModuleModal = () => {
		this.setState({
			AddModuleModal: !this.state.AddModuleModal,
		});
	};

	// Add Professor Modal
	toggleAddProfessorModal = () => {
		this.setState({
			AddProfessorModal: !this.state.AddProfessorModal,
		});
	};

	// Add Category Modal
	toggleAddCategoryModal = () => {
		this.setState({
			AddCategoryModal: !this.state.AddCategoryModal,
		});
	};

	// Add BlogPost Modal
	toggleAddBlogPostModal = () => {
		this.setState({
			AddBlogPostModal: !this.state.AddBlogPostModal,
		});
	};

	// Add Coupon Modal
	toggleAddCouponModal = () => {
		this.setState({
			AddCouponModal: !this.state.AddCouponModal,
		});
	};

	// Add Banner Modal
	toggleAddBannerModal = () => {
		this.setState({
			AddBannerModal: !this.state.AddBannerModal,
		});
	};

	// Add Video Modal
	toggleAddVideoModal = () => {
		this.setState({
			AddVideoModal: !this.state.AddVideoModal,
		});
	};

	// Add Testimony Modal
	toggleAddTestimonyModal = () => {
		this.setState({
			AddTestimonyModal: !this.state.AddTestimonyModal,
		});
	};

	//
	onToggleClass = () => {
		this.setState({
			layoutCls: !this.state.layoutCls,
		});
		this.props.onChangeClass(this.state.layoutCls);
	};

	render() {
		const { collapsed } = this.state;
		const classNameOne = collapsed
			? "collapse navbar-collapse"
			: "collapse navbar-collapse show";

		if (this.state.loggedOut) {
			return <Redirect to="/admin/login" />;
		}

		return (
			<React.Fragment>
				<nav className="navbar navbar-expand admin-top-navbar">
					<Link to="/admin/dashboard">
						<a className="navbar-brand">
							<img
								className="admin-icon"
								src={require("../../images/admin/panel.png")}
								alt="logo"
								style={{ height: "60px", width: "60px" }}
							/>
						</a>
					</Link>

					{/* Burger menu */}
					<div className="burger-menu x" onClick={this.onToggleClass}>
						<span className="top-bar"></span>
						<span className="middle-bar"></span>
						<span className="bottom-bar"></span>
					</div>

					<div className={classNameOne} id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddCourseModal();
									}}
								>
									Agregar curso
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddModuleModal();
									}}
								>
									Agregar módulo
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddProfessorModal();
									}}
								>
									Agregar profesor
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddCategoryModal();
									}}
								>
									Agregar categoría
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddCouponModal();
									}}
								>
									Agregar cupón
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddBlogPostModal();
									}}
								>
									Agregar blog post
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddBannerModal();
									}}
								>
									Agregar banner
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddVideoModal();
									}}
								>
									Agregar video
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddTestimonyModal();
									}}
								>
									Agregar testimonio
								</a>
							</li>

							<li className="nav-item profile">
								<Link to="#">
									<a className="nav-link">
										<img src={adminIcon} alt="Image" />
									</a>
								</Link>

								<ul className="dropdown-menu">
									<li className="nav-item">
										<Link to="#" activeClassName="active">
											<a
												onClick={(e) => {
													e.preventDefault();
													this.handleLogout();
												}}
												className="nav-link"
											>
												Cerrar sesión
											</a>
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</nav>

				{/* Sidebar Modal */}
				<AddCourseModal
					onClick={this.toggleAddCourseModal}
					active={this.state.AddCourseModal ? "active" : ""}
				/>
				{/* Sidebar Modal */}
				<AddCategoryModal
					onClick={this.toggleAddCategoryModal}
					active={this.state.AddCategoryModal ? "active" : ""}
				/>
				<AddModuleModal
					onClick={this.toggleAddModuleModal}
					active={this.state.AddModuleModal ? "active" : ""}
				/>
				<AddBlogPostModal
					onClick={this.toggleAddBlogPostModal}
					active={this.state.AddBlogPostModal ? "active" : ""}
				/>
				<AddProfessorModal
					onClick={this.toggleAddProfessorModal}
					active={this.state.AddProfessorModal ? "active" : ""}
				/>
				<AddCouponModal
					onClick={this.toggleAddCouponModal}
					active={this.state.AddCouponModal ? "active" : ""}
				/>
				<AddBannerModal
					onClick={this.toggleAddBannerModal}
					active={this.state.AddBannerModal ? "active" : ""}
				/>

				<AddVideoModal
					onClick={this.toggleAddVideoModal}
					active={this.state.AddVideoModal ? "active" : ""}
				/>

				<AddTestimonyModal
					onClick={this.toggleAddTestimonyModal}
					active={this.state.AddTestimonyModal ? "active" : ""}
				/>
			</React.Fragment>
		);
	}
}

export default TopNavbar;
