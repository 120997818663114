//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SUB_QUANTITY = "SUB_QUANTITY";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const ADD_QUANTITY_WITH_NUMBER = "ADD_QUANTITY_WITH_NUMBER";
export const ORDER_FORM = "ORDER_FORM";
export const RESET_CART = "RESET_CART";
export const ADD_COURSES = "ADD_COURSES";
export const ADD_MODULE = "ADD_MODULE";
export const ADD_SINGLE_COURSE = "ADD_SINGLE_COURSE";
export const ADD_SINGLE_ORDER = "ADD_SINGLE_ORDER";
export const ADD_SINGLE_PROFESSOR = "ADD_SINGLE_PROFESSOR";
export const EDIT_COURSE = "EDIT_COURSE";
export const REMOVE_COURSE = "REMOVE_COURSE";
export const REMOVE_PROFESSOR = "REMOVE_PROFESSOR";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_PROFESSOR = "EDIT_PROFESSOR";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const ADD_FEED = "ADD_FEED";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_COURSE_WITH_NUMBER_OF_PEOPLE =
	"ADD_COURSE_WITH_NUMBER_OF_PEOPLE";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const ADD_COUPON = "ADD_COUPON";
export const REMOVE_COUPON = "REMOVE_COUPON";
export const ADD_MERCADOPAGO_ITEMS = "ADD_MERCADOPAGO_ITEMS";
export const REMOVE_MERCADOPAGO_ITEMS = "REMOVE_MERCADOPAGO_ITEMS";
export const UPDATE_USER = "UPDATE_USER";
