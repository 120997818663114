import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import dateformat from "dateformat";
import visa from "../../images/cards/visa.png";
import master from "../../images/cards/master.png";
import oca from "../../images/cards/oca.png";

dateformat.i18n = {
	dayNames: [
		"Dom",
		"Lun",
		"Mar",
		"Mie",
		"Jue",
		"Vie",
		"Sab",
		"Domingo",
		"Lunes",
		"Martes",
		"Miércoles",
		"Jueves",
		"Viernes",
		"Sábado",
	],
	monthNames: [
		"Ene",
		"Feb",
		"Mar",
		"Abr",
		"May",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	],
	timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

class CourseSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			course: this.props.course,
			ModuleModal: false,
		};
	}

	render() {
		const { course } = this.state;

		let allHaveDates = true;
		if (course?.modules?.length > 0) {
			course.modules.forEach((m) => {
				if (!m.date) {
					allHaveDates = false;
				}
			});
		}
		const noDatesSet =
			!course ||
			(course && !course.modules) ||
			!course?.modules?.length > 0 ||
			!allHaveDates;

		let isDisabled =
			!course ||
			(course &&
				course.maxParticipants > 0 &&
				(course.quantity == 0 || course.quantity < 0)) ||
			noDatesSet;

		var dates = [];
		if (!course) {
			return <Redirect to="/" />;
		}
		course.modules &&
			course.modules.length > 0 &&
			course.modules.map((a) => {
				const oldDate = a.date && a.date.length ? a.date.split("-") : null;

				if (oldDate && oldDate.length > 0) {
					const newDate = oldDate.slice(1, 3);
					newDate.push(oldDate[0]);

					let newest = new Date();
					newest.setDate(newDate[1]);
					newest.setMonth(newDate[0] - 1);
					newest.setFullYear(newDate[2]);

					if (a == course.modules[0]) {
						isDisabled = isDisabled || newest < Date.now();
					}
					var formatted = dateformat(newest, "dddd d/mm");
					dates.push(formatted);
				}
			});
		var hour =
			course.modules &&
			course.modules.length > 0 &&
			course.modules[0].startTime + " - " + course.modules[0].endTime;
		return (
			<div className="widget-area">
				<div className="widget widget_categories">
					<h3 className="widget-title">Información</h3>

					<div className="row-space-between">
						<div class="column">
							<i className="bx bx-calendar"></i>
						</div>
						<div class="column-2">
							<a className="course-widget-info">
								{" "}
								{course && course.modules?.length > 1 ? "FECHAS" : "FECHA"}{" "}
							</a>
							{!noDatesSet && dates?.length > 0 ? (
								dates.map((d) => (
									<div className="course-widget-subtitle">
										{d || "A definir"}
									</div>
								))
							) : (
								<div className="course-widget-subtitle">A definir</div>
							)}
						</div>
					</div>
					<div className="row-space-between">
						<div class="column">
							<i className="bx bx-time"></i>
						</div>
						<div class="column-2">
							<a className="course-widget-info"> HORA </a>
							<div className="course-widget-subtitle">
								{!noDatesSet ? hour : "A definir"}
							</div>
						</div>
					</div>
					<div className="row-space-between">
						<div class="column">
							<i className="bx bx-timer"></i>
						</div>
						<div class="column-2">
							<a className="course-widget-info"> DURACIÓN TOTAL </a>
							<div className="course-widget-subtitle">
								{course.totalDuration
									? `${
											course.totalDuration == 1
												? `${course.totalDuration} hr`
												: `${course.totalDuration} hrs`
									  }`
									: "A definir"}
							</div>
						</div>
					</div>
					<div className="row-space-between">
						<div class="column">
							<img
								className="course-avatar"
								src={require("../../images/about/courses_avatar.jpg")}
								alt="image"
							/>
						</div>
						<div class="column-2">
							<a className="course-widget-info"> DOCENTE</a>
							<div className="course-widget-subtitle">
								{course.professor ? course.professor.fullName : "A definir"}
							</div>
						</div>
					</div>
					<div className="row-space-between">
						<Link
							to={"/checkout/" + course.id + "/1"}
							style={isDisabled ? { pointerEvents: "none" } : null}
						>
							<a
								class={
									isDisabled
										? "default-btn-disabled buy-btn"
										: "default-btn buy-btn"
								}
							>
								{noDatesSet ? "Próximamente" : "Inscribirme"}
							</a>
						</Link>
					</div>

					{!course?.isFree && (
						<div style={{ marginBottom: "4px", marginRight: "7px" }}>
							<p style={{ fontSize: "12px", marginBottom: '4px' }}>
								<strong>*</strong>Trabajamos con <strong>VISA</strong> ,
								<strong> MASTERCARD</strong>,<strong> American Express</strong>, <strong> Diners</strong>  y <strong>OCA</strong>. Hasta 12
								cuotas <strong>sin recargo</strong>.
							</p>
							<p style={{ fontSize: "12px", marginTop: '0px' }}>
								{" "}
								Por transferencia bancaria{" "}
								<a href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20Transferencia%20">
									consulte aquí.
								</a>
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const WrappedCourseSidebar = withRouter(CourseSidebar);

export default WrappedCourseSidebar;
