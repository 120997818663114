import React, { Component } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";

const options = {
	loop: true,
	nav: false,
	dots: true,
	autoplayHoverPause: true,
	autoplay: true,
	animateOut: "fadeOut",
	mouseDrag: false,
	items: 1,
	navText: [
		"<i class='flaticon-left'></i>",
		"<i class='flaticon-right-arrow'></i>",
	],
};

class OfferProducts extends Component {
	_isMounted = false;
	state = {
		display: false,
	};
	componentDidMount() {
		this._isMounted = true;
		this.setState({ display: true });
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	render() {
		return (
			<section className="offer-products-area ptb-100">
				<div className="container">
					{this.state.display ? (
						<>
							<OwlCarousel
								className="offer-products-slides owl-carousel owl-theme"
								{...options}
							>
								<div className="single-offer-products">
									<div className="content">
										<h3>In Company</h3>
										<p>
											Consulte por la dinámica que aportará valor a tu empresa.
										</p>
										<a
											href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20In%20Company%20"
											className="default-btn"
										>
											Consultar
										</a>
									</div>

									<div className="image">
										<a>
											<img
												src={require("../../images/in-company/1.jpg")}
												alt="image"
											/>
										</a>
									</div>
								</div>

								<div className="single-offer-products">
									<div className="content">
										<h3>
											<a>In Company</a>
										</h3>
										<p>
											Consulte por la dinámica que aportará valor a tu empresa.
										</p>
										<a
											href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20In%20Company%20"
											className="default-btn"
										>
											Consultar
										</a>
									</div>

									<div className="image">
										<a>
											<img
												src={require("../../images/in-company/2.jpg")}
												alt="image"
											/>
										</a>
									</div>
								</div>

								<div className="single-offer-products">
									<div className="content">
										<h3>
											<a>In Company</a>
										</h3>
										<p>
											Consulte por la dinámica que aportará valor a tu empresa.
										</p>
										<a
											href="mailto:cursos@gabrielvallone.com.uy?Subject=Consulta%20In%20Company%20"
											className="default-btn"
										>
											Consultar
										</a>
									</div>

									<div className="image">
										<a>
											<img
												src={require("../../images/in-company/3.jpg")}
												alt="image"
											/>
										</a>
									</div>
								</div>
							</OwlCarousel>

 
						</>
					) : (
						""
					)}
				</div>
			</section>
		);
	}
}

export default OfferProducts;
